import React from "react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"
import usePinkDolphinAxios from "hooks/usePinkDolphinAxios"

// store
import useWebuser from "store/api/webuser"
import { create as createPostalAddress } from "store/api/postal-address"
import { update as updateClinic } from "store/api/clinic"

const useWebuserClinics = (revalidateOnMount = false) => {
  const orca = useOrcaAxios()
  const pinkdolphin = usePinkDolphinAxios()

  const webuserAPI = useWebuser()
  const webuser = webuserAPI.data ?? {}
  const webuserId = webuser.webuser_id

  const {
    data: clinicData,
    error,
    mutate,
    isValidating,
  } = useSWR(
    () => (webuserId ? "/rpc/clinic__get" : null),
    async (url) => {
      const { data } = await orca.post(url, {
        webuser_id_value: webuserId,
      })
      return data
    },
    { revalidateOnMount }
  )

  const data = React.useMemo(() => {
    return clinicData
      ? clinicData.map((c) => ({
          clinic_id: c.clinic_id,
          domain_marketing_role_id: c.domain_marketing_role_id,
          webuser_clinic_role_id: c.webuser_clinic_role_id,
          clinic: {
            provider: c.provider,
            corporate_clinic_group_id: c.corporate_clinic_group_id,
            installation_status: c.installation_status,
            name: c.name,
            acronym: c.acronym,
            slug: c.slug,
            webuser_creator_id: c.webuser_creator_id,
          },
        }))
      : undefined
  }, [clinicData])

  const create = async ({
    switchTenant,
    role,
    clinic: {
      arthurRep,
      name,
      slug,
      referrer,
      clinicOwner,
      fiscalYearStartDateDayKey,
      fiscalYearStartDateMonthKey,
      providerName,
      operatingRoomsCount,
    },
    postalAddress: { address, city, districtCode, postalCode, countryCode },
  }) => {
    if (fiscalYearStartDateMonthKey < 1 || fiscalYearStartDateMonthKey > 12) {
      throw new Error({
        name: "Bad type",
        message: "'fiscal_year_start_date_month_key' must be between 1 - 12",
        toString: function () {
          return this.name + ": " + this.message
        },
      })
    }
    if (fiscalYearStartDateMonthKey === 2 && fiscalYearStartDateDayKey === 29) {
      throw new Error({
        name: "Bad type",
        message: "Fiscal year cannot be Feb 29th",
        toString: function () {
          return this.name + ": " + this.message
        },
      })
    }

    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    // CREATE CLINIC
    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    const { data: newClinicId } = await orca.post("/rpc/clinic__insert", {
      webuser_id: webuserId,
      name,
      slug,
      provider_name: providerName,
      domain_marketing_role_id: role,
      referrer,
      clinic_owner: clinicOwner,
    })

    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    // CREATE POSTAL ADDRESS
    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***

    const newPostalAddress = await createPostalAddress(orca, {
      address,
      city,
      district_code: districtCode,
      country_code: countryCode,
      postal_code: postalCode,
      subjectId: newClinicId,
      subjectType: "clinic",
    })

    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    // UPDATE CLINIC INFOS
    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***

    await switchTenant(newClinicId)

    await updateClinic(orca, newClinicId, {
      fiscal_year_start_date_day_key: fiscalYearStartDateDayKey,
      fiscal_year_start_date_month_key: fiscalYearStartDateMonthKey,
      postal_address_id: newPostalAddress.postal_address_id,
      arthur_representative: arthurRep,
      operating_rooms_count: operatingRoomsCount,
      acronym: slug
        .split("-")
        .map((str, index, arr) =>
          !isNaN(str) ? (arr[index + 1] ? `-${str}-` : `-${str}`) : str[0]
        )
        .join("")
        .toUpperCase()
        .substring(0, 10),
    })

    return newClinicId
  }

  const triggerPipeline = async (name) => {
    const availablePipelines = [
      "provision",
      // "clinic_info",
      "extract_and_load",
      "integrate",
      "finish_onboarding",
    ]

    if (!availablePipelines.includes(name)) {
      throw new Error(
        `Only these pipelines are available: ${availablePipelines.join("|")}`
      )
    }

    await pinkdolphin.post(`/rpc/dags/${name}`)
  }

  return {
    data,
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    create,
    triggerPipeline,
  }
}

export default useWebuserClinics
