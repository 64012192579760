import React from "react"

// styles
import { css } from "@emotion/core"

import { Switch as Component } from "antd"

const styling = (theme) => css`
  label: component-switch--styling;

  min-width: 40px;
  height: 20px;

  &.ant-switch-checked {
    background-color: ${theme.markerColorGreenDark};

    .ant-switch-handle {
      left: calc(100% - 16px - 2px);
    }
  }

  .ant-switch-handle {
    width: 16px;
    height: 16px;
  }
`

const Switch = ({ checked, onChange, ...rest }) => {
  return (
    <Component
      css={styling}
      checked={checked}
      onChange={onChange}
      data-testid="switch"
      className="component-switch"
      {...rest}
    />
  )
}

export default Switch
