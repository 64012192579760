// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 14,
  height = 14,
  color = "themeColorDefault",
  type = "right",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform={`rotate(${
          type === "left"
            ? 0
            : type === "top"
            ? -90
            : type === "bottom"
            ? 90
            : 180
        })`}
        transform-origin="center"
        d="M12 1.5582L6.94404 7.11375L12 12.6693M2 1.33594V12.5952"
        stroke={theme[color]}
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
