import React from "react"

// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-color-tag;

  display: inline-flex;
  align-items: center;

  border-radius: 8px;
  padding: ${({ padding }) => padding};

  border: 1px solid
    ${({ theme, bordered }) =>
      bordered ? theme.borderColorLight : "transparent"};

  background: ${({ bgColor }) => bgColor};
`

const ColorBox = styled.div`
  label: component-color-tag--box;

  display: flex;
  flex-wrap: wrap;

  margin-top: 4px;

  > * {
    margin-bottom: 4px;
  }
`

const ColorIcon = styled.div`
  label: component-color-tag--icon;
  width: ${({ small }) => (small ? "8px" : "16px")};
  height: ${({ small }) => (small ? "8px" : "16px")};

  margin-right: 4px;

  border-radius: ${({ dot }) => (dot ? "100%" : "4px")};

  background: ${({ color }) => color};
`

const ColorTag = ({
  label,
  bgColor = "white",
  color = "white",
  bordered = false,
  dot = false,
  small = false,
  padding = "2px 4px",
  ...rest
}) => {
  return (
    <Component
      className="component-color-tag"
      bgColor={bgColor}
      bordered={bordered}
      padding={padding}
      {...rest}
    >
      {Array.isArray(color) ? (
        <ColorBox>
          {color.map((c, index) => (
            <ColorIcon key={index} color={c} />
          ))}
        </ColorBox>
      ) : (
        <ColorIcon small={small} dot={dot} color={color} />
      )}

      {label}
    </Component>
  )
}

export default ColorTag
