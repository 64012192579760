import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Breadcrumb as Component } from "antd"

const styling = (theme) => css`
  lable: component-breadcrumb--styling;
`

const Breadcrumb = ({ children, ...props }) => {
  return (
    <Component
      css={styling}
      data-testid="breadcrumb"
      className="component-breadcrumb"
      {...props}
    >
      {children}
    </Component>
  )
}

Breadcrumb.Item = Component.Item

export default Breadcrumb
