import React from "react"

// styles
// import styled from "@emotion/styled"
import { css, Global } from "@emotion/core"

import { Tooltip as Component } from "antd"

const styling = (theme) => css`
  .component-tooltip-card {
    .ant-tooltip-content {
      background: ${theme.borderColorDarker};
      border-radius: 12px;

      padding: 0px 2px;

      .ant-tooltip-arrow {
        display: none;
        overflow: visible;
        &::before {
          width: 20px;
          height: 20px;
          background: none;
          overflow: visible;
          background: ${theme.borderColorDarker};
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAyMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMCAxMC4yMTY4QzE4LjUwMzkgMTAuMTc4OCAxNy4xNTAzIDkuMzA3NDEgMTYuNDk4NCA3Ljk1Mzk5TDEzLjc1NzYgMi4yNjQxQzEyLjMwMzQgLTAuNzU0NzEyIDguMDA0MzMgLTAuNzU0NzE1IDYuNTUwMTggMi4yNjQwOUwzLjgwOTU3IDcuOTUzNTdDMy4xNTUgOS4zMTI0NCAxLjc5MzE0IDEwLjE4NTQgMC4yODk5MTEgMTAuMjE2OEwyMCAxMC4yMTY4WiIgZmlsbD0iIzFFMkM0RCIvPgo8L3N2Zz4K");
        }
      }

      .ant-tooltip-inner {
        background: none;

        ${theme.font.typo.bold600};
        font-size: ${theme.font.size.default};
        color: ${theme.textColorWhite};

        line-height: 140%;
        letter-spacing: 0.03em;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.ant-tooltip-placement-right .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
      transform: translateX(-1.5px) rotate(-90deg);
      box-shadow: none;
    }

    &.ant-tooltip-placement-top .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
      transform: translateY(-2px) translateX(-3.5px) rotate(180deg);
      box-shadow: none;
    }

    &.ant-tooltip-placement-left .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
      transform: translateX(-6px) rotate(90deg);
      box-shadow: none;
    }

    &.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
    &.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
      transform: translateY(2px) translateX(-4.5px) rotate(0deg);
      box-shadow: none;
    }
  }
`

const Tooltip = ({ children, ...rest }) => {
  return (
    <Component
      className="component-tooltip"
      overlayClassName="component-tooltip-card"
      {...rest}
    >
      <Global styles={styling} />
      {children}
    </Component>
  )
}

export default Tooltip
