import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// assets
import Check from "assets/icons/icon-success.svg"

const Component = styled.div`
  label: component-checkitem;

  display: flex;
  align-items: center;
  margin: 20px 0;

  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
`

const Content = styled.div`
  label: component-checkitem--content;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDark};

  width: calc(100% - 40px);

  text-align: left;
  line-height: 150%;
  letter-spacing: 0.01em;

  ${({ theme }) =>
    theme.media(
      "<768px",
      css`
        font-size: ${theme.font.size.tall};
      `
    )};
`

const CheckItem = ({ children, ...rest }) => (
  <Component {...rest}>
    <img src={Check} alt="check" />
    <Content>{children}</Content>
  </Component>
)

export default CheckItem
