// import React from "react"

// other
import { message } from "antd"

export default {
  success: (content, duration = 3, key) =>
    message.success({
      content,
      duration,
      key,
      className: "component-message--success",
      // icon: <span>a</span>
    }),
  error: (content, duration = 5, key) =>
    message.error({
      content,
      duration,
      key,
      className: "component-message--error",
      // icon: <span>a</span>
    }),
  loading: (content, key) =>
    message.loading({
      content,
      key,
      className: "component-message--loading",
    }),
}
