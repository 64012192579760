import React from "react"
// import { renderToString, renderToStaticMarkup } from "react-dom/server"

// charts
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

// components
// import { Typo } from "components"

const DEFAULT_COLOR = [
  "#1D88FF",
  "#FFD55F",
  "#FF7B7B",
  "#C12E4D",
  "#48DE9E",
  "#9283FF",
  "#FF8C2D",
  "#7FE4FF",
  "#35BAA5",
  "#018BD1",
  "#AAAAAA",
  "#8690AD",
  "#AED7FF",
  "#493BB1",
  "#94E3D7",
  "#00359E",
  "#007457",
  "#FFF3B8",
  "#BAB0FF",
]

const Chart = ({ options }) => {
  const extendOptions = (opt) => {
    const newOptions = { ...opt }

    // const ReactTitle = <Typo.Title level={3}>{options.title.text}</Typo.Title>

    // console.log(ReactTitle)
    // console.log(renderToStaticMarkup(ReactTitle))

    // newOptions.title.text = renderToString(ReactTitle)
    newOptions.title.style = {
      margin: "0 0 0 0",
      display: "block",
      "text-align": "left",
      "text-transform": "inherit",
      "font-family": "Proxima Nova",
      "font-style": "normal",
      "font-weight": "bold",
      "font-size": "18px",
      color: "#48577A",
      "line-height": "120%",
    }

    const axises = ["xAxis", "yAxis"]

    const extendAxis = (a) => ({
      tickAmount: 10,
      ...(a || {}),
      title: {
        ...(a?.title || {}),
        style: {
          "font-family": "Proxima Nova",
          "font-style": "normal",
          "font-weight": "bold",
          "font-size": "12px",
          color: "#98A4C0",
          ...(a?.title?.style || {}),
        },
      },
      labels: {
        ...(a?.labels || {}),
        style: {
          "font-family": "Proxima Nova",
          "font-style": "normal",
          "font-size": "12px",
          color: "#98A4C0",
          ...(a?.labels?.style || {}),
        },
      },
    })

    axises.forEach((axis) => {
      if (newOptions[axis])
        newOptions[axis] = Array.isArray(newOptions[axis])
          ? newOptions[axis].map((a) => extendAxis(a))
          : extendAxis(newOptions[axis])
    })

    newOptions.tooltip = {
      className: "highcharts-tooltip",

      backgroundColor: "#48577A",
      borderColor: "transparent",
      borderRadius: 16,
      formatter: function () {
        return this.x + ": " + this.y
      },

      ...(newOptions.tooltip || {}),

      style: {
        "font-family": "Proxima Nova",
        "font-style": "normal",
        "font-size": "12px",
        color: "#E3E7F1",
        ...(newOptions.tooltip?.style || {}),
      },
    }

    newOptions.legend = {
      ...(newOptions.legend || {}),
      itemStyle: {
        "font-family": "Proxima Nova",
        "font-style": "normal",
        "font-size": "12px",
        color: "#98A4C0",
        ...(newOptions.legend?.itemStyle || {}),
      },
    }

    newOptions.series = newOptions.series.map((set) => ({
      ...set,

      marker: {
        enabled: false,
        ...(set.marker || {}),
      },

      dataLabels: {
        ...(set.dataLabels || {}),

        style: {
          "font-family": "Proxima Nova",
          "font-style": "normal",
          "font-weight": "bold",
          "font-size": "12px",
          color: "#48577A",
          textOutline: "transparent",
          ...(set.dataLabels?.style || {}),
        },
      },
    }))

    newOptions.colors = DEFAULT_COLOR

    newOptions.accessibility = { enabled: false }
    newOptions.credits = { enabled: false }

    return newOptions
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={extendOptions(options)}
      // callback={callback}
    />
  )
}

Chart.DEFAULT_COLOR = DEFAULT_COLOR

export default Chart
