import React from "react"

// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-scroll;

  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  -webkit-overflow-scrolling: touch;

  padding-bottom: 16px;
  padding-top: 8px;
  margin-bottom: 16px;
  margin-top: 8px;
`

const Scroll = ({ children, ...rest }) => {
  return (
    <Component className="component-scroll" {...rest}>
      {children}
    </Component>
  )
}

export default Scroll
