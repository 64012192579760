import React from "react"

// styles
import styled from "@emotion/styled"

function colorIsDark(hexcolor) {
  var r = parseInt(hexcolor.substr(1, 2), 16)
  var g = parseInt(hexcolor.substr(3, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness < 140
}

const Component = styled.div`
  label: component-pill;
  display: inline-flex;
  flex-direction: center;
  align-items: center;
  padding: 1px 8px;

  background: ${({ theme, color }) => theme[color] || color};
  border-radius: ${({ round }) => (round ? "12px" : "6px")};

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme, color }) =>
    !colorIsDark(color) ? theme.textColorDefault : theme.textColorWhite};

  margin: 2px;
`

const Pill = ({ color = "white", label, round, ...rest }) => {
  return (
    <Component
      className="component-pill"
      data-testid="pill"
      color={color}
      round={round}
      {...rest}
    >
      {label}
    </Component>
  )
}

export default Pill
