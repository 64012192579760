// import React from "react"

// main
// import useSWR from "swr"

// hooks
// import useOrcaAxios from "hooks/useOrcaAxios"

// const usePhoneNumber = ({ type, postalAddressId }) => {}

const get = async (orca, phoneNumberId) => {
  const {
    data: [response],
  } = await orca.get(
    `/phone_number?phone_number_id=${phoneNumberId}&select=number`
  )

  return response
}

const create = async (orca, values) => {
  const { subjectId, subjectType, value, ...rest } = values

  const { data: response } = await orca.post("/rpc/phone_number__insert", {
    subject_id_value: subjectId,
    subject_type_value: subjectType,
    phone_number_value: value
      .replace(/\./g, "")
      .replace(/-/g, "")
      .replace(/\//g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, ""),
    ...rest,
  })
  return response
}

export { create, get }

// export default usePhoneNumber
