import Holidays from "date-holidays"

const holidays = new Holidays("CA", "QC")

export const getHoliday = (year, date, language) => {
  const holiday = holidays
    .getHolidays(year, language)
    .find((item) => item.date.split(" ")[0] === date)

  return holiday
}
