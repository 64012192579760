import React from "react"

// styles
import styled from "@emotion/styled"

// assets
import Check from "assets/icons/icon-success.svg"

// utils
import { get } from "lodash"

const Component = styled.div`
  label: component-check-list;

  border: 1px solid ${({ theme }) => theme.borderColorDefault};
  box-sizing: border-box;
  border-radius: 12px;
`

const ListItem = styled.div`
  label: component-check-list--item;

  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.borderColorDefault};
  }
`

const ListItemCounter = styled.span`
  label: component-check-list--item-counter;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 17px;
  letter-spacing: 0.02em;
  color: $black808;

  &::before {
    content: "#";
  }
`

const ListItemLabel = styled.span`
  label: component-check-list--item-label;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};

  flex-grow: 2;
  line-height: 17px;
  letter-spacing: 0.02em;
  margin: 0 16px;
`

const ListItemImage = styled.img`
  label: component-check-list--item-image;

  width: 16px;
  height: 16px;
`

const ListContainer = ({ items, keyName, id }) => {
  return (
    <Component>
      {items.map((item, index) => (
        <ListItem key={item[id]}>
          <ListItemCounter>{index + 1}</ListItemCounter>
          <ListItemLabel>{get(item, keyName)}</ListItemLabel>

          <ListItemImage src={Check} alt="check" />
        </ListItem>
      ))}
    </Component>
  )
}

export default ListContainer
