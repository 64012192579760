import React from "react"

// translation
import useTranslate from "hooks/useTranslate"

// styles
import styled from "@emotion/styled"

// components
import { Typo, Space, Flex } from "components"

// utils
import * as fn from "utils/functions"

// assets
import { ReactComponent as IconSuccess } from "assets/icons/icon-success.svg"
import { ReactComponent as IconWarning } from "assets/icons/icon-warning.svg"
import { ReactComponent as IconFail } from "assets/icons/icon-fail.svg"

const Component = styled.div`
  label: widget-component-stat-value-objective;

  height: 100%;
  padding-top: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Stat = ({ title = "Total", value, objective, format = {} }) => {
  const { t } = useTranslate(["generic", "ui"])

  const ratio = (value - objective) / objective

  return (
    <Component>
      <Typo.Title level={3} textAlign="center">
        {title}
      </Typo.Title>
      <Typo.Text font="bold700" size="big">
        {fn.renderAnalyticValue(value, format)}
      </Typo.Text>

      {objective !== undefined && (
        <React.Fragment>
          <Typo.Text font="bold600" size="default" color="textColorLighter">
            {t("generic:objective.label")}
          </Typo.Text>
          <Typo.Text font="bold600" size="default" color="textColorLighter">
            {fn.renderAnalyticValue(objective, format)}
          </Typo.Text>

          <Space>
            <Typo.Text
              font="default"
              size="default"
              color={
                ratio === 0
                  ? "textColorLighter"
                  : ratio > 0
                  ? "textColorSuccess"
                  : "textColorError"
              }
            >
              {fn.renderAnalyticValue(value - objective, {
                ...format,
                sign: true,
              })}
            </Typo.Text>
            <Typo.Text
              font="default"
              size="default"
              color={
                ratio === 0
                  ? "textColorLighter"
                  : ratio > 0
                  ? "textColorSuccess"
                  : "textColorError"
              }
            >
              (
              {fn.renderAnalyticValue(ratio, {
                unit: "percentage",
                precision: 2,
                sign: true,
              })}
              )
            </Typo.Text>
          </Space>
        </React.Fragment>
      )}
    </Component>
  )
}

const Info = ({
  value,
  objective,
  inline = false,
  background = false,
  icon = true,
  hideObjective = false,
  backgroundOpacity = 0.15,
  formatValue = (v) => v,
}) => (
  <Flex.Row
    wrap={false}
    justify="end"
    fullWidth
    fullHeight
    gutter={8}
    style={{
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: !background
        ? "transparent"
        : value / objective >= 0.9
        ? `#48DE9E${Math.round(backgroundOpacity * 255).toString(16)}`
        : value / objective >= 0.75
        ? `#FFD55F${Math.round(backgroundOpacity * 255).toString(16)}`
        : `#FF7B7B${Math.round(backgroundOpacity * 255).toString(16)}`,
    }}
  >
    <Flex.Col
      style={{
        alignItems: "flex-end",
        flexDirection: inline ? "row" : "column",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typo.Text block={!inline} font="bold600" margin="4px 0">
        {formatValue(value)}
      </Typo.Text>
      {!hideObjective && objective !== undefined && (
        <Typo.Text
          margin={inline ? "4px 0 4px 4px" : undefined}
          block={!inline}
          font="default"
          color="textColorLighter"
          size="small"
        >
          {formatValue(objective)}
        </Typo.Text>
      )}
    </Flex.Col>
    {icon && (
      <Flex.Col
        style={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {value / objective > 1 ? (
          <IconSuccess height="16px" width="16px" />
        ) : value / objective > 0.75 ? (
          <IconWarning height="16px" width="16px" />
        ) : (
          <IconFail height="16px" width="16px" />
        )}
      </Flex.Col>
    )}
  </Flex.Row>
)

Stat.Info = Info

export default Stat
