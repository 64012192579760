import React from "react"

// styles
import { css } from "@emotion/core"

// componeny
import { Badge as Component } from "antd"

const styling = (theme) => css`
  label: component-badge--styling;
`

const Badge = ({ children, ...rest }) => {
  return (
    <Component css={styling} className="component-badge" {...rest}>
      {children}
    </Component>
  )
}

export default Badge
