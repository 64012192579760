export const getCurrentFiscalYear = ({ fiscalMonth, fiscalDay }) => {
  const d = new Date()
  const currentDate = d.getDate()
  const currentMonth = d.getMonth() + 1

  var currentYear = d.getFullYear()

  if (currentMonth > fiscalMonth) {
    return currentYear
  } else if (currentMonth < fiscalMonth) {
    return currentYear - 1
  } else {
    return currentDate >= fiscalDay ? currentYear : currentYear - 1
  }
}
