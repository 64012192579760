import React from "react"

// styles
import { css } from "@emotion/core"
import styled from "@emotion/styled"

// components
import { ErrorFallback, Loader } from "components"

const Wrapper = styled.div`
  label: component-page-title-container--wrapper
  height: 100vh;
  width: 100%;
  position: relative;
`

const Component = styled.div`
  label: component-page-title-container;
  background-color: ${({ theme }) => theme.backgroundColorDefault};

  width: 100%;
  height: 100vh;

  padding: 32px;
  padding-top: 0px;
  padding-bottom: 300px;
  overflow-y: scroll;

  ${({ theme }) =>
    theme.media(
      ">1920px",
      css`
        padding-right: 300px;
      `
    )};

  ${({ theme }) =>
    theme.media(
      "<1600px",
      css`
        padding-right: 2rem;
      `
    )};

  ${({ theme }) =>
    theme.media(
      "<desktop",
      css`
        padding-left: 1rem;
        padding-right: 1rem;
      `
    )};
`

const Header = styled.header`
  label: component-page-title-container--header;
  ${({ theme }) => theme.font.typo.bold800};
  font-size: ${({ theme }) => theme.font.size.large};
  color: ${({ theme }) => theme.textColorDark};

  background: ${({ theme }) => theme.backgroundColorDefault};
  z-index: 3;
  min-height: 5%;

  position: sticky;
  top: 0;

  margin: 0 -32px;
  padding: 0 32px;
  padding-top: 86px;
  padding-bottom: 16px;

  line-height: 110%;
  letter-spacing: -0.02em;

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        text-align: center;
      `
    )};

  transition: box-shadow 300ms;
  box-shadow: ${({ shadow }) =>
    shadow
      ? "0px 5px 8px 0px rgba(50, 50, 50, 0.1)"
      : "0px 5px 8px 0px rgba(0, 0, 0, 0)"};
`

const Container = styled.div`
  label: component-page-title-container--container;
  width: 100%;
`

const PageTitleContainer = React.forwardRef(
  ({ children, title, loading = false, loadingProps }, ref) => {
    return (
      <Wrapper data-testid="page-title-container">
        <Loader
          loading={loading}
          {...loadingProps}
          style={{
            zIndex: 1,
          }}
        />

        <Component
          data-testid="page-title-container-content"
          ref={ref}
          style={{ overflowY: loading ? "hidden" : "scroll" }}
        >
          <Header role="heading" data-testid="page-title-container-header">
            {title}
          </Header>
          <Container>{children}</Container>
        </Component>
      </Wrapper>
    )
  }
)

export default ErrorFallback.withErrorBoundary(PageTitleContainer, {
  part: "page",
})
