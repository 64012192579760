// packages
import { css } from "@emotion/core"

// colors
import { colors } from "./colors"

// utils
import mediaQuery from "styles/utils/media-query"

// ${({ theme }) => theme.font.typo.default};
// font-size: ${({ theme }) => theme.font.size.default};
// color: ${({ theme }) => theme.textColorDefault};

const defaultTheme = {
  themeColorDefault: colors.arthurBlue.hex, //#156CF7
  themeColorLight: colors.azure.hex, //#1D88FF
  themeColorLighter: colors.opal.hex, //#59A8FF
  themeColorExtraLight: colors.vipassana.hex, //#CAE6FF
  themeColorExtraLighter: colors.mirage.hex, //#CAE6FF

  borderColorDefault: colors.moonshadow.hex, //#A6B2CE
  borderColorLight: colors.saltPlains.hex, //#E3E7F1
  borderColorLighter: colors.revelation.hex, //#F5F7FB
  borderColorDark: colors.black808.hex, //#3B4A6E
  borderColorDarker: colors.lacrimosa.hex, //#1E2C4D
  borderColorError: colors.futureboyRed.hex, //#FF5E69
  borderColorSuccess: colors.myscelia.hex, //#48DE9E
  borderColorWarning: colors.elon.hex, //#FF9E3B
  borderColorWarningLight: colors.sivananda.hex, //#FFD55F
  borderColorWarningDark: colors.swami.hex, //#FF8C2D

  backgroundColorDefault: colors.white.hex, //#FFFFFF
  backgroundColorLight: colors.saltPlains.hex, //#E3E7F1
  backgroundColorLighter: colors.spirit.hex, //#EBEFF7
  backgroundColorExtraLight: colors.revelation.hex, //#F5F7FB
  backgroundColorExtraLighter: colors.silence.hex, //#FAFBFD
  backgroundColorDark: colors.lacrimosa.hex, //#1E2C4D
  backgroundColorDarker: colors.deepSpace.hex, //#121E31
  backgroundColorExtraDark: colors.deepSpace.hex, //#121E31
  backgroundColorError: `rgba(${colors.futureboyRed.rgb}, 0.1)`, ////#FF5E69
  backgroundColorDisabled: colors.karesansui.hex, //#D9DEEB
  backgroundColorNegative: colors.vulnerability.hex, //#FDD8D8
  backgroundColorNegativeDark: colors.futureboyRed.hex, ////#FF5E69
  backgroundColorPositive: colors.chloe.hex, //#D2F6E6
  backgroundColorWarning: colors.elon.hex, //#FF9E3B
  backgroundColorWarningLight: colors.sivananda.hex, //#FFD55F
  backgroundColorWarningDark: colors.swami.hex, //#FF8C2D

  textColorDefault: colors.orion.hex, //#48577A
  textColorPlaceholder: colors.inBetween.hex, //#8690AD
  textColorWhite: colors.white.hex, //#FFFFFF
  textColorDark: colors.black808.hex, //#3B4A6E
  textColorDarker: colors.lacrimosa.hex, //#1E2C4D
  textColorLight: colors.ufo.hex, //#5D6C8F
  textColorLighter: colors.mills.hex, //#98A4C0
  textColorExtraLight: colors.wiseRabbit.hex, //#B9C2DA
  textColorUltraLight: colors.saltPlains.hex, //#E3E7F1
  textColorDisabled: colors.karesansui.hex, //#D9DEEB
  textColorError: colors.futureboyRed.hex, //#FF5E69
  textColorSuccess: colors.myscelia.hex, //#48DE9E
  textColorWarning: colors.elon.hex, //#FF9E3B
  textColorWarningLight: colors.sivananda.hex, //#FFD55F
  textColorWarningDark: colors.swami.hex, //#FF8C2D

  markerColorDefault: colors.elon.hex, //#FF9E3B
  markerColorGreen: colors.essence.hex, //#A6EDCF
  markerColorGreenLight: colors.chloe.hex, //#D2F6E6
  markerColorGreenDark: colors.myscelia.hex, //#48DE9E
  markerColorGold: colors.marigold.hex, //#FFE88C
  markerColorPink: colors.elon.hex, //#FD8888
  markerColorOrange: colors.sivananda.hex, //#FFD55F
  markerColorTeal: colors.mirage.hex, //#D8F7FF
  markerColorGrey: colors.saltPlains.hex, //#E3E7F1

  overlayColorDefault: `rgba(${colors.white.rgb}, 0.64)`,
  overlayColorDark: `rgba(${colors.lacrimosa.rgb}, 0.5)`,

  loadingBackgroundColorDefault: colors.opal.hex, //#59A8FF
  loadingBackgroundColorWarning: colors.sivananda.hex, //#FFD55F
  loadingBackgroundColorSuccess: colors.myscelia.hex, //#48DE9E

  skeleton: {
    loading: css`
      box-shadow: 0px 0px 20px rgba(108, 108, 108, 0.1);
      border-radius: 2px;

      @keyframes animation {
        0% {
          background-position: 0% 50%;
        }
        100% {
          background-position: -200% 50%;
        }
      }

      will-change: background-position;
      animation: animation 3s linear infinite;
      background-image: repeating-linear-gradient(
        -45deg,
        #dbecff 0%,
        #f2f2f2 50%,
        #dbecff 100%
      );

      background-size: 200% 200%;
    `,
  },

  shadow: css`
    label: drop-shadow;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.15);
  `,

  round: css`
    label: round;
    border-radius: 8px;
  `,

  height: {
    default: css`
      label: height-default;
      height: 32px;
    `,

    small: css`
      label: height-small;
      height: 28px;
    `,
    smaller: css`
      label: height-smaller;
      height: 24px;
    `,
  },

  font: {
    typo: {
      default: css`
        label: typo-default;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
      `,
      bold600: css`
        label: typo-bold600;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
      `,
      bold700: css`
        label: typo-bold700;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
      `,
      bold800: css`
        label: typo-bold800;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 800;
      `,
      Default: css`
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal !important;
      `,
      Bold600: css`
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600 !important;
      `,
      Bold700: css`
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold !important;
      `,
      Bold800: css`
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 800 !important;
      `,
    },

    size: {
      micro: "6px",
      tiny: "8px",
      extraSmall: "10px",
      smaller: "11px",
      small: "12px",
      default: "14px",
      tall: "16px",
      taller: "18px",
      extraTall: "20px",
      big: "24px",
      bigger: "30px",
      extraBig: "32px",
      large: "40px",
      larger: "44px",
      extraLarge: "60px",
      huge: "65px",
      huger: "70px",
      extraHuge: "80px",
    },
  },
  utils: {
    unit: (unit, defaultValue = "0px", fallbackUnit = "px") =>
      unit
        ? typeof unit === "number"
          ? `${unit}${fallbackUnit}`
          : unit
        : defaultValue,
    log: () => console.log(this),
  },
  media: (queryString, styling) =>
    css`
      ${mediaQuery(queryString)} {
        ${styling}
      }
    `,
}

export default defaultTheme
