import styled from "@emotion/styled"
import css from "@emotion/css"

function addStyle(
  cssProperty,
  value,
  { predicate = (v) => v !== undefined, transform = (v) => v } = {}
) {
  return predicate(value) ? `${cssProperty}: ${transform(value)};` : ""
}

const responsiveMediaQueries = ({
  xsmall = "",
  small = "",
  medium = "",
  large = "",
  xlarge = "",
}) => css`
  ${xsmall}
  /* Small devices (portrait phones, 480px and up) */
  @media (min-width: 480px) {
    ${small}
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    ${medium}
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    ${large}
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    ${xlarge}
  }
`

const gridStyles = ({
  height,
  width,
  columns,
  rows,
  gap,
  columnGap,
  rowGap,
  areas,
  flow,
  justifyContent,
  alignContent,
}) => css`
  margin: auto;
  display: grid;
  ${addStyle("height", height)}
  ${addStyle("max-width", width)}
  ${addStyle("grid-template-columns", columns)}
  ${addStyle("grid-template-rows", rows)}
  ${addStyle("grid-gap", gap)}
  ${addStyle("grid-column-gap", columnGap)}
  ${addStyle("grid-row-gap", rowGap)}
  ${addStyle("grid-areas", areas)}
  ${addStyle("grid-auto-flow", flow)}
  ${addStyle("justify-content", justifyContent)}
  ${addStyle("align-content", alignContent)}
`

function createResponsiveGrid(element = "div", grids = {}) {
  const GridElement = styled[element]`
    ${responsiveMediaQueries({
      xsmall: grids.xsmall ? gridStyles(grids.xsmall) : "",
      small: grids.small ? gridStyles(grids.small) : "",
      medium: grids.medium ? gridStyles(grids.medium) : "",
      large: grids.large ? gridStyles(grids.large) : "",
      xlarge: grids.xlarge ? gridStyles(grids.xlarge) : "",
    })}
  `
  return GridElement
}

const gridCellStyles = ({
  row,
  column,
  width,
  height,
  left,
  top,
  area,
  flex,
  flexDirection,
  justifyContent,
  alignItems,
}) => css`
  height: 100%;
  min-width: 0;
  ${addStyle("grid-column-start", left)}
  ${addStyle("grid-column-end", width, {
    transform: (width) => `span ${width}`,
  })}
  ${addStyle("grid-row-start", top)}
  ${addStyle("grid-row-end", height, {
    transform: (height) => `span ${height}`,
  })}
  ${addStyle("grid-column", column)}
  ${addStyle("grid-row", row)}
  ${addStyle("grid-area", area)}
  ${flex
    ? `
      display: flex;
      ${addStyle("flex-direction", flexDirection)}
      ${addStyle("justify-content", justifyContent)}
      ${addStyle("align-items", alignItems)}
    `
    : ""}
`

function createResponsiveGridCell(element = "div", gridCells = {}) {
  const GridCellElement = styled[element]`
    ${responsiveMediaQueries({
      xsmall: gridCells.xsmall ? gridCellStyles(gridCells.xsmall) : "",
      small: gridCells.small ? gridCellStyles(gridCells.small) : "",
      medium: gridCells.medium ? gridCellStyles(gridCells.medium) : "",
      large: gridCells.large ? gridCellStyles(gridCells.large) : "",
      xlarge: gridCells.xlarge ? gridCellStyles(gridCells.xlarge) : "",
    })}
  `
  return GridCellElement
}

export {
  gridStyles,
  gridCellStyles,
  createResponsiveGrid,
  createResponsiveGridCell,
  responsiveMediaQueries,
}
