import React from "react"

// styles
import { css } from "@emotion/core"

import { Progress as Component } from "antd"

const styling = () => css`
  label: component-progress--styling;

  @keyframes animation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -200% 50%;
    }
  }

  &.ant-progress-status-active {
    .ant-progress-bg {
      will-change: background-position;
      animation: animation 15s linear infinite;
      background-image: repeating-linear-gradient(
        -45deg,
        #59a8ff 0px,
        #59a8ff 10px,
        #aed7ff 10px,
        #aed7ff 12px,
        #59a8ff 12px,
        #59a8ff 13px
      );

      background-size: 200% 200%;
    }
  }
`

const Progress = ({ ...rest }) => (
  <Component css={styling} className="component-progress" {...rest} />
)

export default Progress
