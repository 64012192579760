import React from "react"

// styles
import { css } from "@emotion/core"

import { Radio as Component } from "antd"

const stylingButton = ({ theme, size }) => css`
  ${theme.font.typo.bold600};
  font-size: ${theme.font.size.small};
  color: ${theme.textColorDefault};

  line-height: 110%;
  display: inline-flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  border-color: transparent;
  border-right: none;

  &:first-of-type {
    border-left: 1px solid transparent;
  }

  :not(.ant-radio-button-wrapper-checked) {
    background: ${theme.backgroundColorLighter};
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  padding: 0 8px;

  ${size === "small"
    ? css`
        &:first-of-type {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        &:last-of-type {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      `
    : null}
`

const stylingGroup = ({ theme, block }) => css`
  z-index: 0;
  position: relative;
  white-space: nowrap;

  ${block
    ? css`
        width: 100%;
      `
    : null}
`

const Radio = ({ children, ...rest }) => {
  return (
    <Component className="component-radio" data-testid="radio" {...rest}>
      {children}
    </Component>
  )
}

Radio.Group = ({ children, block, ...props }) => (
  <Component.Group
    css={(theme) => stylingGroup({ theme, block })}
    className="component-radio-group"
    data-testid="radio-group"
    {...props}
  >
    {children}
  </Component.Group>
)

Radio.Button = ({ children, ...props }) => (
  <Component.Button
    css={(theme) => stylingButton({ theme, size: props.size })}
    className="component-radio-button"
    data-testid="radio-group-button"
    {...props}
  >
    {children}
  </Component.Button>
)

export default Radio
