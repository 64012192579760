import React from "react"

// components
import {
  Select,
  Flex,
  Space,
  Dropdown,
  Menu,
  Radio,
  ActiveInactive,
} from "components"

// translation
import useTranslate from "hooks/useTranslate"

// styles
import styled from "@emotion/styled"

// assets
import { ReactComponent as IconBin } from "assets/icons/icon-bin.svg"
import { ReactComponent as IconDots } from "assets/icons/icon-dots.svg"
import { ReactComponent as IconArrowOrder } from "assets/arrows/arrow-order.svg"
import { ReactComponent as IconArrow } from "assets/arrows/arrow-thin.svg"

const Icon = styled.div`
  label: component-data-table--svg-icon;

  path {
    fill: ${({ selected }) => (selected ? "white" : "#48577A")};
  }
`

const Orders = ({
  schema,
  orders = [],
  onChangeOrder = () => null,
  onDeleteOrder = () => null,
  onMoveUp = () => null,
  onMoveDown = () => null,
}) => {
  const { t } = useTranslate(["ui"])

  return (
    <div>
      {orders.map((order, index) => (
        <Flex.Row
          key={order.id}
          gutter={8}
          fullWidth
          style={{ marginBottom: 4 }}
        >
          <Flex.Col span={14}>
            <Select
              block
              allowClear={false}
              defaultValue={order.field}
              value={order.field}
              onChange={(value) =>
                onChangeOrder(order.id, {
                  ...order,
                  field: value,
                  direction: "asc",
                })
              }
              placeholder={t("ui:datatable.form.placeholder.field.label")}
              items={schema.map((col) => ({
                id: col.key,
                value: col.key,
                label: col.title,
              }))}
            />
          </Flex.Col>
          <Flex.Col span={8}>
            <ActiveInactive active={order.field}>
              <Radio.Group
                defaultValue={order.direction}
                value={order.direction}
                buttonStyle="solid"
                onChange={(e) =>
                  onChangeOrder(order.id, {
                    ...order,
                    direction: e.target.value,
                  })
                }
              >
                <Radio.Button value="asc">
                  <Space>
                    <Icon selected={order.direction === "asc"}>
                      <IconArrowOrder
                        style={{
                          transform: "rotate(-180deg)",
                        }}
                      />
                    </Icon>
                    ASC
                  </Space>
                </Radio.Button>
                <Radio.Button value="desc">
                  <Space>
                    <Icon selected={order.direction === "desc"}>
                      <IconArrowOrder />{" "}
                    </Icon>
                    DESC
                  </Space>
                </Radio.Button>
              </Radio.Group>
            </ActiveInactive>
          </Flex.Col>

          <Flex.Col span={2}>
            <Dropdown.Button
              icon={<IconDots width="16px" />}
              type="text"
              size="small"
              trigger="click"
              arrow={false}
              overlay={
                <Menu
                  onClick={({ key }) => {
                    switch (key) {
                      case "move-up":
                        onMoveUp(order.id, index)
                        break
                      case "move-down":
                        onMoveDown(order.id, index)
                        break
                      case "delete":
                        onDeleteOrder(order.id)
                        break
                      default:
                        break
                    }
                  }}
                  items={[
                    ...(index === 0
                      ? []
                      : [
                          {
                            key: "move-up",
                            icon: (
                              <IconArrow
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                width="16px"
                              />
                            ),
                            label: t("ui:datatable.buttons.move-up.label"),
                          },
                        ]),
                    ...(index + 1 === orders.length
                      ? []
                      : [
                          {
                            key: "move-down",
                            icon: <IconArrow width="16px" />,
                            label: t("ui:datatable.buttons.move-down.label"),
                          },
                        ]),

                    {
                      key: "delete",
                      icon: <IconBin width="16px" height="16px" />,
                      label: t("ui:datatable.buttons.delete.label"),
                    },
                  ]}
                />
              }
            />
          </Flex.Col>
        </Flex.Row>
      ))}
    </div>
  )
}

export default Orders
