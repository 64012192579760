import React from "react"

// styles
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"

// components
import { Select, Flex, Space, Button, Typo } from "components"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"

// assets
import { ReactComponent as IconArrow } from "assets/arrows/arrow-thin.svg"

const Component = styled.div`
  label: component-data-table--pagination;

  margin-top: 4px;
  margin-right: -20px;
`

const Pagination = ({
  page,
  maxPages,
  pageSize,
  setPage = () => null,
  setPageSize = () => null,
}) => {
  const { t } = useTranslate(["ui"])

  return (
    <Component data-testid="datatable-pagination">
      <Flex.Row justify="end" gutter={16} fullWidth>
        <Flex.Col style={{ marginTop: 2 }}>
          <Space>
            <Typo.Text color="textColorLight" size="default">
              {t("ui:datatable.pagination.label")}
            </Typo.Text>

            <Select
              size="smaller"
              onChange={(value) => {
                setPageSize(value)
                setPage(1)
              }}
              allowClear={false}
              value={pageSize}
              defaultValue={pageSize}
              items={[
                { id: 10, value: 10, label: 10 },
                { id: 20, value: 20, label: 20 },
                { id: 50, value: 50, label: 50 },
                { id: 100, value: 100, label: 100 },
                { id: 200, value: 200, label: 200 },
                { id: 1000, value: 1000, label: 1000 },
              ]}
            />
          </Space>
        </Flex.Col>
        {maxPages > 1 && (
          <Flex.Col style={{ display: "flex", marginBottom: 2 }}>
            <Space style={{ columnGap: "0px" }} wrap>
              {page !== 1 && (
                <Button
                  grey
                  onClick={() => setPage((prev) => prev - 1)}
                  style={{ padding: "2.4px 0" }}
                  type="text"
                  size="small"
                  icon={
                    <IconArrow
                      style={{
                        transform: "rotate(90deg)",
                      }}
                    />
                  }
                />
              )}

              {new Array(maxPages).fill(null).map((_, pageIndex) =>
                maxPages < 10 ||
                (maxPages >= 10 &&
                  (pageIndex === 0 ||
                    Math.abs(page - pageIndex - 1) < 5 ||
                    pageIndex + 1 === maxPages)) ? (
                  <Typo.Text
                    style={{ cursor: "pointer" }}
                    color={
                      pageIndex + 1 === page
                        ? "themeColorDefault"
                        : "textColorLight"
                    }
                    key={pageIndex}
                    onClick={() => setPage(pageIndex + 1)}
                    margin="4px 4px 0 4px"
                    size="default"
                    font={pageIndex + 1 === page ? "bold700" : "bold600"}
                    underline={pageIndex + 1 === page}
                  >
                    {pageIndex + 1}
                  </Typo.Text>
                ) : maxPages >= 10 && Math.abs(page - pageIndex - 1) === 5 ? (
                  <Button
                    key={pageIndex}
                    onClick={() =>
                      setPage((prev) =>
                        pageIndex < page ? prev - 5 : prev + 5
                      )
                    }
                    style={{ padding: "2.4px 0", marginTop: 3 }}
                    size="small"
                    type="text"
                    icon={
                      pageIndex < page ? (
                        <DoubleLeftOutlined
                          style={{ fontSize: 12, color: "#98A4C0" }}
                        />
                      ) : (
                        <DoubleRightOutlined
                          style={{ fontSize: 12, color: "#98A4C0" }}
                        />
                      )
                    }
                  />
                ) : null
              )}

              {page !== maxPages && (
                <Button
                  grey
                  onClick={() => setPage((prev) => prev + 1)}
                  style={{ padding: "2.4px 0" }}
                  size="small"
                  type="text"
                  icon={
                    <IconArrow
                      style={{
                        transform: "rotate(-90deg)",
                      }}
                    />
                  }
                />
              )}
            </Space>
          </Flex.Col>
        )}
      </Flex.Row>
    </Component>
  )
}

export default Pagination
