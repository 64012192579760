import { is_number } from "./is_number"
import { getRatioString } from "./getRatioString"

export const renderAnalyticValue = (
  value,
  {
    unit = null,
    currency = "CAD",
    precision = 0,
    minPrecision = 0,
    nullRepresenter = "–",
    formatter = (v) => v,
    suffix = "",
    prefix = "",
    sign = false,
    alwaysShowUnits = false,
    languageCode = "en-CA",
    config = {},
    exclude = [],
  }
) => {
  const formatNumber = (v) => {
    return new Intl.NumberFormat(languageCode, {
      maximumFractionDigits: precision,
      minimumFractionDigits: minPrecision,

      ...config,
    }).format(v)
  }

  const currencyString = (() => {
    switch (currency) {
      case "CAD":
        return "$"

      case "EUR":
        return "E"

      default:
        return "$"
    }
  })()

  const formatByUnit = (v) => {
    switch (unit) {
      case "ratio":
        return getRatioString(v)

      case "currency":
        return `${v} ${currencyString}`

      case "currency-per-hour":
        return `${v} ${currencyString}/h`

      case "percent":
        return `${v}%`

      case "percentage":
        return `${Math.round(v * 100)}%`

      case "hour":
      case "amount-hour":
        return `${v}h`

      case "amount-patient":
        return `${v} n.p.`

      case "factor":
        return `${v}x`

      default:
        return v
    }
  }

  if (exclude.includes(value)) return nullRepresenter

  let result
  const invalidNumber = !is_number(value)

  if (invalidNumber) {
    if (alwaysShowUnits) {
      result = ""
    } else {
      return nullRepresenter
    }
  } else {
    result = formatNumber(value)
  }

  if (unit) result = formatByUnit(result)

  if (sign) {
    if (value === 0) result = "± " + result
    else if (value > 0) result = "+ " + result
    else if (value < 0) result = "- " + result.substring(1)
  }

  result = formatter(result)

  return `${prefix}${result}${suffix}`
}
