// packages
import moment from "moment"

// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
// FUNCTIONS
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export const isEqualDateTo = (
  value,
  dateObject = { day: 1, month: 0, year: 2000 }
) => {
  const momentValue = moment(value)

  const usedDateComparisionValues = Object.keys(dateObject).filter((key) =>
    Boolean(key)
  )

  const values = {
    day: momentValue.get("date"),
    month: momentValue.get("month"),
    year: momentValue.get("year"),
  }

  return usedDateComparisionValues.every(
    (key) => values[key] === dateObject[key]
  )
}
