import React from "react"

// styles
import styled from "@emotion/styled"

// assets
import { ReactComponent as CloseIcon } from "assets/icons/icon-close.svg"

function colorIsDark(hexcolor) {
  var r = parseInt(hexcolor.substr(1, 2), 16)
  var g = parseInt(hexcolor.substr(3, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness < 140
}

const Component = styled.div`
  label: component-tag;
  display: inline-flex;
  flex-direction: center;
  align-items: center;
  padding: ${({ small }) => (small ? "0px 8px" : "1px 8px")};
  height: ${({ height = "auto", small }) => (small ? "18px" : height)};
  max-width: ${({ maxWidth = "100%" }) => maxWidth};
  width: ${({ width = "auto" }) => width};
  margin: ${({ margin = "2px" }) => margin};

  background: ${({ theme, color }) => theme[color] || color};
  border: ${({ theme, borderColor }) =>
    `1px solid ${theme[borderColor] || borderColor}`};

  border-radius: ${({ round }) => (round ? "12px" : "6px")};

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme, textColor, color }) =>
    theme[textColor] ||
    (!colorIsDark(theme[color] || color)
      ? theme.textColorDefault
      : theme.textColorWhite)};

  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};

  &:hover {
    svg {
      opacity: 1;
    }
  }
`

const ComponentClose = styled.div`
  label: component-tag--close;

  margin-left: 4px;

  cursor: pointer;

  svg {
    opacity: 0;
    transition: opacity 300ms;
    path {
      fill: ${({ theme, textColor, color }) =>
        theme[textColor] ||
        (!colorIsDark(theme[color] || color)
          ? theme.textColorDefault
          : theme.textColorWhite)};
    }
  }
`

const ComponentIcon = styled.div`
  label: component-tag--icon;

  margin-right: 4px;

  svg path {
    fill: ${({ theme, textColor, color }) =>
      theme[textColor] ||
      (!colorIsDark(theme[color] || color)
        ? theme.textColorDefault
        : theme.textColorWhite)};
  }
`

const ComponentLabel = styled.span`
  label: component-tag--label;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Tag = ({
  small,
  height,
  width,
  maxWidth,
  label,
  textColor,
  color = "backgroundColorDefault",
  borderColor = "transparent",
  round,
  icon,
  onClose,
  onClick,
  margin,
  ...rest
}) => (
  <Component
    data-testid="tag"
    className="component-tag"
    small={small}
    height={height}
    maxWidth={maxWidth}
    width={width}
    color={color}
    textColor={textColor}
    margin={margin}
    borderColor={borderColor}
    round={round}
    onClick={onClick}
    isClickable={onClick !== undefined && onClick !== null}
    {...rest}
  >
    {icon && (
      <ComponentIcon textColor={textColor} color={color}>
        {icon}
      </ComponentIcon>
    )}

    <ComponentLabel>{label}</ComponentLabel>

    {onClose && (
      <ComponentClose
        color={color}
        onClick={onClose}
        data-testid="tag-close-button"
      >
        <CloseIcon width="8px" height="8px" />
      </ComponentClose>
    )}
  </Component>
)

export default Tag
