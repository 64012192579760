import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// component
import { Dropdown as Component } from "antd"
import { Button, Space } from "components"

// assets
import { ReactComponent as IconArrow } from "assets/arrows/arrow-triangle.svg"

const styling = (theme) => css`
  label: component-dropdown--styling;

  ${theme.font.typo.default};
  font-size: ${theme.font.size.default};
  color: ${theme.textColorLight};

  line-height: 150%;
  letter-spacing: 0.03em;

  transition: color 300ms;

  &:hover {
    cursor: pointer;
    color: ${theme.textColorLighter};
  }
`

const TriggerWrapper = styled.div`
  label: component-dropdown-trigger-wrapper;
  display: inline-flex;
  align-items: center;
`

const Trigger = styled.span`
  label: component-dropdown-trigger;
  margin-right: ${({ arrow }) => (arrow ? "8px" : "0px")};
`

const Arrow = styled.div`
  label: component-dropdown-icon-arrow;
  margin-bottom: 2px;

  transform: ${({ opened }) => (opened ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 300ms;
`

const Dropdown = ({
  autoClose = true,
  arrow = true,
  children,
  visible,
  onVisibleChange,
  ...rest
}) => {
  const [v, setV] = React.useState(visible)

  return (
    <Component
      css={styling}
      className="component-dropdown"
      {...rest}
      visible={autoClose ? visible : visible || v}
      onVisibleChange={
        autoClose
          ? onVisibleChange
          : onVisibleChange || ((state) => setV(state))
      }
      overlayStyle={{ zIndex: "1050" }}
      overlayClassName="component-dropdown-content"
    >
      <TriggerWrapper>
        <Trigger arrow={arrow}>{children}</Trigger>
        {arrow && (
          <Arrow opened={visible}>
            <IconArrow />
          </Arrow>
        )}
      </TriggerWrapper>
    </Component>
  )
}

const buttonStyling = (theme, props) => css`
  label: component-dropdownbutton--styling;

  .dropdownbutton-icon {
    path {
      fill: ${props.type === "primary"
        ? "white"
        : props.grey
        ? theme.textColorLighter
        : props.danger
        ? theme.textColorError
        : props.warning
        ? theme.textColorWarning
        : props.disabled
        ? theme.textColorDisabled
        : theme.themeColorDefault};
    }
  }

  .dropdownbutton-separator {
    border-left: 1px solid
      ${props.type === "primary"
        ? "white"
        : props.grey
        ? theme.textColorLighter
        : props.danger
        ? theme.textColorError
        : props.warning
        ? theme.textColorWarning
        : props.disabled
        ? theme.textColorDisabled
        : theme.themeColorDefault};
  }
`

const Seperator = styled.div`
  label: component-dropdownbutton-sep;
  position: absolute;
  right: 27px;
  top: -1px;
  bottom: -1px;
`

Dropdown.Button = ({
  autoClose,
  icon,
  type,
  round,
  grey,
  label,
  warning,
  loading,
  size,
  arrow,
  visible,
  disabled,
  danger,
  style,
  ...props
}) => (
  <Dropdown
    autoClose={autoClose}
    css={(theme) =>
      buttonStyling(theme, { type, grey, danger, warning, disabled })
    }
    className="component-dropdownbutton"
    visible={visible}
    overlayStyle={{ zIndex: "1050" }}
    overlayClassName="component-dropdown-content"
    arrow={false}
    {...props}
  >
    <Button
      data-testid="dropdownbutton"
      disabled={disabled}
      round={round}
      icon={icon}
      danger={danger}
      warning={warning}
      grey={grey}
      type={type}
      size={size}
      style={style}
      loading={loading}
      label={
        <Space>
          <span>{label}</span>

          {arrow && (
            <React.Fragment>
              {(type === "primary" || type === "secondary") && (
                <Seperator className="dropdownbutton-separator" />
              )}
              <IconArrow
                data-testid="dropdownbutton-icon"
                className="dropdownbutton-icon"
              />
            </React.Fragment>
          )}
        </Space>
      }
    />
  </Dropdown>
)

export default Dropdown
