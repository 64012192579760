import React from "react"

// firebase
import { FirebaseAuthService } from "services/firebase"

// store
import useWebuser from "store/api/webuser"

// logs
import debugModule from "utils/debug"
const debug = debugModule("firebase-auth")

const FirebaseAuthContext = React.createContext()

function useFirebaseAuth() {
  const context = React.useContext(FirebaseAuthContext)
  if (!context) {
    throw new Error(
      `useFirebaseAuth must be used within a FirebaseAuthProvider`
    )
  }

  const [firebaseUser] = context.firebaseUser
  const [status] = context.firebaseStatus
  const [tokenClaims] = context.firebaseToken

  return {
    firebaseUser,
    tokenClaims,
    status,
  }
}

function FirebaseAuthProvider(props) {
  const [firebase, setFirebase] = React.useState(null)
  const [token, setToken] = React.useState({})

  const [status, setStatus] = React.useState("idle")

  const currentEmail = React.useRef()
  const currentDisplayName = React.useRef()

  const webuser = useWebuser()

  React.useLayoutEffect(() => {
    const unsubscribe = FirebaseAuthService.onAuthStateChanged(
      async (user) => {
        if (user) {
          const claims = await user.getIdTokenResult()
          setFirebase(user)
          setToken({
            ...claims.claims,
            tenant_id: claims.claims.tenant_id,
            role: claims.claims.role,
          })

          debug.log(`Firebase-Tenant: Signed In`)
        } else {
          setFirebase(null)
          setToken({})
          debug.log(`Firebase-Tenant: Logged out!`)
        }

        setStatus("ready")
      },
      (error) => debug.error(error)
    )

    return () => {
      debug.log(`Signout Firebase Application`)
      unsubscribe()
    }
  }, [setFirebase, setToken])

  React.useEffect(() => {
    const asyncHandler = async () => {
      try {
        if (!firebase || !webuser.isReady) return

        const orcaWebuser = webuser.data
        const firebaseUser = firebase

        if (firebaseUser.uid !== orcaWebuser.webuser_id) {
          debug.log(`False Firebase-Tenant signed in!`)
          debug.log(`Logging out Firebase-Tenant ...`)
          await FirebaseAuthService.signOut()
          return
        }

        const webuserDisplayName = `${orcaWebuser.given_name} ${orcaWebuser.family_name}`

        if (
          currentEmail.current === orcaWebuser.email_address &&
          currentDisplayName.current === webuserDisplayName
        )
          return

        currentEmail.current = orcaWebuser.email_address
        currentDisplayName.current = webuserDisplayName

        debug.log(`Check Firebase-Tenant Information`)

        if (
          (firebaseUser.displayName !== webuserDisplayName &&
            webuserDisplayName !== "null null") ||
          firebaseUser.email !== orcaWebuser.email_address.email
        ) {
          debug.log(`Update Firebase-Tenant Infos!`)

          if (
            firebaseUser.displayName !== webuserDisplayName &&
            webuserDisplayName !== "null null"
          ) {
            debug.log(
              `Update (displayName) ${webuserDisplayName} --> ${firebaseUser.displayName}`
            )
            await firebaseUser.updateProfile({
              displayName: webuserDisplayName,
            })
          }
          if (firebaseUser.email !== orcaWebuser.email_address.email) {
            debug.log(
              `Update (email) ${orcaWebuser.email_address.email} --> ${firebaseUser.email}`
            )
            await firebaseUser.updateEmail(orcaWebuser.email_address.email)
          }
        }
      } catch (e) {
        console.error(e)
        debug.error(e)
      }
    }

    asyncHandler()
  }, [
    firebase,
    webuser.isReady,
    webuser.data,
    currentEmail,
    currentDisplayName,
  ])

  const firebaseUser = React.useMemo(() => [firebase, setFirebase], [firebase])
  const firebaseToken = React.useMemo(() => [token, setToken], [token])
  const firebaseStatus = React.useMemo(() => [status, setStatus], [status])
  return (
    <FirebaseAuthContext.Provider
      value={{ firebaseUser, firebaseToken, firebaseStatus }}
      {...props}
    />
  )
}

export { FirebaseAuthProvider, useFirebaseAuth }
