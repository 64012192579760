import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// component
import { Popover } from "antd"

// picker
import { BlockPicker } from "react-color"

// assets
import { ReactComponent as Arrow } from "assets/arrows/arrow-triangle.svg"

const Component = styled.div`
  label: component-color-picker;

  position: relative;
  display: inline-flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;

  width: 50px;
  height: 28px;

  border: 1px solid ${({ theme }) => theme.borderColorLight};
  box-sizing: border-box;
  border-radius: 8px;
`

const Color = styled.div`
  label: component-color-picker--color;

  width: 16px;
  height: 16px;
  border-radius: 4px;

  border: 1px solid ${({ theme }) => theme.borderColorLight};
  background-color: ${({ color }) => color};
`

const ColorPicker = ({ value, onChange, ...props }) => {
  return (
    <Popover
      placement="right"
      content={
        <BlockPicker
          css={(theme) => css`
            box-shadow: rgb(0 0 0 / 10%) 0px 0px 12px 5px !important;

            input {
              display: none;
            }

            > div {
              &:first-of-type {
                display: none;
              }

              &:nth-of-type(2) {
                > div {
                  display: none;
                }
              }
            }
          `}
          onChange={(color) => onChange(color.hex)}
          color={{ hex: value }}
          width={250}
          colors={[
            "#444444",
            "#12243B",
            "#1E2C4D",
            "#2D3B63",
            "#3B4A6E",
            "#48577A",
            "#5D6C8F",
            "#626D8C",
            "#75809D",
            "#8690AD",
            "#98A4C0",
            "#A6B2CE",
            "#B9C2DA",
            "#D9DEEB",
            "#E3E7F1",
            "#EBEFF7",
            "#F5F7FB",
            "#FFFFFF",
            "#00359E",
            "#094CC2",
            "#156CF7",
            "#1D88FF",
            "#59A8FF",
            "#AED7FF",
            "#CAE6FF",
            "#41F3FF",
            "#018BD1",
            "#00AAFF",
            "#38D5FF",
            "#7FE4FF",
            "#9FEAFF",
            "#B1EEFF",
            "#D8F7FF",
            "#007372",
            "#00897C",
            "#5FCEBD",
            "#94E3D7",
            "#C1F1EA",
            "#007457",
            "#00A375",
            "#00C991",
            "#48DE9E",
            "#71E8B8",
            "#A6EDCF",
            "#D2F6E6",
            "#FF8C2D",
            "#FF9E3B",
            "#FFBF00",
            "#FFD55F",
            "#FFE88C",
            "#FFF3B8",
            "#FDF6D9",
            "#C12E4D",
            "#D14957",
            "#FF5E69",
            "#FF7B7B",
            "#FD8888",
            "#FDB4B4",
            "#FDD8D8",
            "#493BB1",
            "#5342CC",
            "#6754EC",
            "#9283FF",
            "#A598FF",
            "#BAB0FF",
            "#E0DCFF",
          ]}
          {...props}
        />
      }
      trigger="click"
    >
      <Component>
        <Color color={value ? value : "white"} />
        <Arrow />
      </Component>
    </Popover>
  )
}

export default ColorPicker
