import { css } from "@emotion/core"

export default css`
  .alert-enter {
    opacity: 0 !important;
    max-height: 0px !important;
  }
  .alert-enter-active {
    opacity: 1 !important;
    max-height: 1000px !important;
    transition: opacity 300ms 0.65s, max-height 500ms 0.65s !important;
  }
  .alert-exit {
    opacity: 1 !important;
  }
  .alert-exit-active {
    max-height: 0px !important;
    border-width: 0px !important;
    margin: 0px !important;
    opacity: 0 !important;
    transition: opacity 300ms, max-height 500ms, border-width 500ms,
      margin 500ms !important;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  .appearing-enter {
    opacity: 0;
  }
  .appearing-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .appearing-exit {
    opacity: 1;
  }
  .appearing-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  .fade-enter {
    opacity: 0;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  .slowfade-enter {
    opacity: 0;
  }

  .slowfade-enter.slowfade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .slowfade-leave {
    opacity: 1;
  }

  .slowfade-leave.slowfade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  .flip-enter {
    will-change: transform, height, opacity;
    height: 0px !important;
    opacity: 0;
    /*transform: scaleY(0) !important;*/
  }

  .flip-enter.flip-enter-active {
    height: 2rem !important;
    opacity: 1;
    transition: transform 0.3s ease-out, height 0.3s, opacity 0.3s;
  }

  .flip-leave {
    will-change: transform, height, opacity;
    height: 2rem !important;
    opacity: 1;
  }

  .flip-leave.flip-leave-active {
    height: 0px !important;
    opacity: 0;

    transition: transform 0.1s ease-out, height 0.1s, opacity 0.1s;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  .zooming-enter {
    will-change: transform, width;
    transform: scale(0) !important;
    width: 0rem !important;
  }

  .zooming-enter.zooming-enter-active {
    transform: scale(1);
    width: 4rem !important;
    transition: transform 100ms ease-in, width 100ms ease-in;
  }

  .zooming-leave {
    will-change: transform, width;
    transform: scale(1);
    width: 4rem;
  }

  .zooming-leave.zooming-leave-active {
    transform: scale(0.1);
    width: 0rem;
    transition: transform 300ms ease-in, width 300ms ease-in;
  }
`
