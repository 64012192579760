// styles
// import { useTheme } from "emotion-theming"

export default ({ width, height, ...props }) => {
  // const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.66 28.979"
      {...props}
    >
      <defs>
        <linearGradient
          id="logo-linear-gradient"
          x1="28.119"
          y1="-5.087"
          x2="-1.01"
          y2="23.459"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#41f3ff" />
          <stop offset="1" stopColor="#156cf7" />
        </linearGradient>
      </defs>
      <path
        fill="url(#logo-linear-gradient)"
        d="M4.686,28.561,0,.291A.251.251,0,0,1,.25,0H10.606a.5.5,0,0,1,.5.446L12.855,16.56,22.011.255A.5.5,0,0,1,22.447,0H33.41a.25.25,0,0,1,.213.38L16.361,28.739a.5.5,0,0,1-.427.24H5.179A.5.5,0,0,1,4.686,28.561Z"
      />
    </svg>
  )
}
