// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Component = styled.div`
  label: component-typo;
`

const ComponentTitle = styled.div`
  label: ${({ level }) => `component-typo--title-${level}`};
  margin: ${({ margin = "0 0 0 0" }) => margin};

  display: ${({ inline }) => (inline ? "inline-block" : "block")};

  ${({ space }) =>
    space
      ? css`
          margin-bottom: ${space};
        `
      : null}

  text-align : ${({ textAlign = "left" }) => textAlign};
  text-transform: ${({ textTransform = "inherit" }) => textTransform};

  ${({ theme, font, size, color, level = 1 }) => {
    if (!font) {
      if (level === 1) {
        font = "bold800"
      }

      if (level === 2) {
        font = "bold700"
      }

      if (level === 3) {
        font = "bold700"
      }

      if (level === 4) {
        font = "bold700"
      }
      if (level === 5) {
        font = "bold700"
      }
    }
    if (!size) {
      if (level === 1) {
        size = "larger"
      }

      if (level === 2) {
        size = "bigger"
      }

      if (level === 3) {
        size = "taller"
      }
      if (level === 4) {
        size = "tall"
      }
      if (level === 5) {
        size = "default"
      }
    }
    if (!color) {
      if (level === 1) {
        color = "textColorDefault"
      }

      if (level === 2) {
        color = "textColorDefault"
      }

      if (level === 3) {
        color = "textColorDefault"
      }
      if (level === 4) {
        color = "textColorDefault"
      }
    }

    switch (level) {
      case 1:
        return css`
          ${theme.font.typo[font]};
          font-size: ${theme.font.size[size]};
          color: ${theme[color]};

          line-height: 100%;
          letter-spacing: -0.02em;
        `

      case 2:
        return css`
          ${theme.font.typo[font]};
          font-size: ${theme.font.size[size]};
          color: ${theme[color]};

          line-height: 120%;
          letter-spacing: -0.02em;
        `

      case 3:
        return css`
          ${theme.font.typo[font]};
          font-size: ${theme.font.size[size]};
          color: ${theme[color]};

          line-height: 120%;
        `

      case 4:
        return css`
          ${theme.font.typo[font]};
          font-size: ${theme.font.size[size]};
          color: ${theme[color]};

          line-height: 150%;
        `

      case 5:
        return css`
          ${theme.font.typo[font]};
          font-size: ${theme.font.size[size]};
          color: ${theme[color]};

          line-height: 150%;
        `

      default:
        return css``
    }
  }}
`

Component.Title = ComponentTitle
ComponentTitle.defaultProps = { "data-testid": "title" }

const ComponentText = styled.span`
  label: component-typo--text;
  margin: ${({ margin = "0 0 0 0" }) => margin};

  ${({ theme, font = "default" }) =>
    theme.font.typo[font[0].toUpperCase() + font.substr(1)]};

  font-style: ${({ fontStyle = "inherit", italic }) =>
    italic ? "italic" : fontStyle} !important;
  text-transform: ${({ textTransform = "inherit" }) => textTransform};
  text-decoration: ${({ textDecoration = "inherit", underline }) =>
    underline ? "underline" : textDecoration};
  text-align: ${({ textAlign = "left" }) => textAlign};

  white-space: ${({ breakSpaces, whiteSpace = "inherit" }) =>
    breakSpaces ? "break-spaces" : whiteSpace};

  word-break: ${({ breakWord = true, wordBreak = "inherit" }) =>
    breakWord ? "break-word" : wordBreak};

  font-size: ${({ theme, inherit, size = "default" }) =>
    inherit ? "inherit" : theme.font.size[size]} !important;
  color: ${({ theme, inherit, color = "textColorDefault" }) =>
    inherit ? "inherit" : theme[color] || color} !important;

  line-height: ${({ lineHeight = "150%" }) => lineHeight};
  letter-spacing: 0.03em;

  display: ${({ block, inline = true }) =>
    block ? "block" : inline ? "inline" : "inherit"};

  ${({ space }) =>
    space
      ? css`
          margin-bottom: ${space};
        `
      : null}

  ${({ hoverable, theme }) =>
    hoverable
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${theme.themeColorExtraLight};
          }
        `
      : null}

  ${({ link, theme }) =>
    link
      ? css`
          cursor: pointer;
          &:hover {
            color: ${theme.themeColorLight};
          }
        `
      : null}
`

Component.Text = ComponentText
ComponentText.defaultProps = { "data-testid": "text" }

const ComponentParagraph = styled.div`
  label: component-typoe--paragraph;
  margin: ${({ margin = "0 0 0 0" }) => margin};

  text-align: ${({ textAlign = "left" }) => textAlign};
  text-transform: ${({ textTransform = "inherit" }) => textTransform};
  ${({ theme, font = "default" }) => theme.font.typo[font]};

  font-style: ${({ fontStyle = "inherit", italic }) =>
    italic ? "italic" : fontStyle};

  font-size: ${({ theme, size = "default" }) => theme.font.size[size]};
  color: ${({ theme, color = "textColorDefault" }) => theme[color] || color};

  line-height: ${({ lineHeight = "150%" }) => lineHeight};
  letter-spacing: 0.03em;

  max-width: ${({ maxWidth = "100%" }) => maxWidth};

  ${({ space }) =>
    space
      ? css`
          margin-bottom: ${space};
        `
      : null}
`

ComponentParagraph.defaultProps = { "data-testid": "paragraph" }
Component.Paragraph = ComponentParagraph

export default Component
