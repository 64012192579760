export const getRatioString = (ratio) => {
  if (!ratio) return undefined

  const v1 = Math.round((100 * ratio) / (1 + ratio))
  const v2 = 100 - v1

  // x / y = ratio
  //  y + x = 100
  //
  //  y = 100 - x
  //
  // x = ratio *  (100 - x)
  //
  // x = 100 * ratio - ratio * x
  //  x + ratio * x = 100 * ratio
  //
  //  x(1+ ratio)  = 100 * ratio
  //
  //  x = (100 * ratio) / (1 + ratio)

  return `${Math.round(v1)}/${Math.round(v2)}`
}
