import React from "react"

// assets
import { ReactComponent as IconColText } from "assets/table/table-col-text.svg"
import { ReactComponent as IconColNumber } from "assets/table/table-col-number.svg"
import { ReactComponent as IconColSelection } from "assets/table/table-col-selection.svg"
import { ReactComponent as IconColFormular } from "assets/table/table-col-formular.svg"
import { ReactComponent as IconColAction } from "assets/table/table-col-action.svg"
import { ReactComponent as IconColDate } from "assets/table/table-col-date.svg"
import { ReactComponent as IconColBool } from "assets/table/table-col-bool.svg"

export default (type) => {
  switch (type) {
    case "date":
    case "daterange":
      return <IconColDate />
    case "action":
      return <IconColAction />
    case "text":
      return <IconColText />
    case "bool":
      return <IconColBool />
    case "select":
      return <IconColSelection />
    case "number":
      return <IconColNumber />
    case "formular":
      return <IconColFormular />
    default:
      throw new Error(`type=${type} is not supported`)
  }
}
