import React from "react"

// styles
import { css } from "@emotion/core"

import { Divider as Component } from "antd"

const styling = (theme) => css`
  label: component-divider--styling;

  margin: 10px 0;
`

const Divider = ({ children, ...rest }) => {
  return (
    <Component css={styling} className="component-divider" {...rest}>
      {children}
    </Component>
  )
}

export default Divider
