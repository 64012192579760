import React from "react"

// styles
import { css, Global } from "@emotion/core"

// component
import { DatePicker as Component } from "antd"

// assets
import { ReactComponent as CloseIcon } from "assets/icons/icon-close-filled.svg"

const stylingRangepicker = (theme, props) => css`
  label: component-rangepicker--styling;

  background-color: ${theme.backgroundColorExtraLight};
  border: none;

  width: ${props.block ? "100%" : "auto"};
  padding: 5.6px;
  ${theme.height[props.size]};
  ${theme.round};

  &:hover:not(:focus):not(.ant-picker-disabled) {
    background-color: ${theme.backgroundColorLighter};
    ${props.hasValue &&
    css`
      .ant-picker-suffix {
        opacity: 0;
      }
    `}
  }

  .ant-picker-clear {
    background: transparent;

    svg {
      margin-top: 4px;

      rect {
        opacity: 0.7;
      }

      &:hover {
        rect {
          opacity: 0.9;
        }
      }
    }
  }

  &:focus {
    background-color: ${theme.backgroundColorExtraLight};
  }
`

const stylingDatepicker = (theme, props) => css`
  label: component-datepicker--styling;

  background-color: ${theme.backgroundColorExtraLight};
  border: none;

  padding: 5.6px;
  ${theme.height[props.size]};
  ${theme.round};
  width: ${props.block ? "100%" : "auto"};

  &:hover:not(:focus):not(.ant-picker-disabled) {
    background-color: ${theme.backgroundColorLighter};

    ${props.hasValue &&
    css`
      .ant-picker-suffix {
        opacity: 0;
      }
    `}
  }

  .ant-picker-clear {
    background: transparent;

    svg {
      margin-top: 4px;

      rect {
        opacity: 0.7;
      }

      &:hover {
        rect {
          opacity: 0.9;
        }
      }
    }
  }

  &:focus {
    background-color: ${theme.backgroundColorExtraLight};
  }
`

const stylingDatepickerDropdown = (theme) => css`
  .component-datepicker-dropdown {
    .ant-picker-panel-container {
      ${theme.round}
      ${theme.shadow}
      border: 1px solid #e3e7f1;
      box-sizing: border-box;

      .ant-picker-date-panel {
        width: 222px;

        .ant-picker-content {
          width: auto;

          thead {
            tr {
              th {
                color: ${theme.textColorDefault};
                ${theme.font.typo.bold800};
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .ant-picker-month-btn {
      text-transform: uppercase;
    }

    .ant-picker-cell-inner {
      ${theme.font.typo.bold600};
    }

    .ant-picker-cell-in-view .ant-picker-cell-inner {
      color: ${theme.textColorDefault};
      ${theme.font.typo.bold600};
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      color: white;
      ${theme.font.typo.bold700};

      border-radius: 100%;
    }

    .ant-picker-cell-today .ant-picker-cell-inner {
      &::before {
        border-radius: 100%;
      }
      border-radius: 100%;
    }

    * {
      ${theme.font.typo.default};
      font-size: ${theme.font.size.small};
      line-height: 15px;
      letter-spacing: 0.56746px;
    }
  }
`

const DatePicker = ({ loading, size = "default", block, ...props }) => {
  return (
    <React.Fragment>
      <Global styles={stylingDatepickerDropdown} />
      <Component
        data-testid="datepicker"
        clearIcon={<CloseIcon />}
        css={(theme) =>
          stylingDatepicker(theme, {
            block,
            size,
            loading,
            hasValue: Boolean(props.value),
          })
        }
        className="component-datepicker"
        dropdownClassName="component-datepicker-dropdown"
        {...props}
      />
    </React.Fragment>
  )
}

const RangePicker = React.forwardRef(
  ({ loading, size = "default", block, ...props }, ref) => {
    return (
      <React.Fragment>
        <Global styles={stylingDatepickerDropdown} />
        <Component.RangePicker
          data-testid="rangepicker"
          clearIcon={<CloseIcon />}
          css={(theme) =>
            stylingRangepicker(theme, {
              block,
              size,
              loading,
              hasValue: Boolean(props.value),
            })
          }
          className="component-rangepicker"
          dropdownClassName="component-datepicker-dropdown"
          ref={ref}
          {...props}
        />
      </React.Fragment>
    )
  }
)

DatePicker.RangePicker = RangePicker

export default DatePicker
