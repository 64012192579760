import React from "react"

// styles
import styled from "@emotion/styled"

const AreaNormal = styled.div`
  label: component-area;
`

const AreaHorizontal = styled.div`
  label: component-area--horizontal;
  width: 100%;
  overflow-x: scroll;
`

const Area = () => <AreaNormal />

const H = ({ children, ...rest }) => <AreaHorizontal>{children}</AreaHorizontal>

Area.H = H

export default Area
