import React from "react"

// sentry
import * as Sentry from "@sentry/react"

// errors
import { ErrorBoundary } from "react-error-boundary"

// translations
import { useTranslation } from "react-i18next"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// components
import { Typo, Button, Spacer } from "components"

// logs
import debugModule from "utils/debug"
const debug = debugModule("error-fallback")

const Compoment = styled.div`
  label: component-error-fallback;

  background: #ff00000f;
  padding: ${({ small }) => (small ? "10px" : "10vmin")};

  width: 100%;
  max-width: 100%;

  ${({ center }) =>
    center
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: auto;
        `
      : null}

  ${({ theme, fullscreen }) =>
    theme.media(
      ">tablet",
      css`
        max-width: ${fullscreen ? "100%" : "600px"};
      `
    )};
`

const ErrorMessage = styled.pre`
  color: ${({ theme }) => theme.textColorError};
  word-break: break-all;
  white-space: break-spaces;
  font-weight: bold;
  margin-bottom: 0;
  font-size: ${({ size }) => (size ? "12px" : `${size}px`)};
`

const ErrorFallback = ({
  part = "app",
  center = false,
  fullscreen = false,
  small,
  showUi = true,
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation(["ui", "common", "validation"])

  React.useEffect(() => {
    if (error) {
      const errorText = `[${part}] Crashed: ${error.message}`
      Sentry.captureMessage(new Error(errorText))
      debug.error(errorText)
    }
  }, [error, part])

  if (small)
    return (
      <Compoment role="alert" center={center} small={small}>
        <Typo.Title
          level={4}
          space="12px"
          color="textColorError"
          textTransform="none"
        >
          {t("validation:message.types.error.__.general.label")}
        </Typo.Title>

        <ErrorMessage size={10}>{`Code: ${part}`}</ErrorMessage>
        {error && <ErrorMessage size={10}>{error.message}</ErrorMessage>}

        {showUi && (
          <React.Fragment>
            <Spacer margin="18px" />

            <Button
              size="small"
              type="primary"
              label={t("ui:button.reload.label", { context: "app" })}
              onClick={resetErrorBoundary}
            />
          </React.Fragment>
        )}
      </Compoment>
    )

  return (
    <Compoment role="alert" center={center} fullscreen={fullscreen}>
      <Typo.Title
        level={3}
        space="16px"
        color="textColorError"
        textTransform="none"
      >
        {t("validation:message.types.error.__.general.label", {
          context: "long",
        })}
      </Typo.Title>

      <ErrorMessage>{`Code: ${part}`}</ErrorMessage>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <Spacer margin="8px" />

      <Typo.Paragraph textAlign={center ? "center" : null}>
        {t("common:error-fallback.label")}
      </Typo.Paragraph>

      {showUi && (
        <React.Fragment>
          <Spacer margin="24px" />

          <Button
            type="primary"
            label={t("ui:button.reload.label", { context: "app" })}
            onClick={resetErrorBoundary}
          />
        </React.Fragment>
      )}
    </Compoment>
  )
}

const withErrorBoundary =
  (Component, furtherProps = {}) =>
  (componentProps = {}) =>
    (
      <ErrorBoundary
        FallbackComponent={(fallbackComponentProps) => (
          <ErrorFallback {...furtherProps} {...fallbackComponentProps} />
        )}
        onReset={() => {
          window.location.reload()
        }}
      >
        <Component {...componentProps} />
      </ErrorBoundary>
    )

ErrorFallback.withErrorBoundary = withErrorBoundary

export default ErrorFallback
