// utils
import { get, orderBy } from "lodash"
import { stringMatchesTo } from "utils/functions"

export default (data, { schema, filters, orders = [], search = "" }) => {
  return orderBy(
    data.filter((record) => {
      const searchableColumnKeys = schema
        .filter((col) => col.searchable)
        .map((col) => col.key)

      const stringSearchMatches =
        !search ||
        search === "" ||
        searchableColumnKeys.some((key) => {
          const col = schema.find((col) => col.key === key)

          const searchValue = col.search
            ? col.search(get(record, key), record)
            : get(record, key)

          if (searchValue === undefined)
            throw new Error(
              `can not search column=${key}, please add search function:(value,record)=>string to column schema! Failing record: ${JSON.stringify(
                record
              )}`
            )

          return (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== "" &&
            searchValue &&
            stringMatchesTo(searchValue || "", search)
          )
        })

      const checkFilter = (f, item) => {
        if (!f) return true

        if (!f.or && !f.and) {
          const col = schema.find((col) => col.key === f.field)

          if (!col) return true

          const recordValue = col.filter
            ? col.filter(item[f.field], item)
            : item[f.field]

          if (recordValue === undefined) {
            console.log({ field: f.field, item, recordValue, f: col.filter })
            throw new Error(
              `can not filter column=${
                f.field
              }, please add filter function:(value,record)=>string to column schema! Failing record: ${JSON.stringify(
                item
              )}`
            )
          }

          switch (f.operator) {
            case "equals": {
              switch (col.type) {
                case "number": {
                  if (f.value === null || isNaN(Number(f.value))) return true

                  return recordValue === Number(f.value)
                }

                default: {
                  if (
                    f.value === "" ||
                    f.value === null ||
                    f.value === undefined
                  )
                    return true

                  return (
                    recordValue === f.value ||
                    (!isNaN(Number(recordValue)) &&
                      Number(recordValue) === Number(f.value))
                  )
                }
              }
            }

            case "notEquals": {
              switch (col.type) {
                case "number": {
                  if (f.value === null || isNaN(Number(f.value))) return true

                  return recordValue !== Number(f.value)
                }

                default: {
                  if (
                    f.value === "" ||
                    f.value === null ||
                    f.value === undefined
                  )
                    return true

                  return recordValue !== f.value
                }
              }
            }

            case "gte": {
              if (f.value === null || isNaN(Number(f.value))) return true

              return recordValue >= Number(f.value)
            }

            case "gt": {
              if (f.value === null || isNaN(Number(f.value))) return true

              return recordValue > Number(f.value)
            }

            case "lte": {
              if (f.value === null || isNaN(Number(f.value))) return true

              return recordValue <= Number(f.value)
            }

            case "lt": {
              if (f.value === null || isNaN(Number(f.value))) return true

              return recordValue < Number(f.value)
            }

            case "set":
              return recordValue !== null && recordValue !== undefined

            case "notSet":
              return recordValue === null || recordValue === undefined

            case "empty":
              return (
                recordValue === "" ||
                recordValue === null ||
                recordValue === undefined
              )

            case "notEmpty":
              return (
                recordValue !== "" &&
                recordValue !== null &&
                recordValue !== undefined
              )

            case "contains":
              return !f.value || (recordValue && recordValue.includes(f.value))

            default:
              return true
          }
        }

        return (
          (f.or && f.or.some((i) => checkFilter(i, item))) ||
          (f.and && f.and.every((i) => checkFilter(i, item)))
        )
      }

      return stringSearchMatches && checkFilter(filters, record)
    }),
    orders.map((o) => (record) => {
      const col = schema.find((col) => col.key === o.field)

      if (!col) return true

      const orderValue = col.order
        ? col.order(record[o.field], record)
        : record[o.field]

      if (orderValue === undefined)
        throw new Error(
          `can not order column=${
            o.field
          }, please add order function:(value,record)=>string to column schema! Failing record: ${JSON.stringify(
            record
          )}`
        )

      switch (col.type) {
        case "date":
        case "daterange":
          return new Date(orderValue)

        case "number":
          return Number(orderValue)

        case "text":
        case "select":
        case "formular":
        case "bool":
        default:
          return orderValue
      }
    }),
    orders.map((o) => o.direction)
  )
}
