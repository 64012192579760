// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 6,
  height = 6,
  color = "themeColorDefault",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 6"
      {...props}
    >
      <circle cx="3" cy="3" r="3" fill={theme[color]} />
    </svg>
  )
}
