// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-square;
  width: 16px;
  height: 16px;

  margin-right: 4px;

  border-radius: 4px;

  background: ${({ color }) => color};
`

export default Component
