import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// component
import { Collapse as Component } from "antd"

// assets
import { ArrowThick } from "assets"

const IconWrapper = styled.div`
  label: component-collapse--icon-wrapper;

  svg {
    margin-top: 2px;

    transition: transform 300ms;

    transform: ${({ isActive }) =>
      isActive ? "rotate(0deg)" : "rotate(-90deg)"};
  }
`

const Wrapper = styled.div`
  label: component-toggle--wrapper;
`

// styling
const styling = (theme, { indent, toggled, disabled, hoverable, space }) => css`
  label: component-collapse--styling;

  background: none;
  border-radius: 16px;
  border: none;

  padding: 0;

  .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }

  > .ant-collapse-item {
    background: none;
    border-bottom: none;

    overflow: hidden;

    > .ant-collapse-header {
      background: none;
      border-bottom: none;
      padding: 0;
      opacity: ${!hoverable || toggled ? 1 : 0.7};
      cursor: ${disabled ? "default" : "pointer"};
      transition: opacity 300ms;

      .ant-collapse-header-text {
        font-weight: 600;
      }

      .ant-collapse-arrow {
        margin-right: 4px;
        vertical-align: 2px;
      }

      &:hover {
        opacity: ${hoverable && disabled ? 0.5 : 1};
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
    padding-top: ${space} !important;
    padding-left: ${indent ? "15px" : "0px"} !important;
  }

  .ant-collapse-content {
    border-top: none;
  }
`

const Toggle = ({
  children,
  label,
  space = "0px",
  disabled = false,
  hoverable = true,
  defaultActive,
  active,
  indent = false,
  onChange = () => null,
  wrapperProps = {},
  ...rest
}) => {
  const [toggled, setToggled] = React.useState(
    defaultActive !== undefined
      ? defaultActive
      : active !== undefined
      ? active
      : false
  )

  React.useEffect(() => {
    if (active !== undefined) setToggled(active)
  }, [active])

  return (
    <Wrapper
      data-testid="toggle"
      data-toggle-state={toggled ? "visible" : "hidden"}
      {...wrapperProps}
    >
      <Component
        disabled={disabled}
        ghost
        activeKey={toggled ? ["toggle"] : []}
        onChange={(v) => {
          if (disabled) return

          setToggled(v.length)
          onChange(v.length)
        }}
        className="component-toggle"
        css={(theme) =>
          styling(theme, { indent, space, disabled, toggled, hoverable })
        }
        expandIcon={(props) => {
          // NOTE:
          // props object:
          //
          // accordion: false
          // children: {$$typeof: Symbol(react.element), key: null, ref: null, props: {…}, type: ƒ, …}
          // className: "component-panel"
          // collapsible: undefined
          // destroyInactivePanel: false
          // expandIcon: ƒ renderExpandIcon()
          // forceRender: false
          // header: {$$typeof: Symbol(react.element), type: {…}, key: null, ref: null, props: {…}, …}
          // headerClass: ""
          // isActive: true
          // onItemClick: ƒ (key)
          // openMotion: {motionName: "ant-motion-collapse", onAppearStart: ƒ, onEnterStart: ƒ, onAppearActive: ƒ, onEnterActive: ƒ, …}
          // panelKey: "2021-04"
          // prefixCls: "ant-collapse"
          // showArrow: true

          return (
            <IconWrapper isActive={props.isActive} data-testid="toggle-arrow">
              <ArrowThick color="textColorDefault" />
            </IconWrapper>
          )
        }}
        {...rest}
      >
        <Component.Panel key="toggle" header={label}>
          {children}
        </Component.Panel>
      </Component>
    </Wrapper>
  )
}

export default Toggle
