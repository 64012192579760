import React from "react"

// styles
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"

// animation
import { CSSTransition } from "react-transition-group"

// component
// import { Spinner } from "components"
import { LoadingOutlined } from "@ant-design/icons"

const Component = styled.div`
  label: component-loader;

  background-color: ${({ theme, dark }) =>
    dark ? theme.overlayColorDark : theme.overlayColorDefault};

  position: ${({ fullscreen }) => (fullscreen ? "fixed" : "absolute")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ top }) => (top ? "9999999" : "1")};
  backdrop-filter: blur(2px);
`

const Message = styled.span`
  label: component-loader--message;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme, dark }) =>
    dark ? theme.textColorWhite : theme.textColorLighter};

  margin: 1rem;
`

const Loader = ({
  loading = true,
  timeout = 300,
  fullscreen = false,
  top = true,
  icon,
  dark,
  content,
  children,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <CSSTransition
      in={Boolean(loading)}
      timeout={timeout}
      classNames="fade"
      unmountOnExit
    >
      <Component
        data-testid="loader"
        className="component-loader"
        dark={dark}
        fullscreen={fullscreen}
        top={top}
        {...rest}
      >
        {content ? (
          content
        ) : (
          <React.Fragment>
            {icon ? (
              icon
            ) : (
              <LoadingOutlined
                style={{ fontSize: "24px", color: theme.themeColorDefault }}
              />
            )}
            {children && <Message dark={dark}>{children}</Message>}
          </React.Fragment>
        )}
      </Component>
    </CSSTransition>
  )
}

const ComponentBox = styled.div`
  label: component-loader-box;
  min-height: 100px;
  height: ${({ height = "100px" }) => height};
  ${({ isloading, theme }) => (isloading ? theme.skeleton.loading : null)}
`

Loader.Box = ({ loading, ...props }) => (
  <ComponentBox isloading={loading} {...props} />
)

export default Loader
