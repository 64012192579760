import React from "react"

// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-title-with-line;
  margin-bottom: 32px;
`

const Line = styled.hr`
  label: component-title-with-line-line;
  background-color: ${({ theme }) => theme.borderColorLighter};
  border: none;
  height: 1px;
`

const Title = styled.h3`
  label: component-title-with-line-title;

  ${({ theme }) => theme.font.typo.bold700};
  font-size: ${({ theme }) => theme.font.size.tall};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 130%;
  letter-spacing: 0.01em;
`

const TitleWithLine = ({ title }) => (
  <Component>
    <Title>{title}</Title>
    <Line />
  </Component>
)

export default TitleWithLine
