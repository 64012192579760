import React from "react"

// styles
import { css } from "@emotion/core"

// components
import { InputNumber as Component } from "antd"

const styling = (theme) => css`
  label: component-input-number--styling;

  background: ${theme.backgroundColorExtraLight};
  border: none;
  box-sizing: border-box;
  border-radius: 4px;

  height: 44px;
  margin: 4px 0px;
  padding: 5px 8px;

  width: 100%;

  cursor: pointer;

  &:hover {
    background: ${theme.backgroundColorLighter};
  }

  &:focus {
    cursor: text;
    background: ${theme.backgroundColorExtraLight};
  }

  input {
    ${theme.font.typo.bold800};
    font-size: ${theme.font.size.extraBig};
    color: ${theme.textColorDefault};

    height: 100%;

    padding: 0;
    letter-spacing: -0.02em;
    line-height: 110%;
  }

  .ant-input-number-input-wrap {
    height: 100%;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`

const InputNumber = ({ ...props }) => {
  return (
    <Component className="component-input-number" {...props} css={styling} />
  )
}

export default InputNumber
