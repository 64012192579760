import React from "react"

// component
import { Row, Col } from "antd"

// styles
import { css } from "@emotion/core"

const stylingRow = ({
  theme,
  block,
  fullHeight,
  fullWidth,
  width,
  height,
  space,
}) => css`
  label: component-lex--frow--styling;

  ${height || fullHeight
    ? css`
        height: ${height ? height : "100%"};
      `
    : null}

  ${block || width || fullWidth
    ? css`
        width: ${width ? width : "100%"};
      `
    : null}

  margin-bottom: ${typeof space === "number" ? `${space}px` : space}
`

const ComponentRow = ({
  block,
  fullHeight,
  fullWidth,
  width,
  height,
  space = 0,
  ...props
}) => {
  return (
    <Row
      css={(theme) =>
        stylingRow({
          theme,
          block,
          fullHeight,
          fullWidth,
          width,
          height,
          space,
        })
      }
      className="component-flex--row"
      {...props}
    />
  )
}

const stylingCol = ({ theme, center, align, justify }) => css`
  label: component-flex--col--styling;

  ${center || align || justify
    ? css`
        display: flex;
        flex-direction: column;
        align-items: ${center ? "center" : align};
        justify-content: ${center ? "center" : justify};
        text-align: ${center ? "center" : justify};
      `
    : null}
`

const ComponentCol = ({ center, align, justify, ...props }) => {
  return (
    <Col
      css={(theme) =>
        stylingCol({
          theme,
          center,
          align,
          justify,
        })
      }
      className="component-flex--col"
      {...props}
    />
  )
}

export default { Row: ComponentRow, Col: ComponentCol }
