// import React from "react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"

const generateSWRCacheKey = ({
  clinicId,
  corporateClinicGroupId,
  tableKey,
}) => {
  if (!tableKey) return null

  if (clinicId)
    return () => `/table_view_config?clinic_id=eq.${clinicId}&table_key=eq.${tableKey}&select=
table_view_config_id,
clinic_id,
table_key,
locked,
name,
config,
created_at,
updated_at
`

  if (corporateClinicGroupId)
    return () => `/table_view_config?clinic_id=eq.${corporateClinicGroupId}&table_key=eq.${tableKey}&select=
table_view_config_id,
clinic_id,
table_key,
locked,
name,
config,
created_at,
updated_at
`

  return null
}

const update = async (orca, tableViewConfigId, values) => {
  const {
    data: [data],
  } = await orca.patch(
    `/table_view_config?table_view_config_id=eq.${tableViewConfigId}`,
    values,
    {
      headers: {
        Prefer: "return=representation",
      },
    }
  )

  return data
}

const create = async (orca, values) => {
  const {
    data: [data],
  } = await orca.post(`/table_view_config`, values, {
    headers: {
      Prefer: "return=representation",
    },
  })

  return data
}

const remove = async (orca, tableViewConfigId) => {
  await orca.delete(
    `/table_view_config?table_view_config_id=eq.${tableViewConfigId}`
  )
}

const useTeam = ({ clinicId, corporateClinicGroupId, tableKey }) => {
  const orca = useOrcaAxios()

  const fetcher = async (url) => {
    const { data } = await orca.get(url)
    return data
  }

  const { data, error, mutate, isValidating } = useSWR(
    generateSWRCacheKey({ clinicId, corporateClinicGroupId, tableKey }),
    fetcher
  )

  const updateAPI = (tableViewConfigId, values) =>
    update(orca, tableViewConfigId, values)
  const createAPI = (values) =>
    create(
      orca,
      clinicId
        ? { ...values, clinic_id: clinicId }
        : { ...values, corporate_clinic_group_id: corporateClinicGroupId }
    )
  const removeAPI = (tableViewConfigId) => remove(orca, tableViewConfigId)

  return {
    data,
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    update: updateAPI,
    create: createAPI,
    remove: removeAPI,
  }
}

export { generateSWRCacheKey, update, create, remove }

export default useTeam
