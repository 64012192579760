import React from "react"

// token
import { useToken } from "store/token"

// cube
import cubejs from "@cubejs-client/core"
import { useCubeQuery, QueryRenderer } from "@cubejs-client/react"

// components
import { Loader } from "components"

// logs
// import debugModule from "utils/debug"
// const debug = debugModule("components-cube")

const Cube = ({ render, loading = false, ...props }) => {
  const { cubeToken } = useToken()

  const cubejsApi = cubejs({
    apiUrl: process.env.REACT_APP__ORCA_CUBE_API__HOST,
    headers: { Authorization: `${cubeToken}` },
  })

  return (
    <QueryRenderer
      {...props}
      cubejsApi={cubejsApi}
      render={({ resultSet }) => {
        if (!resultSet || loading) return <Loader top={false} />
        return render({ resultSet })
      }}
    />
  )
}

Cube.useQuery = (query, options) => {
  const { cubeToken } = useToken()

  const cubejsApi = cubejs({
    apiUrl: process.env.REACT_APP__ORCA_CUBE_API__HOST,
    headers: { Authorization: `${cubeToken}` },
  })

  const data = useCubeQuery(query, {
    cubejsApi,
    ...options,
  })

  return data
}

export default Cube
