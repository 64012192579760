import React from "react"

// translation
import { useTranslation } from "react-i18next"

// hooks
import { useFiscalYearPeriod } from "store/fiscal-year"

// components
import { ArrowSwitcher, Modal } from "components"

const Component = ({
  allowPast = false,
  allowFuture = false,
  disabled,
  status,
  loading,
  onChange = () => null,
}) => {
  const period = useFiscalYearPeriod()
  const { t, i18n } = useTranslation()

  return (
    <ArrowSwitcher
      loading={period.isLoading}
      label={
        period.isReady
          ? `${period.currentPeriod.start.toLocaleDateString(i18n.language, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })} - ${period.currentPeriod.end.toLocaleDateString(i18n.language, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}`
          : "-"
      }
      prevDisabled={!allowPast && (!period.canSelectPrevYear || disabled)}
      nextDisabled={!allowFuture && (!period.canSelectNextYear || disabled)}
      onPrev={() => {
        if (status === "edited") {
          Modal.confirm({
            title: t("validation:message.types.confirmation.label"),
            content: t(
              "validation:message.types.confirmation.__.continue-without-save.label"
            ),
            onOk() {
              period.selectPreviousYear()
              onChange()
            },
            onCancel() {
              return
            },
          })
        } else {
          period.selectPreviousYear()
          onChange()
        }
      }}
      onNext={() => {
        if (status === "edited") {
          Modal.confirm({
            title: t("validation:message.types.confirmation.label"),
            content: t(
              "validation:message.types.confirmation.__.continue-without-save.label"
            ),
            onOk() {
              period.selectNextYear()
              onChange()
            },
            onCancel() {
              return
            },
          })
        } else {
          period.selectNextYear()
          onChange()
        }
      }}
    />
  )
}
export default Component
