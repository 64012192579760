import React from "react"

// styles
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"

// components
import { Flex, Space, Button, Typo, Checkbox } from "components"
import { Empty } from "antd"

// utils
import { get, uniqBy } from "lodash"
import renderColTypeIcon from "../utils/renderColTypeIcon"
import getRowKey from "../utils/getRowKey"

// assets
import { ReactComponent as IconCheckmark } from "assets/icons/icon-checkmark2.svg"
import { ReactComponent as IconCancel } from "assets/icons/icon-cancel.svg"

const Header = styled.div`
  label: component-data-table--header;

  padding: 12px 0;

  border-bottom: ${({ theme, selectable }) =>
    selectable ? null : `1px solid ${theme.borderColorLight}`};
`

const Component = styled.div`
  label: component-data-table--table;

  // background-color: ${({ theme }) => theme.backgroundColorLight};
  border: 1px solid ${({ theme }) => theme.borderColorLight};
  border-radius: 12px;
`

const Body = styled.div`
  label: component-data-table--body;

  max-height: 500px;
  resize: vertical;
  overflow-y: auto;
`

const EditorRow = styled.div`
  label: component-data-table--editor-row;

  display: grid;
  grid-template-columns: ${({ template }) => `40px ${template}`};

  opacity: ${({ visible }) => (visible ? "1" : "0")};
  will-change: opacity;
  transition: opacity 1000ms;

  > * {
    border-right: none !important;
  }
`

const EditorRowContainer = styled.div`
  label: component-data-table--editor-row-container;

  background-color: white;
  margin: ${({ visible }) => (visible ? "0 -12px " : "0")};
  border: 1px solid ${({ theme }) => theme.borderColorLight};
  border-radius: ${({ visible }) => (visible ? "4px" : "0px")};

  height: ${({ visible }) => (visible ? "auto" : "4px")};
  padding: ${({ visible }) => (visible ? "8px 12px 8px 4px" : "0")};

  will-change: margin, border, height, border-radius;
  transition: height 1000ms, border 1000ms, margin 1000ms, border-radius 1000ms;

  box-shadow: 4px 3px 13px 1px rgb(200, 200, 200, 0.1);
`

const Row = styled.div`
  label: component-data-table--row;
  display: grid;
  grid-template-columns: ${({ template }) => template};

  background-color: ${({ selected }) =>
    selected ? "rgba(230,230,255,0.1)" : "transparent"};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.borderColorLight};
  }
`

const Cell = styled.div`
  label: component-data-table--cell;

  display: flex;
  flex-direction: column;
  justify-content: ${({ verticalAlign = "top" }) =>
    verticalAlign === "top"
      ? "flex-start"
      : verticalAlign === "bottom"
      ? "flex-end"
      : verticalAlign};
  align-items: ${({ align = "left" }) =>
    align === "left" ? "flex-start" : align === "right" ? "flex-end" : align};

  padding: ${({ header }) => (header ? "0px 8px" : "12px 8px")};
  padding-left: ${({ selector }) => (selector ? "16px" : null)};

  &:not(:last-of-type) {
    border-right: 1px solid
      ${({ selector, theme }) =>
        !selector ? theme.borderColorLight : "transparent"};
  }

  border-bottom-left-radius: ${({ isLeftBottom }) =>
    isLeftBottom ? "16px" : "0px"};
  border-bottom-right-radius: ${({ isRightBottom }) =>
    isRightBottom ? "16px" : "0px"};

  border-top-left-radius: ${({ isLeftTop }) => (isLeftTop ? "16px" : "0px")};
  border-top-right-radius: ${({ isRightTop }) => (isRightTop ? "16px" : "0px")};
`

const Table = ({
  schema,
  pagination,
  rowKey = "key",
  selectable = false,
  searchable = false,
  checkboxGap = "36px",

  selection = [],
  data = [],
  onRemoveSelection = () => null,
  onSelectRecord = () => null,
  onRemoveRecord = () => null,
  onApplySelection = () => null,
}) => {
  const { t } = useTranslate(["ui", "common"])

  const showEditor = selection.length > 0

  const template = schema
    .map((col) =>
      typeof col.width === "number" ? `${col.width}px` : col.width
    )
    .join(" ")

  const selectedRecords = data.filter((record) =>
    selection.includes(getRowKey(rowKey, record))
  )

  return (
    <Component data-testid="datatable-table">
      <Header selectable={selectable}>
        <Row
          selectable={selectable}
          template={selectable ? `${checkboxGap} ${template}` : template}
        >
          {selectable && <Cell selector header />}
          {schema.map((col, colIndex) => (
            <Cell
              header
              key={col.key}
              isLeftTop={colIndex === 0}
              isRightTop={colIndex + 1 === schema.length}
            >
              <Flex.Row gutter={8} align="end">
                <Flex.Col style={{ paddingBottom: 1 }}>
                  {renderColTypeIcon(col.type)}
                </Flex.Col>
                <Flex.Col>
                  <Typo.Text
                    font="bold600"
                    color="textColorDefault"
                    size="tall"
                  >
                    {col.title}
                  </Typo.Text>
                </Flex.Col>
              </Flex.Row>
            </Cell>
          ))}
        </Row>
      </Header>
      {selectable && (
        <EditorRowContainer visible={showEditor}>
          {showEditor && (
            <EditorRow
              data-testid="datatable-editor-row"
              visible={showEditor}
              template={template}
            >
              <Cell verticalAlign="center">
                <Space direction="vertical" size="small">
                  <Button
                    round={false}
                    onClick={onApplySelection}
                    type="secondary"
                    size="small"
                    icon={<IconCheckmark width="12px" />}
                    // label="Apply"
                  />
                  <Button
                    round={false}
                    type="secondary"
                    danger
                    grey
                    onClick={onRemoveSelection}
                    icon={<IconCancel width="12px" height="8px" />}
                    // label="Cancel"
                    size="small"
                  />
                </Space>
              </Cell>
              {schema.map((col, colIndex) => {
                const limit = col.max || 1
                const records = col.unique
                  ? uniqBy(
                      [...selectedRecords],
                      typeof col.unique === "function" ? col.unique : col.key
                    )
                  : [...selectedRecords]

                const maximalRecods = records.slice(0, limit)

                return (
                  <Cell key={col.key} align={col.align} verticalAlign="center">
                    {col.renderSelection ? (
                      col.renderSelection(maximalRecods)
                    ) : (
                      <React.Fragment>
                        <Space wrap style={{ display: "flex" }}>
                          {maximalRecods.map((record, index, arr) => (
                            <span key={getRowKey(rowKey, record)}>
                              {col.render(get(record, col.key), record)}
                              {index + 1 !== arr.length ? ", " : ""}
                            </span>
                          ))}
                        </Space>
                        {records.length > limit && (
                          <Typo.Text
                            color="textColorLighter"
                            size="smaller"
                            italic
                          >
                            ... +
                            {t("common:other.label", {
                              count: records.length - limit,
                            }).toLowerCase()}
                          </Typo.Text>
                        )}
                      </React.Fragment>
                    )}
                  </Cell>
                )
              })}
            </EditorRow>
          )}
        </EditorRowContainer>
      )}
      <Body>
        {data.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {(pagination !== null
          ? data.slice(
              (pagination.page - 1) * pagination.pageSize,
              pagination.page * pagination.pageSize
            )
          : data
        ).map((record, dataIndex) => (
          <Row
            data-testid="datatable-row"
            data-datatable-row={getRowKey(rowKey, record)}
            key={getRowKey(rowKey, record)}
            template={selectable ? `${checkboxGap} ${template}` : template}
            selected={selection.includes(getRowKey(rowKey, record))}
            selectable={selectable}
          >
            {selectable && (
              <Cell selector data-testid="datatable-cell-select">
                <Checkbox
                  checked={selection.includes(getRowKey(rowKey, record))}
                  onChange={(e) => {
                    return e.target.checked
                      ? onSelectRecord(getRowKey(rowKey, record))
                      : onRemoveRecord(getRowKey(rowKey, record))
                  }}
                />
              </Cell>
            )}
            {schema.map((col, colIndex) => (
              <Cell
                data-testid="datatable-cell"
                data-datatable-cell={col.key}
                key={col.key}
                align={col.align}
                verticalAlign={col.verticalAlign}
                isLeftBottom={dataIndex + 1 === data.length && colIndex === 0}
                isRightBottom={
                  dataIndex + 1 === data.length &&
                  colIndex + 1 === schema.length
                }
              >
                {col.render(get(record, col.key), record)}
              </Cell>
            ))}
          </Row>
        ))}
      </Body>
    </Component>
  )
}

export default Table
