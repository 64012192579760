// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-background;
  background-color: ${({ theme }) => theme.backgroundColorExtraLighter};

  padding: 8px;
  margin: -8px;
  margin-top: ${({ marginTop, theme }) =>
    theme.utils.unit(marginTop, "12px", "px")};
  margin-bottom: 12px;
  border-radius: 4px;
`

export default Component
