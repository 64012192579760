import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Carousel as Component } from "antd"

const styling = (theme) => css`
  label: component-carousel--styling;

  .slick-slide {
    line-height: 160px;
    overflow: hidden;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
  }

  .slick-list {
    z-index: 10 !important;
  }

  .slick-dots {
    height: 10px;
    z-index: 21;
  }

  .ant-carousel .slick-slide h3 {
    color: ${theme.themeColorDefault};
  }

  .ant-carousel .slick-dots li button {
    background-color: ${theme.themeColorDefault};
  }
`

const Carousel = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Component css={styling} className="component-carousel" {...rest} ref={ref}>
      {children}
    </Component>
  )
})

Carousel.next = Component.next

export default Carousel
