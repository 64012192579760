import React from "react"

// styles
import { css } from "@emotion/core"
import { Global } from "@emotion/core"

// componeny
import { AutoComplete as Component } from "antd"

const styling = (theme) => css`
  label: component-autocomplete--styling;

  background-color: ${theme.backgroundColorExtraLight};
  border-radius: 5px;

  ${theme.font.typo.default};
  font-size: ${theme.font.size.default};
  color: ${theme.textColorDefault};

  .ant-select-selector {
    border: none !important;
    background-color: transparent !important;
  }
`

const stylingSelectDropdown = (theme) => css`
  .component-autocomplete-dropdown {
    padding: 0;
    animation-duration: 0.1s !important;

    .ant-select-item {
      ${theme.font.typo.default};
      font-size: ${theme.font.size.default};
      color: ${theme.textColorDefault};
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${theme.markerColorGreenLight};
    }

    &.borderless {
    }

    &.min-width {
      width: auto !important;
    }
  }
`

const Autocomplete = ({ children, ...rest }) => {
  return (
    <div>
      <Global styles={stylingSelectDropdown} />
      <Component
        css={styling}
        className="component-autocomplete"
        dropdownClassName="component-autocomplete-dropdown"
        {...rest}
      ></Component>
    </div>
  )
}

export default Autocomplete
