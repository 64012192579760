// import React from "react"

// translation
import { useTranslation, Trans } from "react-i18next"

function useTranslate(namespace) {
  const { t, i18n } = useTranslation(namespace)

  const translateList = (list, key = "misc", config) =>
    list.map((type) => ({
      ...type,
      id: type.id,
      value: type.value,
      label: t(`data:${key}.types.${type.value.replace(/_/g, "-")}.label`, {
        context: config && config.version,
      }),
    }))

  return { t, i18n, translateList, Trans }
}

export default useTranslate
