import React from "react"

// styles
import styled from "@emotion/styled"
import { css, Global } from "@emotion/core"

import { Popover as Component } from "antd"

const styling = (theme) => css`
  .ant-popover {
    z-index: 1050;

    .ant-popover-title,
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-content,
    .ant-popover-inner,
    .ant-popover-inner-content {
      box-shadow: none;
      background: none;
      padding: 0;
    }
  }
`

const PopContainer = styled.div`
  label: component-popover;
  box-shadow: 0px 8px 12px rgba(121, 108, 147, 0.1);
  border: 1px solid ${({ theme }) => theme.borderColorLight};
  overflow: hidden;
  min-width: 300px;
  border-radius: 5px;
  z-index: 1000000;
`

const Popover = ({ children, content, addPadding, ...rest }) => {
  return (
    <Component
      content={<PopContainer>{content}</PopContainer>}
      overlayClassName="component-popover-overlay"
      overlayStyle={{ padding: addPadding ? "1rem" : "" }}
      {...rest}
    >
      <Global styles={styling} />
      {children}
    </Component>
  )
}

export default Popover
