import React from "react"

// utils
import { is_number } from "utils/functions"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// assets
import Caret from "../assets/arrow-caret"

const GrowthSpan = styled.span`
  label: component-utils-fn-growth-span;
  display: inline-flex;
  align-items: center;
`

const GrowthPercentage = styled.span`
  margin-right: 4px;

  color: ${({ growth }) =>
    growth > 0 ? "#00C991" : growth < 0 ? "#FF5E69" : "inherit"};
`

const InitialValue = styled.span`
  margin-right: 4px;
`

const GrowthValue = styled.span`
  margin-right: 4px;
`

const Arrow = ({ growth, invertColorScheme }) => {
  if (growth === 0 || growth === undefined || growth === null) return null

  const getCaretColor = (growth) => {
    if (growth > 0) {
      return "#00C991"
    } else if (growth < 0) {
      return "#FF5E69"
    }
  }

  return (
    <Caret
      css={css`
        margin: 0 4px;
        width: 4px;
        height: 4px;
      `}
      color={getCaretColor(invertColorScheme ? -growth : growth)}
      rotation={growth > 0 ? 0 : 180}
    />
  )
}

const GrowthLabel = ({
  current,
  previous,
  formatter = (v) => v,
  showInitialValue = true,
  showGrowthValue = false,
  invertColorScheme,
}) => {
  if (
    !is_number(previous) ||
    !is_number(current) ||
    (previous === 0 && current === 0)
  )
    return (
      <GrowthSpan>
        {showInitialValue && is_number(current) ? (
          <InitialValue>{formatter(current)}</InitialValue>
        ) : (
          "–"
        )}
      </GrowthSpan>
    )

  const growth = Math.round((current - previous) * 100) / 100
  const percent = previous === 0 ? 100 : Math.round((growth / previous) * 100)

  const sign = growth >= 0 ? "+" : ""

  return (
    <GrowthSpan>
      {showInitialValue && (
        <InitialValue growth={growth}>{formatter(current)}</InitialValue>
      )}
      {showGrowthValue && (
        <GrowthValue growth={growth}>{`${sign}${formatter(
          growth
        )}`}</GrowthValue>
      )}

      <Arrow growth={growth} invertColorScheme={invertColorScheme} />

      <GrowthPercentage
        growth={growth}
        invertColorScheme={invertColorScheme}
      >{`${Math.abs(percent)}%`}</GrowthPercentage>
    </GrowthSpan>
  )
}

export default GrowthLabel
