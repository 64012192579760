// packages
import axios from "axios"

// auth0
import { useToken } from "store/token"

// utils
import message from "utils/message"

// logs
import debugModule from "utils/debug"
const debug = debugModule("useOrcaAxios")

const instance = axios.create({
  baseURL: process.env.REACT_APP__ORCA_PGRST_API__HOST,
})

const addTokenToRequest = async (config) => {
  try {
    debug.log(
      `[${config.method}] ${
        config.baseURL.split("//")[1] + config.url.split("?")[0]
      }`
    )

    return config
  } catch (err) {
    debug.error(err)
    throw err
  }
}

instance.interceptors.request.use(addTokenToRequest, (err) =>
  Promise.reject(err)
)

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    debug.error(err)
    message.error(err?.message || "Error! Please contact VotreSuccès.com!", 60)
    return Promise.reject(err)
  }
)

function useOrcaAxios() {
  const { databaseToken } = useToken()

  instance.defaults.headers.common["Authorization"] = `Bearer ${databaseToken}`

  return instance
}

export default useOrcaAxios
