// token
import { useToken } from "store/token"

// cube
import cubejs from "@cubejs-client/core"
import { useCubeQuery } from "@cubejs-client/react"

const useCube = (query, options) => {
  const { cubeToken } = useToken()

  const cubejsApi = cubejs({
    apiUrl: process.env.REACT_APP__ORCA_CUBE_API__HOST,
    headers: { Authorization: `${cubeToken}` },
  })

  const data = useCubeQuery(query, {
    cubejsApi,
    ...options,
  })

  return data
}

export default useCube
