export const formatNumberToTime = (value, timeFormat) => {
  if (typeof value !== "number")
    throw new Error(`The value "${value}" is not a number!`)

  if (timeFormat === ":") {
    const timeValue = Number(value)
    const hours = Math.floor(timeValue / 60)
    const minutes = timeValue % 60
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`
  } else {
    const timeValue = Number(value)
    const hours = Math.floor(timeValue / 60)
    const minutes = Math.floor(((timeValue % 60) * 100) / 60)
    return `${hours}.${minutes < 10 ? "0" + minutes : minutes}`
  }
}
