import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// components
import { Flex, Typo } from "components"

// assets
import { ReactComponent as Arrow } from "assets/arrows/arrow-oval.svg"

const ArrowSwitcher = styled.div`
  label: component-arrow-switcher;
  background: ${({ theme }) => theme.backgroundColorDefault};

  cursor: default;
`

const ArrowWrapper = styled.div`
  label: component-arrow-switcher--arrow;

  display: flex;
  align-items: center;

  svg {
    transition: transform 300ms;
    cursor: pointer;

    ${(props) =>
      props.disabled
        ? css`
            opacity: 0.25;
            transform: ${props.right ? "rotate(180deg)" : "rotate(0deg)"};
          `
        : css`
            opacity: 0.75;
            transform: ${props.right ? "rotate(180deg)" : "rotate(0deg)"}
              scale(1);

            &:hover {
              opacity: 1;
              transform: ${props.right ? "rotate(180deg)" : "rotate(0deg)"}
                scale(1.1);
            }
          `}
  }
`

const ArrayButton = (props) => {
  return (
    <ArrowWrapper
      disabled={props.disabled}
      right={props.right}
      onClick={() => (props.disabled ? null : props.onClick())}
    >
      <Arrow />
    </ArrowWrapper>
  )
}

const Component = ({
  label,
  loading,
  nextDisabled,
  prevDisabled,
  onPrev = () => null,
  onNext = () => null,
}) => {
  if (loading) return null

  return (
    <ArrowSwitcher>
      <Flex.Row align="middle" gutter={8}>
        <Flex.Col>
          <ArrayButton
            disabled={prevDisabled}
            onClick={() => {
              onPrev()
            }}
          />
        </Flex.Col>

        <Flex.Col>
          <Typo.Text size="taller" font="bold700">
            {label}
          </Typo.Text>
        </Flex.Col>

        <Flex.Col>
          <ArrayButton
            disabled={nextDisabled}
            right
            onClick={() => {
              onNext()
            }}
          />
        </Flex.Col>
      </Flex.Row>
    </ArrowSwitcher>
  )
}
export default Component
