/**
 * @see https://kentcdodds.com/blog/authentication-in-react-applications
 * @see https://auth0.com/docs/libraries/auth0-spa-js
 */
import React from "react"
import isFunction from "lodash/isFunction"

const AuthContext = React.createContext()

function createCallback(...callbacks) {
  return (...args) => {
    for (const callback of callbacks) {
      if (isFunction(callback)) {
        callback(...args)
      }
    }
  }
}

function AuthProvider({
  authProviderLibraryInitializerFunction,
  authProviderLibraryOptions,
  authProviderLibraryCallbacks,
  paths,
  children,
}) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [idTokenClaims, setIdTokenClaims] = React.useState(null)
  const [authProviderClient, setAuthProviderClient] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const {
      onAuthProviderClientInitialized,
      onAuthenticationStateChange,
      onLoadingStateChange,
      onUserStateChange,
      onIdTokenClaimsChange,
      onRedirect,
    } = authProviderLibraryCallbacks

    const callbacks = {
      onAuthProviderClientInitialized: createCallback(
        setAuthProviderClient,
        onAuthProviderClientInitialized
      ),
      onAuthenticationStateChange: createCallback(
        setIsAuthenticated,
        onAuthenticationStateChange
      ),
      onLoadingStateChange: createCallback(setLoading, onLoadingStateChange),
      onUserStateChange: createCallback(setUser, onUserStateChange),
      onIdTokenClaimsChange: createCallback(
        setIdTokenClaims,
        onIdTokenClaimsChange
      ),
      onRedirect: onRedirect,
    }

    authProviderLibraryInitializerFunction(
      authProviderLibraryOptions,
      callbacks
    )
  }, [
    authProviderLibraryInitializerFunction,
    authProviderLibraryOptions,
    authProviderLibraryCallbacks,
  ])

  // note from @kentcdodds:
  // I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  const context = {
    loading,
    isAuthenticated,
    user,
    idTokenClaims,
    paths,
    ...(authProviderClient || {}),
  }
  return <AuthContext.Provider value={context} children={children} />
}

const useAuth = () => React.useContext(AuthContext)

const useUser = () => {
  const { user, idTokenClaims } = useAuth()
  return {
    user,
    idTokenClaims,
  }
}

const useLogin = () => {
  const auth = useAuth()
  const { isAuthenticated, login } = auth
  return {
    isAuthenticated,
    login,
    onLogin: () => {
      auth.login({
        appState: { targetUrl: "/" },
      })
    },
  }
}

const useSignup = () => {
  const auth = useAuth()
  const { isAuthenticated, signup } = auth
  return {
    isAuthenticated,
    signup,
    onSignup: () => {
      auth.signup({
        appState: { targetUrl: "/" },
      })
    },
  }
}

const useLogout = () => {
  const auth = useAuth()
  const { isAuthenticated, logout } = auth
  return {
    isAuthenticated,
    logout,
    onLogout: (e) => {
      e.preventDefault()
      auth.logout()
    },
  }
}

const useVerifyEmail = () => {
  return {}
}

const useUnauthenticatedPaths = () => {
  const { user, paths } = useAuth()
  return {
    ...paths,
    redirectTo:
      user && user.email_verified === false ? paths.verifyEmail : paths.login,
  }
}

const useAuthenticatedPaths = () => {
  const { paths } = useAuth()
  return { ...paths }
}

const useSwitchClinic = () => {
  const { switchClinic, idTokenClaims } = useAuth()
  return { switchClinic, idTokenClaims }
}

const useCreateClinic = () => {
  const { createClinic } = useAuth()
  return { createClinic }
}

export {
  AuthProvider,
  useAuth,
  useUser,
  useLogin,
  useSignup,
  useLogout,
  useVerifyEmail,
  useUnauthenticatedPaths,
  useAuthenticatedPaths,
  useSwitchClinic,
  useCreateClinic,
}
