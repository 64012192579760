import React from "react"

// Sentry
import * as Sentry from "@sentry/react"

// store
import useWebuserClinicAPI from "store/api/webuser-clinic"
import useWebuserCcgAPI from "store/api/webuser-ccg"

const WebuserCorporateClinicGroupContext = React.createContext()

function useWebuserCorporateClinicGroup() {
  const context = React.useContext(WebuserCorporateClinicGroupContext)
  if (!context) {
    throw new Error(
      `useWebuserCorporateClinicGroup must be used within a WebuserCorporateClinicGroupProvider`
    )
  }

  const [corporateClinicGroup, setCorporateClinicGroup] =
    context.corporateClinicGroup

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === "development" || !corporateClinicGroup)
      return

    Sentry.setContext("tenant", {
      tenant_type: "ccg",
      tenant_id: corporateClinicGroup.corporate_clinic_group_id,
    })
  }, [corporateClinicGroup])

  const webuserClinics = useWebuserClinicAPI()
  const webuserCcgs = useWebuserCcgAPI()

  const getClinicsOfCurrentCCG = React.useCallback(() => {
    if (corporateClinicGroup) {
      const corporateClinicGroupId =
        corporateClinicGroup.corporate_clinic_group_id
      const list = webuserClinics.data.filter(
        (c) => c.clinic.corporate_clinic_group_id === corporateClinicGroupId
      )

      return list
    } else {
      throw new Error({
        name: "Bad Request",
        message: "current corporateClinicGroup must be set!",
        toString: function () {
          return this.name + ": " + this.message
        },
      })
    }
  }, [corporateClinicGroup, webuserClinics.data])

  const getClinic = React.useCallback(
    (clinicId) => {
      return webuserClinics.data.find((c) => c.clinic_id === clinicId)
    },
    [webuserClinics.data]
  )

  const clinicList = React.useMemo(() => {
    if (!corporateClinicGroup) return []

    return webuserClinics.data.filter(
      (c) =>
        c.clinic.corporate_clinic_group_id ===
        corporateClinicGroup.corporate_clinic_group_id
    )
  }, [corporateClinicGroup, webuserClinics.data])

  return {
    getClinicsOfCurrentCCG,
    corporateClinicGroup,
    setCorporateClinicGroup,
    corporateClinicGroups: webuserCcgs.data ?? [],
    store: webuserCcgs,
    clinicList,
    getClinic,
  }
}

function WebuserCorporateClinicGroupProvider(props) {
  const [corporateClinicGroup, setCorporateClinicGroup] = React.useState()

  const corporate_clinic_group_value = React.useMemo(
    () => [corporateClinicGroup, setCorporateClinicGroup],
    [corporateClinicGroup]
  )

  return (
    <WebuserCorporateClinicGroupContext.Provider
      value={{
        corporateClinicGroup: corporate_clinic_group_value,
      }}
      {...props}
    />
  )
}

export { WebuserCorporateClinicGroupProvider, useWebuserCorporateClinicGroup }

export default useWebuserCorporateClinicGroup
