export const pms_types = [
  {
    id: 1,
    value: "dentitek",
    label: "Dentitek",
  },
  {
    id: 2,
    value: "progident",
    label: "Progident",
  },
]

export const arthur_representative_types = [
  { id: 0, value: "gregory-benko-prieur", label: "Gregory Benko-Prieur" },
  { id: 1, value: "magali-loiselle", label: "Magali Loiselle" },
  { id: 2, value: "marie-france-fournier", label: "Marie-France Fournier" },
  { id: 3, value: "joel-labelle", label: "Joël Labelle" },
]

export const domain_employee_workday_status_types = [
  {
    id: "worked",
    value: "worked",
    color: "#FFFFFF",
    borderColor: "#D9DEEB",
    input: true,
  },
  {
    id: "future",
    value: "future",
    color: "#F5F7FB",
    borderColor: "#D9DEEB",
    input: false,
  },
  {
    id: "sick-leave",
    value: "sick-leave",
    color: "#FDD8D8",
    borderColor: "#FDB4B4",
    input: true,
  },
  {
    id: "holiday",
    value: "holiday",
    color: "#FDF6D9",
    borderColor: "#FFE88C",
    input: true,
  },
  {
    id: "vacation",
    value: "vacation",
    color: "#E0DCFF",
    borderColor: "#BAB0FF",
    input: true,
  },
  {
    id: "training",
    value: "training",
    color: "#D2F6E6",
    borderColor: "#48DE9E",
    input: true,
  },
  {
    id: "weekend",
    value: "weekend",
    color: "#EBEFF7",
    borderColor: "#B9C2DA",
    input: true,
  },
  {
    id: "other",
    value: "other",
    color: "#D8F7FF",
    borderColor: "#7FE4FF",
    input: true,
  },
]

export const gender_types = [
  { id: 1, value: "male" },
  { id: 2, value: "female" },
  { id: 3, value: "other" },
]

export const domain_marketing_role_types = [
  { id: 1, value: "executive" },
  { id: 2, value: "dentist_owner" },
  { id: 3, value: "owner" },
  { id: 4, value: "manager" },
  { id: 5, value: "dentist_percentage" },
  { id: 6, value: "hygienist" },
  { id: 7, value: "assistant" },
  { id: 8, value: "secretary" },
  { id: 9, value: "consultant" },
  { id: 10, value: "evaluator" },
  { id: 11, value: "other" },
]

export const name_title_types = [
  { id: 1, value: "dr_male" },
  { id: 2, value: "dr_female" },
  { id: 3, value: "dr_other" },
  { id: 4, value: "m_male" },
  { id: 5, value: "m_female" },
  { id: 6, value: "m_other" },
]

export const language_types = [
  { id: 1, value: "en" },
  { id: 2, value: "fr" },
]

export const month_types = [
  { id: 0, value: "all" },
  { id: 1, value: "january" },
  { id: 2, value: "february" },
  { id: 3, value: "march" },
  { id: 4, value: "april" },
  { id: 5, value: "may" },
  { id: 6, value: "june" },
  { id: 7, value: "july" },
  { id: 8, value: "august" },
  { id: 9, value: "sepember" },
  { id: 10, value: "october" },
  { id: 11, value: "november" },
  { id: 12, value: "december" },
]

export const province_types = [
  { id: 1, value: "quebec" },
  { id: 2, value: "ontario" },
  { id: 3, value: "new_brunswick" },
  { id: 4, value: "nova_scotia" },
  { id: 5, value: "prince_edward_island" },
  { id: 6, value: "manitoba" },
  { id: 7, value: "saskatchewan" },
  { id: 8, value: "alberta" },
  { id: 9, value: "british_columbia" },
  { id: 10, value: "newfoundland_and_labrador" },
  { id: 11, value: "northwest_territories" },
  { id: 12, value: "nunavut" },
  { id: 13, value: "yukon" },
]

export const domain_operator_title_types = [
  {
    id: 7,
    value: "hygienist",
    highligthed: true,
    order: 1,
    department: "hygiene",
  },
  {
    id: 13,
    value: "assistant",
    highligthed: true,
    order: 2,
    department: "other",
  },
  {
    id: 4,
    value: "dentist",
    highligthed: true,
    order: 3,
    department: "restorative",
  },
  {
    id: 5,
    value: "dentist_owner",
    highligthed: true,
    order: 4,
    department: "restorative",
  },
  {
    id: 6,
    value: "dentist_percentage",
    highligthed: true,
    order: 5,
    department: "restorative",
  },
  // Github note: https://github.com/arthurintelligence/arthur-data-pipeline/issues/374
  // all the rest can be in alphabetical order. Can you have a horizontal divider between the first sort and the second?
  // another thing that would be optimal is to have the top 5 above with a title for the group "Shortcuts"
  // and the second group being "Alphabetical Order"
  // if you add the separator, up to you if you want to show the shortcut group in the alphabetical order section as well.
  { id: 1, value: "owner", department: "administration" },
  { id: 2, value: "manager", department: "administration" },
  { id: 3, value: "coordinator", department: "consultation" },
  { id: 9, value: "denturologist", department: "denturology" },
  { id: 8, value: "hygienist_assistant", department: "hygiene" },
  { id: 10, value: "orthodontist", department: "orthodontics" },
  { id: 11, value: "secretary", department: "staff" },
  { id: 12, value: "laboratory", department: "staff" },
  { id: 14, value: "operating_room", department: "material" },
  { id: 15, value: "material_resource", department: "material" },
  { id: 16, value: "uncategorized_account", department: "staff" },
  { id: 17, value: "other", department: "staff" },
  { id: 18, value: "sterilization_attendant", department: "other" },
]

export const domain_operator_source_types = [
  { id: 1, value: "schedule_track" },
  // { id: 2, value: "employee_time_slot" },
  { id: 3, value: "timesheet_template" },
  { id: 4, value: "dentitek_biopunch" },
]

export const report_frequence_types = [
  { id: 1, value: "daily" },
  { id: 2, value: "weekly" },
  { id: 3, value: "biweekly" },
  { id: 4, value: "monthly" },
]

export const department_types = [
  { id: "restorative", value: "restorative", color: "#E0DCFF" },
  { id: "hygiene", value: "hygiene", color: "#D8F7FF" },
  { id: "denturology", value: "denturology", color: "#38D5FF" },
  { id: "orthodontics", value: "orthodontics", color: "#59A8FF" },
  { id: "administration", value: "administration", color: "#FFD55F" },
  { id: "consultation", value: "consultation", color: "#94E3D7" },
  { id: "staff", value: "staff", color: "#FFE88C" },
  { id: "material", value: "material", color: "#A598FF" },
  { id: "other", value: "other", color: "#c6c6c6" },
]

export const webuser_member_types = [
  { id: 1, value: "administrator" },
  { id: 2, value: "user" },
]

export const status_types = [
  { id: 1, value: "active" },
  { id: 2, value: "inactive" },
]

export const clinic_referrer_types = [
  { id: 1, value: "dentitek" },
  { id: 2, value: "progident" },
  { id: 3, value: "arthur-rep" },
  { id: 4, value: "idi" },
  { id: 6, value: "facebook-dentist-group" },
  { id: 7, value: "facebook-page-arthur" },
  { id: 8, value: "friend-or-family" },
  { id: 9, value: "other" },
]

export const boolean_types = [
  { id: 1, value: "yes" },
  { id: 2, value: "no" },
]

export const comment_minimum_duration_types = (() => {
  const result = []

  for (var i = 0; i < 25; i++) {
    result.push({ id: i, label: `${i * 5} min`, value: i * 5 })
  }

  return result
})()

export const domain_schedule_track_mode_types = [
  { id: 1, value: "normal" },
  { id: 2, value: "auto-close-template" },
  { id: 3, value: "ignore" },
]

export const clinic_permission_scope_types = [
  {
    color: "#AED7FF",
    borderColor: "#59A8FF",
    type: "clinic",
    id: "act4132f4955f3543f9bad601676d4",
    value: "act4132f4955f3543f9bad601676d4",
    label: "clinic-settings:read-write",
  },
  {
    color: "#A6EDCF",
    borderColor: "#5FCEBD",
    type: "clinic",
    id: "act80b58eb1853444e794e5cb78c0f",
    value: "act80b58eb1853444e794e5cb78c0f",
    label: "clinic-objectives:read-write",
  },
  {
    color: "#FFF3B8",
    borderColor: "#FFBF00",
    type: "clinic",
    id: "actbc79ca1d6c6543ac8abd9cc1f64",
    value: "actbc79ca1d6c6543ac8abd9cc1f64",
    label: "clinic-marketing:read-write",
  },
  {
    color: "#B8F0FF",
    borderColor: "#52D6FA",
    type: "clinic",
    id: "act8fee4543a86c4bd294917edbcbc",
    value: "act8fee4543a86c4bd294917edbcbc",
    label: "clinic-drive:read",
  },
  {
    color: "#B8F0FF",
    borderColor: "#52D6FA",
    type: "clinic",
    id: "act0a428e94308543aa89aac8e698b",
    value: "act0a428e94308543aa89aac8e698b",
    label: "clinic-drive:read-all",
  },
  {
    color: "#FFD2B3",
    borderColor: "#F89F60",
    type: "clinic",
    id: "act8304a48677524b3d81f22759944",
    value: "act8304a48677524b3d81f22759944",
    label: "clinic-timesheet:read-write",
  },
  {
    color: "#FFD2B3",
    borderColor: "#F89F60",
    type: "clinic",
    id: "acta10f40633fc747c4a687b3bbc90",
    value: "acta10f40633fc747c4a687b3bbc90",
    label: "clinic-timesheet:read-write-all",
  },
  {
    color: "#FFD2B3",
    borderColor: "#F89F60",
    type: "clinic",
    id: "actb19adcd7cc0b4468997ac36dbbe",
    value: "actb19adcd7cc0b4468997ac36dbbe",
    label: "clinic-timesheet:settings-read-write",
  },
  {
    color: "#BAB0FF",
    borderColor: "#6754EC",
    type: "clinic",
    id: "actbff27474c7e14f85b9293518c20",
    value: "actbff27474c7e14f85b9293518c20",
    label: "clinic-dashboard:read",
  },
]

export const ccg_permission_scope_types = [
  {
    color: "#AED7FF",
    borderColor: "#59A8FF",
    type: "ccg",
    id: "act9c68670e49e14a33b3549b6bc4e",
    value: "act9c68670e49e14a33b3549b6bc4e",
    label: "ccg-settings:read-write",
  },
  {
    color: "#BAB0FF",
    borderColor: "#6754EC",
    type: "ccg",
    id: "act563b6d5afeec4ab083d59b8f239",
    value: "act563b6d5afeec4ab083d59b8f239",
    label: "ccg-dashboard:read",
  },
]

export const service_category_types = [
  {
    department_key: "administration",
    service_authority: "vsc",

    color: "#59A8FF",
    id: "administration",
    value: "administration",

    subcategories: [
      {
        id: "administration/adjustments",
        value: "administration/adjustments",
      },
    ],
  },
  {
    department_key: "hygiene",
    service_authority: "acdq",
    color: "#FFE88C",

    id: "diagnostic-services",
    value: "diagnostic-services",
  },
  {
    department_key: "restorative",
    service_authority: "acdq",
    color: "#FD8888",

    id: "endodontics",
    value: "endodontics",
  },
  {
    department_key: "restorative",
    service_authority: "acdq",
    color: "#A6B2CE",

    id: "fixed-prosthodontics",
    value: "fixed-prosthodontics",

    subcategories: [
      {
        id: "fixed-prosthodontics/cerec",
        value: "fixed-prosthodontics/cerec",
      },
      {
        id: "fixed-prosthodontics/implant",
        value: "fixed-prosthodontics/implant",
      },
    ],
  },
  {
    department_key: "other",
    service_authority: "acdq",
    color: "#EBF6FF",

    id: "general-services",
    value: "general-services",

    subcategories: [
      {
        id: "general-services/bleaching",
        value: "general-services/bleaching",
      },
    ],
  },
  {
    department_key: "restorative",
    service_authority: "acdq",
    color: "#D8F7FF",

    id: "operative-dentistry",
    value: "operative-dentistry",
  },
  {
    department_key: "restorative",
    service_authority: "acdq",
    color: "#C1F1EA",

    id: "oral-surgery",
    value: "oral-surgery",

    subcategories: [
      {
        id: "oral-surgery/implant",
        value: "oral-surgery/implant",
      },
    ],
  },
  {
    department_key: "orthodontics",
    service_authority: "acdq",
    color: "#D2F6E6",

    id: "orthodontics",
    value: "orthodontics",
  },
  {
    department_key: "restorative",
    service_authority: "acdq",
    color: "#FDF6D9",

    id: "periodontics",
    value: "periodontics",

    subcategories: [
      {
        id: "periodontics/implant",
        value: "periodontics/implant",
      },

      {
        id: "periodontics/occlusal-appliance",
        value: "periodontics/occlusal-appliance",
      },
    ],
  },
  {
    department_key: "hygiene",
    service_authority: "acdq",
    color: "#FDD8D8",

    id: "preventive-services",
    value: "preventive-services",
  },
  {
    department_key: "denturology",
    service_authority: "acdq",
    color: "#E0DCFF",

    id: "removable-prosthodontics",
    value: "removable-prosthodontics",

    subcategories: [
      {
        id: "removable-prosthodontics/implant",
        value: "removable-prosthodontics/implant",
      },
    ],
  },
]

export const orthodontics_phase_types = [
  {
    id: "orthodontics/diagnostic",
    value: "orthodontics/diagnostic",
  },
  {
    id: "orthodontics/preventive-interceptive",
    value: "orthodontics/preventive-interceptive",
  },
  {
    id: "orthodontics/corrective",
    value: "orthodontics/corrective",

    subcategories: [
      {
        id: "orthodontics/corrective/setup",
        value: "orthodontics/corrective/setup",
      },
      {
        id: "orthodontics/corrective/follow-up",
        value: "orthodontics/corrective/follow-up",
      },
    ],
  },
  {
    id: "orthodontics/decementation",
    value: "orthodontics/decementation",
  },
  {
    id: "orthodontics/retention",
    value: "orthodontics/retention",
  },
]

export const patient_file_status_types = [
  {
    id: "pre_admission",
    value: "pre_admission",
  },
  {
    id: "open",
    value: "open",
  },
  {
    id: "inactive",
    value: "inactive",
  },
  {
    id: "closed",
    value: "closed",
  },
]
