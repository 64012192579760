/**
 * @see https://kentcdodds.com/blog/authentication-in-react-applications
 */
import React from "react"
import { AuthProvider, useAuth } from "./AuthContext"

function AuthApp(props) {
  const {
    authenticatedApp: AuthenticatedApp,
    unauthenticatedApp: UnauthenticatedApp,
    loader: Loader,
  } = props

  const { loading, user } = useAuth()
  return (
    <React.Suspense fallback={<Loader />}>
      {loading ? (
        <Loader />
      ) : user && user.email_verified ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
    </React.Suspense>
  )
}

function AuthAppWrapper({
  authProviderLibraryInitializerFunction,
  authProviderLibraryOptions,
  authProviderLibraryCallbacks,
  paths,
  loader,
  authenticatedApp,
  unauthenticatedApp,
}) {
  const providerProps = {
    authProviderLibraryInitializerFunction,
    authProviderLibraryOptions,
    authProviderLibraryCallbacks,
    paths: {
      ...(paths || {}),
      login: "/login",
      signup: "/signup",
      logout: "/logout",
      verifyEmail: "/verify-email",
    },
  }
  return (
    <AuthProvider {...providerProps}>
      <AuthApp
        loader={loader}
        authenticatedApp={authenticatedApp}
        unauthenticatedApp={unauthenticatedApp}
      />
    </AuthProvider>
  )
}

export default AuthAppWrapper
