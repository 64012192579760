import React from "react"

// styles
import { css } from "@emotion/core"

import { Input as Component } from "antd"

const stylingTextArea = (theme, props) => css`
  label: component-textarea--styling;

  ${theme.font.typo.default};
  font-size: ${theme.font.size.default};
  color: ${theme.textColorDefault};

  border-radius: 4px;
  background-color: ${theme.backgroundColorExtraLight};
  border-color: transparent !important;

  cursor: pointer;

  &:hover:not(:disabled):not(:focus) {
    background-color: ${theme.backgroundColorLighter};
  }

  &:focus {
    cursor: text;
  }

  &:disabled {
    cursor: default;
  }

  ${props.loading ? theme.skeleton.loading : null}
`

const TextArea = (props) => (
  <Component.TextArea
    className="component-textarea"
    css={(theme) => stylingTextArea(theme, props)}
    {...props}
  />
)

export default TextArea
