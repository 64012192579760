import React from "react"

// styles
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"

// components
import Controls from "./components/Controls"
import Pagination from "./components/Pagination"
import Table from "./components/Table"

// utils
import { get, unset, set, update, cloneDeep } from "lodash"
import applyFilterAndOrder from "./utils/applyFilterAndOrder"
import getRowKey from "./utils/getRowKey"

// store
import useTableViewConfig from "store/api/table-view-config"

// logs
import debugModule from "utils/debug"
const debug = debugModule("<DataTable/>")

const Container = styled.div`
  label: component-data-table;

  width: 100%;
  max-width: ${({ maxWidth, theme }) =>
    theme.utils.unit(maxWidth, "100px", "px")};

  margin-top: ${({ marginTop, theme }) =>
    theme.utils.unit(marginTop, "12px", "px")};
`

const DataTable = ({
  dataTable,
  name,
  rowKey = "key",
  maxWidth = "100%",
  checkboxGap = "24px",
  selectable = false,
  searchable = false,
  loading = false,
  pagination = true,
  schema,

  defaultSelection = [],
  defaultOrder,
  defaultFilter,
  defaultPageSize = 50,
  defaultView = "default",
  defaultViews = [],

  data = [],
  views,

  lock = false,

  presetViews = [],

  extraControls = [],

  onChangeView = () => null,
  onAddView = () => null,
  onDeleteView = () => null,
  onApplySelection = () => null,
}) => {
  const { t } = useTranslate(["ui"])

  const standardView = React.useMemo(
    () => ({
      type: "preset",
      key: "default",
      name: t("ui:datatable.views.default.label"),
      locked: false,
      config: {
        pageSize: defaultPageSize,
        filter: defaultFilter,
        order: defaultOrder
          ? defaultOrder.map((o, index) => ({
              id: new Date().getTime() - index,
              field: o[0],
              direction: o[1],
            }))
          : [],
      },
    }),
    [t, defaultPageSize, defaultFilter, defaultOrder]
  )

  const [selectedRows, setSelectedRows] = React.useState(defaultSelection)
  const [tableViews, setTableViews] = React.useState(
    dataTable ? dataTable.views : defaultViews
  )
  const currentViewRef = React.useRef(defaultView)
  const [currentView, setCurrentView] = React.useState(defaultView)

  const [viewEdited, setViewEdited] = React.useState(false)
  const [filters, setFilters] = React.useState(standardView.config.filter)
  const [orders, setOrders] = React.useState(standardView.config.order)

  const [searchValue, setSearchValue] = React.useState("")
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(standardView.config.pageSize)

  const mappedData = React.useMemo(
    () =>
      applyFilterAndOrder(data, {
        schema,
        filters,
        orders,
        search: searchValue,
      }),
    [data, schema, filters, orders, searchValue]
  )

  const selectedPages = React.useMemo(
    () =>
      selectedRows.map((id) =>
        mappedData.findIndex((record) => getRowKey(rowKey, record) === id)
      ),
    [selectedRows, rowKey, mappedData]
  )

  React.useEffect(() => {
    debug.log("setTableViews", {
      views,
      dataTable,
    })
    setTableViews(dataTable ? dataTable.views : views || [])
  }, [views, dataTable])

  React.useEffect(() => {
    if (currentViewRef.current === currentView) return

    currentViewRef.current = currentView

    const allViews = [standardView, ...presetViews, ...tableViews]
    const viewConfig = allViews.find((v) => v.key === currentView)?.config

    debug.log("setConfig", {
      allViews,
      currentView,
      viewConfig,
    })

    if (!viewConfig) return

    setPageSize(viewConfig.pageSize)
    setFilters(viewConfig.filter)
    setOrders(viewConfig.order)
  }, [tableViews, currentView, standardView, presetViews])

  return (
    <Container maxWidth={maxWidth} data-testid="datatable">
      <Controls
        lock={lock}
        pagination={pagination}
        pageSize={pageSize}
        selectedPages={selectedPages}
        countSelectedItems={selectedRows.length}
        countTotalItems={mappedData.length}
        searchable={searchable}
        selectable={selectable}
        onSearch={(value) => {
          debug.log("onSearch", value)
          setSearchValue(value)
          setPage(1)
        }}
        schema={schema}
        extraControls={extraControls}
        viewEdited={viewEdited}
        views={[
          standardView,
          ...presetViews.map((v) => ({ ...v, type: "preset" })),
          ...(dataTable ? dataTable.views : tableViews).map((v) => ({
            ...v,
            type: "custom",
          })),
        ]}
        filters={filters}
        orders={orders}
        currentView={currentView}
        onSetView={(value) => {
          debug.log("onSetView", value)
          setViewEdited(false)
          setPage(1)
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])

          setCurrentView(value)
        }}
        onChangeView={async (viewId, values = { config: {} }) => {
          debug.log("onChangeView", viewId, values)
          if (dataTable) await dataTable.onChangeView(viewId, values)

          setViewEdited(false)
          setPage(1)
          setTableViews((prev) =>
            prev.map((v) =>
              v.key === viewId
                ? { ...v, ...values, config: { ...v.config, ...values.config } }
                : v
            )
          )

          onChangeView(viewId, values)
        }}
        onAddView={async (item) => {
          debug.log("onAddView", item)
          let newId
          if (dataTable) {
            newId = await dataTable.onAddView(item)
            debug.log("onAddView", newId)
          }
          setViewEdited(false)
          setPage(1)
          setTableViews((prev) => [
            ...prev,
            dataTable ? { ...item, key: newId } : item,
          ])
          setCurrentView(newId || item.key)
          onAddView(item)
        }}
        onDeleteView={async (viewKey) => {
          debug.log("onDeleteView", viewKey)
          if (dataTable) await dataTable.onDeleteView(viewKey)

          setPage(1)
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])

          setCurrentView("default")
          setTableViews((prev) => prev.filter((v) => v.key !== viewKey))
          onDeleteView(viewKey)
        }}
        onResetView={async () => {
          debug.log("onResetView")
          setPage(1)
          setViewEdited(false)
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])

          currentViewRef.current = null
          setCurrentView(currentView)
        }}
        onSelect={(value) => {
          debug.log("onSelect", value)

          switch (value) {
            case "all": {
              const allRecordIds = mappedData.map((record) =>
                getRowKey(rowKey, record)
              )
              setSelectedRows(allRecordIds)
              if (dataTable) dataTable.onSelectRows(allRecordIds)
              break
            }

            case "all-in-view": {
              const allRecordIdsInView = (
                pagination !== null
                  ? mappedData.slice((page - 1) * pageSize, page * pageSize)
                  : mappedData
              ).map((record) => getRowKey(rowKey, record))
              setSelectedRows(allRecordIdsInView)
              if (dataTable) dataTable.onSelectRows(allRecordIdsInView)
              break
            }

            case "none": {
              setSelectedRows([])
              if (dataTable) dataTable.onSelectRows([])
              break
            }

            default:
              break
          }
        }}
        onMoveUpOrder={(orderIndex) => {
          debug.log("onMoveUpOrder", orderIndex)
          setViewEdited(true)
          setOrders((prev) => {
            const list = cloneDeep(prev)
            const item = list[orderIndex]
            list[orderIndex] = list[orderIndex - 1]
            list[orderIndex - 1] = item

            return list
          })
        }}
        onMoveDownOrder={(orderIndex) => {
          debug.log("onMoveDownOrder", orderIndex)
          setViewEdited(true)
          setOrders((prev) => {
            const list = cloneDeep(prev)
            const item = list[orderIndex]
            list[orderIndex] = list[orderIndex + 1]
            list[orderIndex + 1] = item

            return list
          })
        }}
        onAddOrder={() => {
          debug.log("onAddOrder")
          setViewEdited(true)
          setOrders((prev) => [
            ...prev,
            {
              id: new Date().getTime(),
              field: null,
              direction: null,
            },
          ])
        }}
        onDeleteOrder={(orderId) => {
          debug.log("onDeleteOrder", orderId)
          setViewEdited(true)
          setOrders((prev) => prev.filter((o) => o.id !== orderId))
        }}
        onChangeOrder={(orderId, values) => {
          debug.log("onChangeOrder", orderId, values)
          setViewEdited(true)
          setOrders((prev) =>
            prev.map((o) =>
              o.id === orderId
                ? {
                    ...o,
                    ...values,
                  }
                : o
            )
          )
        }}
        onCreateOrder={(id, colKey) => {
          debug.log("onCreateOrder", colKey)
          setViewEdited(true)
          setOrders([
            {
              id,
              field: colKey,
              direction: "asc",
            },
          ])
        }}
        onRemoveOrders={() => {
          debug.log("onRemoveOrders")
          setViewEdited(true)
          setOrders([])
        }}
        onAddFilter={(id, path) => {
          debug.log("onAddFilter", path)
          setViewEdited(true)
          setFilters((prev) =>
            update(cloneDeep(prev), path, (filterList) => [
              ...filterList,
              {
                id,
                field: null,
                operator: null,
                value: null,
              },
            ])
          )
        }}
        onAddFilterGroup={(id, path) => {
          debug.log("onAddFilterGroup", path)
          setPage(1)
          setViewEdited(true)
          setFilters((prev) =>
            update(cloneDeep(prev), path, (filterList) => [
              ...filterList,
              {
                id: id - 1,
                and: [
                  {
                    id: id,
                    field: null,
                    operator: null,
                    value: null,
                  },
                ],
              },
            ])
          )
        }}
        onDuplicateFilter={(id, path, pos) => {
          debug.log("onDuplicateFilter", path, pos)
          setViewEdited(true)
          setFilters((prev) =>
            update(cloneDeep(prev), path, (filterList) => {
              const f = {
                ...filterList[pos],
                id,
              }

              if (filterList.length === 1) {
                return [...filterList, f]
              }

              return [
                ...filterList.slice(0, pos + 1),
                f,
                ...filterList.slice(pos + 1),
              ]
            })
          )
        }}
        onDeleteFilter={(path, pos) => {
          debug.log("onDeleteFilter", path, pos)
          setViewEdited(true)
          setFilters((prev) =>
            update(cloneDeep(prev), path, (filterList) =>
              filterList.filter((_, index) => index !== pos)
            )
          )
        }}
        onChangeGroupType={(path, value) => {
          debug.log("onChangeGroupType", path, value)
          setPage(1)
          setViewEdited(true)
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])
          if (path.length === 1) {
            setFilters((prev) => {
              const [rootPath] = path
              const items = get(prev, rootPath)
              return { [value]: items }
            })
          } else {
            setFilters((prev) => {
              const rootPath = path.slice(0, -1)
              const tmp = cloneDeep(prev)
              const items = get(prev, path)
              unset(tmp, rootPath)
              set(tmp, rootPath, { [value]: items })
              return tmp
            })
          }
        }}
        onChangeFilter={(path, item) => {
          debug.log("onChangeFilter", path, item)
          setViewEdited(true)
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])
          setPage(1)
          setFilters((prev) => set(cloneDeep(prev), path, item))
        }}
        onCreateFilter={(id, colKey) => {
          debug.log("onCreateFilter", colKey)
          setViewEdited(true)
          setFilters({
            id: "group1",
            and: [
              {
                id,
                field: colKey,
                operator: "equals",
                value: null,
              },
            ],
          })
        }}
        onRemoveFilters={() => {
          debug.log("onRemoveFilters")
          setViewEdited(true)
          setFilters(null)
        }}
      />

      <Table
        schema={schema}
        rowKey={rowKey}
        selectable={selectable}
        searchable={searchable}
        selection={selectedRows}
        data={mappedData}
        pagination={pagination ? { page, pageSize } : null}
        onRemoveSelection={() => {
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])
        }}
        onSelectRecord={(recordId) =>
          setSelectedRows((prev) => {
            const result = [...prev, recordId]

            if (dataTable) dataTable.onSelectRows(result)
            return result
          })
        }
        onRemoveRecord={(recordId) =>
          setSelectedRows((prev) => {
            const result = prev.filter((i) => i !== recordId)
            if (dataTable) dataTable.onSelectRows(result)
            return result
          })
        }
        onApplySelection={() => {
          onApplySelection(selectedRows)
          setSelectedRows([])
          if (dataTable) dataTable.onSelectRows([])
        }}
      />

      {pagination && (
        <Pagination
          page={page}
          maxPages={Math.ceil(mappedData.length / pageSize)}
          pageSize={pageSize}
          setPage={(page) => setPage(page)}
          setPageSize={(size) => {
            setViewEdited(true)
            setPageSize(size)
          }}
        />
      )}
    </Container>
  )
}

const useDataTable = ({ clinicId, corporateClinicGroupId, tableKey }) => {
  const TableViewConfig = useTableViewConfig({
    clinicId,
    corporateClinicGroupId,
    tableKey,
  })

  const [selectedRows, onSelectRows] = React.useState([])

  return {
    isLoading: TableViewConfig.isLoading,

    selectedRows,
    onSelectRows,

    views: React.useMemo(
      () =>
        TableViewConfig.isLoading
          ? []
          : TableViewConfig.data.map((item) => ({
              type: "custom",
              key: item.table_view_config_id,
              name: item.name,
              locked: item.locked,
              config: item.config,
            })),
      [TableViewConfig.isLoading, TableViewConfig.data]
    ),

    onChangeView: React.useCallback(
      async (viewId, values) => {
        try {
          debug.log("[hook:onChangeView]", viewId, values)

          await TableViewConfig.mutate(async (prev) => {
            const newView = await TableViewConfig.update(viewId, {
              name: values.name,
              locked: values.locked,
              config: {
                pageSize: values.config.pageSize,
                filter: values.config.filter,
                order: values.config.order,
              },
            })

            return prev.map((i) =>
              i.table_view_config_id === viewId ? newView : i
            )
          }, false)
        } catch (e) {
          debug.error(e.message)
        }
      },
      [TableViewConfig]
    ),
    onAddView: React.useCallback(
      async (newView) => {
        try {
          debug.log("[hook:onAddView]", newView)

          const item = await TableViewConfig.create({
            table_key: tableKey,
            name: newView.name,
            locked: false,
            config: {
              pageSize: newView.config.pageSize,
              filter: newView.config.filter,
              order: newView.config.order,
            },
          })

          TableViewConfig.mutate(async (prev) => [...prev, item], false)

          return item.table_view_config_id
        } catch (e) {
          debug.error(e.message)
        }
      },
      [tableKey, TableViewConfig]
    ),
    onDeleteView: React.useCallback(
      async (viewId) => {
        try {
          debug.log("[hook:onDeleteView]", viewId)
          await TableViewConfig.remove(viewId)

          TableViewConfig.mutate(
            async (prev) =>
              prev.filter((item) => item.table_view_config_id !== viewId),
            false
          )
        } catch (e) {
          debug.error(e.message)
        }
      },
      [TableViewConfig]
    ),
  }
}

DataTable.useDataTable = useDataTable

export default DataTable
