import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// component
import { Collapse as Component } from "antd"

// assets
import { ReactComponent as Icon } from "assets/arrows/arrow-triangle.svg"

const IconWrapper = styled.div`
  label: component-collapse--icon-wrapper;

  svg {
    margin-top: 4px;

    transition: transform 300ms;

    transform: ${({ isActive }) =>
      isActive ? "rotate(0deg)" : "rotate(-90deg)"};
  }
`

// styling
const styling = (theme) => css`
  label: component-collapse--styling;

  background: #ebeff7;
  border-radius: 16px;
  border: none;

  padding: 8px;

  .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }

  > .ant-collapse-item {
    background: #ffffff;

    border: 1px solid #d9deeb;
    border-radius: 12px !important;
    overflow: hidden;

    > .ant-collapse-header {
      background: #f7f8fa;
      border-radius: 12px 12px 0px 0px !important;
      border-bottom: 1px solid #d9deeb;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .ant-collapse-content {
    border-top: none;
  }
`

const Collapse = ({ children, ...rest }) => {
  return (
    <Component
      className="component-collapse"
      data-testid="collapse"
      css={styling}
      expandIcon={(props) => {
        // NOTE:
        // props object:
        //
        // accordion: false
        // children: {$$typeof: Symbol(react.element), key: null, ref: null, props: {…}, type: ƒ, …}
        // className: "component-panel"
        // collapsible: undefined
        // destroyInactivePanel: false
        // expandIcon: ƒ renderExpandIcon()
        // forceRender: false
        // header: {$$typeof: Symbol(react.element), type: {…}, key: null, ref: null, props: {…}, …}
        // headerClass: ""
        // isActive: true
        // onItemClick: ƒ (key)
        // openMotion: {motionName: "ant-motion-collapse", onAppearStart: ƒ, onEnterStart: ƒ, onAppearActive: ƒ, onEnterActive: ƒ, …}
        // panelKey: "2021-04"
        // prefixCls: "ant-collapse"
        // showArrow: true

        return (
          <IconWrapper isActive={props.isActive}>
            <Icon />
          </IconWrapper>
        )
      }}
      {...rest}
    >
      {children}
    </Component>
  )
}

const Panel = ({ children, ...rest }) => {
  return (
    <Component.Panel
      className="component-panel"
      data-testid="collapse-panel"
      {...rest}
    >
      {children}
    </Component.Panel>
  )
}

Collapse.Panel = Panel

export default Collapse
