import React from "react"
import { CSVLink } from "react-csv"

// components
import { Flex, Typo, Table, Dropdown, Menu } from "components"

// styles
import styled from "@emotion/styled"

// utils
import { mapValues, get } from "lodash"

// translation
import useTranslate from "hooks/useTranslate"

// assets
import { ReactComponent as IconDots } from "assets/icons/icon-dots.svg"

// logs
import debugModule from "utils/debug"
const debug = debugModule("<AnalyticTable/>")

const Wrapper = styled.div`
  width: ${({ width }) => width}px;
  margin-bottom: 16px;

  th {
    background-color: white !important;
  }

  .ant-table-cell {
    vertical-align: top !important;

    padding: 0 !important;
    border-right: 1px solid rgba(200, 200, 200, 0.35);
    border-bottom: 1px solid rgba(200, 200, 200, 0.35) !important;

    &:last-of-type {
      border-right: none;
    }
  }

  tr:first-of-type .ant-table-cell {
    border-bottom: 1px solid rgba(200, 200, 200, 0.75) !important;
    /*    border-top: 1px dashed rgba(200, 200, 200, 0.35);*/
  }
`

const Component = ({
  uniqColumns = [],
  title,
  data = [],
  csvData,
  width,
  schema,
  height,
  rowKey = "key",
  ...props
}) => {
  const { t } = useTranslate(["ui"])
  const tableConfig = React.useMemo(() => {
    const config = { data: null, spanConfig: {} }

    config.data = data.map((item, index, arr) => {
      const result = { ...item }

      uniqColumns.forEach((key, i, keys) => {
        const prevItem = data[index - 1]
        if (
          prevItem &&
          keys.slice(0, i + 1).every((k) => item[k] === prevItem[k])
        ) {
          result[key] = null
        } else {
          config.spanConfig[key] = [...(config.spanConfig[key] || []), index]
        }
      })

      return result
    })

    config.spanConfig = mapValues(config.spanConfig, (list) =>
      list.map((item, index, arr) => {
        const next = arr[index + 1] || data.length

        return { from: item, length: next - item }
      })
    )

    return config
  }, [data, uniqColumns])

  debug.log({ data, tableConfig })

  return (
    <Wrapper width={width}>
      <Flex.Row justify="space-between">
        <Flex.Col />

        <Flex.Col>
          <Typo.Title level={3} space="16px">
            {title}
          </Typo.Title>
        </Flex.Col>
        <Flex.Col>
          <Dropdown
            arrow={false}
            trigger="click"
            overlay={
              <Menu
                items={[
                  {
                    key: "csv",
                    label: (
                      <CSVLink
                        style={{ textDecoration: "none" }}
                        filename={`${title}.csv`}
                        data={
                          csvData
                            ? csvData(schema, data)
                            : defaultCsvData(schema, data)
                        }
                        target="_blank"
                      >
                        {t("ui:button.download.label", { context: "csv" })}
                      </CSVLink>
                    ),
                  },
                ]}
              />
            }
          >
            <IconDots />
          </Dropdown>
        </Flex.Col>
      </Flex.Row>

      <Table
        dataSource={data}
        columns={schema
          .filter((col) => !col.hidden)
          .map((col) => ({
            ...col,
            ellipsis: true,
            onCell: (_, rowIndex) => {
              if (!tableConfig.spanConfig[col.dataIndex]) return { rowSpan: 1 }

              const spanConfig = tableConfig.spanConfig[col.dataIndex].find(
                (item) => item.from === rowIndex
              )

              if (!spanConfig) return { rowSpan: 0 }

              if (spanConfig.from === rowIndex)
                return {
                  rowSpan: spanConfig.length,
                }

              return { rowSpan: 0 }
            },

            title: (
              <Typo.Text
                breakWord
                breakSpaces
                textAlign={col.align}
                lineHeight="100%"
                block
                font="bold700"
                size="small"
                margin="8px 4px"
              >
                {col.title}
              </Typo.Text>
            ),
            children: col.children
              ? col.children
                  .filter((subCol) => !subCol.hidden)
                  .map((subCol) => ({
                    ...subCol,
                    ellipsis: true,
                    title: (
                      <Typo.Text
                        breakWord
                        breakSpaces
                        textAlign={subCol.align}
                        lineHeight="100%"
                        block
                        color="textColorLight"
                        font="bold600"
                        size="small"
                        margin="8px 4px"
                      >
                        {subCol.title}
                      </Typo.Text>
                    ),
                  }))
              : undefined,
          }))}
        scroll={{ x: 0, y: height === undefined ? undefined : height * 0.75 }}
        rowKey={rowKey}
        {...props}
      />
    </Wrapper>
  )
}

function defaultCsvData(schema, data) {
  return [
    schema
      .map((col) =>
        col.children
          ? col.children.map((subCol) => `${col.title}:${subCol.title}`)
          : [col.title]
      )
      .flat(),
    ...data.map((record) =>
      schema
        .map((col) =>
          col.children
            ? col.children.map((subCol) =>
                subCol.rawValue
                  ? subCol.rawValue(get(record, subCol.dataIndex), record)
                  : get(record, subCol.dataIndex)
              )
            : [
                col.rawValue
                  ? col.rawValue(get(record, col.dataIndex), record)
                  : get(record, col.dataIndex),
              ]
        )
        .flat()
    ),
  ]
}

export default Component
