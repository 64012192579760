import React from "react"
// import { Prompt } from "react-router-dom"

// styles
import { css } from "@emotion/core"

// components
import { Space } from "components"

// assets
import IconNumber from "assets/icons/icon-number"

// translation
// import useTranslate from "hooks/useTranslate"

import { Tabs as Component } from "antd"

const { TabPane } = Component

const styling = (theme) => css`
  label: component-tabs--styling;
  .ant-tabs-content-holder {
    padding-top: 20px;
  }

  .ant-tabs-tab {
    padding: 4px 0;
    color: ${theme.textColorExtraLight};
    .icon-number {
      background-color: ${theme.textColorExtraLight};
    }

    &.ant-tabs-tab-active {
      color: ${theme.themeColorDefault};
      .icon-number {
        background-color: ${theme.themeColorDefault};
      }
    }

    &.ant-tabs-tab-disabled {
      color: ${theme.textColorUltraLight};
      .icon-number {
        background-color: ${theme.textColorUltraLight};
      }
    }
  }
  .ant-tabs-top-bar {
    margin-bottom: 50px;
  }
  .ant-tabs-tab-active ant-tabs-tab {
    ${theme.font.typo.bold600};
    font-size: ${theme.font.size.tall};
    color: ${theme.textColorLight};

    &.ant-tabs-tab-active {
      color: ${theme.themeColorDefault};
    }
  }
`

// const Prompt = (
//   <Prompt
//     when={false}
//     message={t(
//       "validation:message.types.confirmation.__.continue-without-save.label"
//     )}
//   />
// )

const Tabs = ({ children, labels, numbers, animated = false, ...rest }) => {
  // const { t } = useTranslate(["validation"])

  const tabs = React.Children.toArray(children)

  return (
    <Component
      css={styling}
      data-testid="tabs"
      className="component-tabs"
      animated={animated}
      {...rest}
    >
      {tabs.map((child, index) => (
        <TabPane
          data-testid="tab-pane"
          closable={labels[index].closable}
          disabled={labels[index].disabled}
          tab={
            numbers ? (
              <Space>
                <IconNumber count={index + 1} />
                {labels[index].label}
              </Space>
            ) : (
              labels[index].label
            )
          }
          key={labels[index].id}
          style={{ outline: "none" }}
        >
          {child}
        </TabPane>
      ))}
    </Component>
  )
}

export default Tabs
