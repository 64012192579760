import React from "react"

// styles
import styled from "@emotion/styled"

// moment
import moment from "moment"

// Components
import { Flex, Input, Typo } from "components"
import { Popover } from "antd"

// translation
import useTranslate from "hooks/useTranslate"

// utils
import { orderBy } from "lodash"
import * as fn from "utils/functions"
import { regexp } from "utils/validation"
import { domain_employee_workday_status_types } from "utils/data-types"

const ComponentConfig = styled.div`
  label: component-config-input;
  width: ${({ width = "100%" }) => width};

  .component-input {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0;
    text-align: center;

    background: transparent;

    ${({ theme }) => theme.font.typo.bold600};
    font-size: ${({ theme }) => theme.font.size.default};
    color: ${({ theme }) => theme.textColorDefault};

    line-height: 140%;
    letter-spacing: 0.02em;
  }
`

const ComponentConfigBox = styled.div`
  label: component-config-input--box;
  height: 100%;

  text-align: center;

  border-left: ${({ border, theme }) =>
    border ? `1px solid ${theme.borderColorLight}` : "none"};

  background: ${({ status }) =>
    domain_employee_workday_status_types.find((type) => type.id === status)
      ?.color || "unset"};
`

const ComponentConfigInputBox = styled.div`
  label: component-config-input--input-box;
  border: 1px solid ${({ theme }) => theme.borderColorLight};
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;

  margin-bottom: 8px;
  height: 32px;
`

const order = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

const ConfigInput = ({
  disabled = {},
  value,
  status = {},
  onChange,
  timeFormat,
  labels,
  showWeekdayLabels = true,
  popoverContent,
  ...rest
}) => {
  const { i18n } = useTranslate()
  moment.locale(i18n.language)

  const formatter = (value) => {
    if (value === null) return ""
    if (typeof value !== "number") return value

    return fn.formatNumberToTime(value, timeFormat)
  }
  const parser = (value) => {
    switch (timeFormat) {
      case ":": {
        if (!value.match(regexp.timeformat.minutes)) return value
        return fn.parseTimeToNumber(value, timeFormat)
      }
      case ".": {
        if (!value.match(regexp.timeformat.decimals)) return value
        return fn.parseTimeToNumber(value, timeFormat)
      }
      default:
        throw new Error(`timeFormat ${timeFormat} is not supported!`)
    }
  }

  const onBlur = (key, v) => {
    const val = Number(v)

    if (fn.is_number(val)) {
      switch (timeFormat) {
        case ":": {
          const rangedValue = val < 60 ? val * 60 : val
          const maxedValue = Math.min(Math.round(rangedValue), 960)
          onChange({ ...value, [key]: maxedValue }, key, maxedValue)
          break
        }
        case ".": {
          const rangedValue = val < 100 ? val * 100 : val
          const maxedValue = Math.min(Math.round(rangedValue), 1300)
          const mappedValue = Math.round((maxedValue / 100) * 60)
          onChange({ ...value, [key]: mappedValue }, key, mappedValue)
          break
        }
        default:
          throw new Error(`timeFormat ${timeFormat} is not supported!`)
      }
    }
  }

  return (
    <ComponentConfig {...rest}>
      <ComponentConfigInputBox>
        <Flex.Row fullHeight>
          {orderBy(Object.keys(value), (key) => order[key], ["asc"]).map(
            (key, index) => {
              return (
                <Flex.Col key={key} flex="1">
                  <ComponentConfigBox border={index !== 0} status={status[key]}>
                    {disabled === "all" || disabled[key] ? (
                      <Flex.Row fullHeight align="middle" justify="center">
                        <Typo.Text
                          inline={false}
                          size="default"
                          color="textColorLighter"
                          font="bold600"
                        >
                          {formatter(value[key])}
                        </Typo.Text>
                      </Flex.Row>
                    ) : popoverContent ? (
                      <Popover
                        content={popoverContent(key)}
                        placement="bottom"
                        trigger="click"
                      >
                        <div>
                          <Input
                            onBlur={(e) => onBlur(key, e.target.value)}
                            value={formatter(value[key])}
                            onChange={(e) =>
                              onChange(
                                { ...value, [key]: parser(e.target.value) },
                                key,
                                parser(e.target.value)
                              )
                            }
                          />
                        </div>
                      </Popover>
                    ) : (
                      <Input
                        onBlur={(e) => onBlur(key, e.target.value)}
                        value={formatter(value[key])}
                        onChange={(e) =>
                          onChange(
                            { ...value, [key]: parser(e.target.value) },
                            key,
                            parser(e.target.value)
                          )
                        }
                      />
                    )}
                  </ComponentConfigBox>
                </Flex.Col>
              )
            }
          )}
        </Flex.Row>
      </ComponentConfigInputBox>

      {labels && (
        <Flex.Row>
          {Object.entries(labels).map(([key, label]) => {
            return (
              <Flex.Col key={key} flex="1">
                <ComponentConfigBox>{label}</ComponentConfigBox>
              </Flex.Col>
            )
          })}
        </Flex.Row>
      )}

      {showWeekdayLabels && (
        <Flex.Row>
          {moment.weekdaysShort().map((day) => {
            return (
              <Flex.Col key={day} flex="1">
                <ComponentConfigBox>
                  <Typo.Text
                    size="small"
                    color="textColorLighter"
                    textTransform="uppercase"
                  >
                    {day.substring(0, 3)}
                  </Typo.Text>
                </ComponentConfigBox>
              </Flex.Col>
            )
          })}
        </Flex.Row>
      )}
    </ComponentConfig>
  )
}

export default ConfigInput
