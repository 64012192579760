import i18n from "i18next"

// i18n react package
import { initReactI18next } from "react-i18next"

// language detector
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_ENV === "development",

    ns: [
      "app",
      "generic",
      "data",
      "ui",
      "validation",
      "common",
      "clinic-admin",
      "clinic-objectives",
      "clinic-drive",
      "clinic-marketing",
      "clinic-timesheet",
      "clinic-dashboard",
      "ccg-admin",
      "ccg-dashboard",
    ],
    defaultNS: ["app"],

    lng: "fr",
    fallbackLng: "fr",
    fallbackNS: "app",

    resources: {
      // prettier-ignore
      fr: {
        validation: require(`translations/validation.fr.yaml`).default,
        app: require(`translations/app.fr.yaml`).default,
        common: require(`translations/common.fr.yaml`).default,
        data: require(`translations/data.fr.yaml`).default,
        generic: require(`translations/generic.fr.yaml`).default,
        ui: require(`translations/ui.fr.yaml`).default,
        "clinic-admin": require(`translations/module-clinic-settings.fr.yaml`).default,
        "clinic-objectives":require(`translations/module-clinic-objectives.fr.yaml`).default,
        "clinic-drive": require(`translations/module-clinic-drive.fr.yaml`).default,
        "clinic-marketing":require(`translations/module-clinic-marketing.fr.yaml`).default,
        "clinic-timesheet":require(`translations/module-clinic-timesheet.fr.yaml`).default,
        "clinic-dashboard":require(`translations/module-clinic-dashboard.fr.yaml`).default,
        "ccg-admin": require(`translations/module-ccg-settings.fr.yaml`).default,
        "ccg-dashboard": require(`translations/module-ccg-dashboard.fr.yaml`).default,
      },
      // prettier-ignore
      en: {
        validation: require(`translations/validation.en.yaml`).default,
        app: require(`translations/app.en.yaml`).default,
        common: require(`translations/common.en.yaml`).default,
        data: require(`translations/data.en.yaml`).default,
        generic: require(`translations/generic.en.yaml`).default,
        ui: require(`translations/ui.en.yaml`).default,
        "clinic-admin": require(`translations/module-clinic-settings.en.yaml`).default,
        "clinic-objectives":require(`translations/module-clinic-objectives.en.yaml`).default,
        "clinic-drive": require(`translations/module-clinic-drive.en.yaml`).default,
        "clinic-marketing":require(`translations/module-clinic-marketing.en.yaml`).default,
        "clinic-timesheet":require(`translations/module-clinic-timesheet.en.yaml`).default,
        "clinic-dashboard":require(`translations/module-clinic-dashboard.en.yaml`).default,
        "ccg-admin": require(`translations/module-ccg-settings.en.yaml`).default,
        "ccg-dashboard": require(`translations/module-ccg-dashboard.en.yaml`).default,
      },
    },

    interpolation: {
      escapeValue: false,
      format: function format(value, format) {
        if (format === "lowercase") return value.toLowerCase()
        return value
      },
    },
  })

export default i18n
