// import React from "react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"

const generateSWRCacheKey = ({ postalAddressId }) => {
  return !postalAddressId
    ? null
    : () => `/postal_address?postal_address_id=eq.${postalAddressId}&select=
postal_address_id,
address,
city,
country_code,
district_code,
postal_code`
}

const usePostalAddress = (postalAddressId) => {
  const orca = useOrcaAxios()

  const fetcher = async (url) => {
    const {
      data: [data],
    } = await orca.get(url)
    return data
  }

  const { data, error, mutate, isValidating } = useSWR(
    generateSWRCacheKey({ postalAddressId }),
    fetcher
  )

  const update = async (values) => {
    const {
      data: [data],
    } = await orca.patch(
      `/postal_address?postal_address_id=eq.${postalAddressId}`,
      values,
      {
        headers: {
          Prefer: "return=representation",
        },
      }
    )
    return data
  }
  return {
    data,
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    update,
  }
}

const update = async (orca, postalAddressId, values) => {
  const {
    data: [data],
  } = await orca.patch(
    `/postal_address?postal_address_id=eq.${postalAddressId}`,
    values,
    {
      headers: {
        Prefer: "return=representation",
      },
    }
  )
  return data
}

const create = async (orca, values) => {
  const { subjectId, subjectType, ...rest } = values

  const { data: response } = await orca.post("/rpc/postal_address__insert", {
    subject_id_value: subjectId,
    subject_type_value: subjectType,
    ...rest,
  })
  return response
}

export { generateSWRCacheKey, create, update }

export default usePostalAddress
