import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { Input as Component } from "antd"
import { InputNumber } from "antd"

// assets
import { ReactComponent as IconPencil } from "assets/icons/icon-pencil.svg"

const LockedValue = styled.div`
  label: component-input--lock;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorLight};

  background-color: ${({ theme }) => theme.backgroundColorExtraLight};
  padding: 0.35rem 0.5rem;

  ${({ theme }) => theme.round};

  line-height: 140%;

  ${({ theme, size }) => theme.height[size]}

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  svg {
    margin-left: 8px;
    opacity: 0.5;
    cursor: pointer;
    width: 16px;

    &:hover {
      opacity: 1;
    }
  }
`

const stylingInput = (theme, props) => css`
  label: component-input--styling;

  ${theme.font.typo.default};
  font-size: ${theme.font.size.default};
  color: ${theme.textColorDefault};

  background-color: ${props.ghost
    ? "transparent"
    : theme.backgroundColorExtraLight};

  ${theme.round};

  line-height: 140%;
  ${theme.height[props.size]}

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  padding: 0.35rem 0.5rem;

  border: none;

  cursor: pointer;

  &::placeholder {
    color: ${theme.textColorPlaceholder};
  }

  > input {
    background-color: transparent;
    ${theme.font.typo.default};
    font-size: ${theme.font.size.default};
    color: ${theme.textColorDefault};

    &::placeholder {
      color: ${theme.textColorPlaceholder};
    }
  }

  ${props.loading ? theme.skeleton.loading : null}

  &:hover:not(:disabled):not(:focus) {
    background-color: ${theme.backgroundColorLighter};
  }

  &:focus {
    cursor: text;
  }

  &:disabled {
    cursor: default;
  }
`

const Input = React.forwardRef(
  (
    { loading, ghost, expandOnHover, lock, size = "default", ...props },
    ref
  ) => {
    const [locked, setLocked] = React.useState(true)

    if (lock && locked)
      return (
        <LockedValue
          data-testid="input"
          data-locked="true"
          data-value={props.value}
          size={size}
        >
          {props.value}
          <IconPencil
            data-testid="input-unlock-icon"
            onClick={() => setLocked(false)}
          />
        </LockedValue>
      )

    return (
      <Component
        ref={ref}
        css={(theme) =>
          stylingInput(theme, { ghost, size, expandOnHover, loading })
        }
        className="component-input"
        data-testid="input"
        {...props}
      />
    )
  }
)

const stylingNumberInput = (theme, props) => css`
  label: component-input-number--styling;

  cursor: pointer;

  background: ${theme.backgroundColorExtraLight};
  border: none;
  box-sizing: border-box;
  ${theme.round}

  ${theme.height[props.size]}

  padding: 0 8px;

  width: 100%;

  cursor: pointer;

  .ant-input-number-disabled {
    background: ${theme.backgroundColorUltraLight};
  }

  &::placeholder {
    color: ${theme.textColorPlaceholder};
  }

  &:not(.ant-input-number-disabled) {
    &:hover {
      background: ${theme.backgroundColorLighter};
    }

    &:focus {
      cursor: text;
      background: ${theme.backgroundColorExtraLight};
    }
  }

  input {
    text-align: ${props.align || "left"};
    ${theme.font.typo.bold600};
    font-size: ${theme.font.size.default};
    color: ${props.hasNoneValue
      ? theme.textColorExtraLight
      : theme.textColorDefault};

    height: 100%;

    padding: 0;
    line-height: 140%;
    letter-spacing: 0.01em;

    &::placeholder {
      color: ${theme.textColorPlaceholder};
    }
  }

  .ant-input-number-input-wrap {
    height: 100%;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`

const NumberInput = React.forwardRef(
  ({ loading, value, align, size = "default", ...props }, ref) => {
    return (
      <InputNumber
        ref={ref}
        css={(theme) =>
          stylingNumberInput(theme, {
            size,
            loading,
            align,
            hasNoneValue: [undefined, null].includes(value),
          })
        }
        className="component-input-number"
        value={value}
        {...props}
      />
    )
  }
)

Input.Number = NumberInput

export default Input
