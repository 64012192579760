// grid component
import { createResponsiveGrid, createResponsiveGridCell } from "./Grid"

// main grid
const Grid = ({ options }) => createResponsiveGrid("div", options)

// grid cell
const Cell = ({ options }) => createResponsiveGridCell("div", options)

Grid.Cell = Cell

export default Grid
