// styles
import styled from "@emotion/styled"

// assets
import { ReactComponent as IconSuccess } from "assets/icons/icon-success.svg"
import { ReactComponent as IconExclamation } from "assets/icons/icon-exclamation.svg"
import { ReactComponent as IconExclamationRed } from "assets/icons/icon-exclamation-red.svg"

const Container = styled.div`
  label: component-alert;
  background-color: ${({ theme }) => theme.backgroundColorExtraLight};

  width: ${({ theme, block, maxWidth }) =>
    block ? "100%" : theme.utils.unit(maxWidth, "500px", "px")};

  padding: 8px 16px;
  ${({ theme }) => theme.round};

  display: flex;
`

const Icon = styled.div`
  label: component-alert--icon;

  width: 30px;

  display: flex;
  align-items: center;
`

const Content = styled.div`
  label: component-alert--content;

  width: ${({ theme, block, maxWidth }) =>
    block ? "100%" : theme.utils.unit(maxWidth - 30, "450px", "px")};
`

const Component = ({ block, maxWidth, content, success, failure, warning }) => (
  <Container data-testid="alert" block={block} maxWidth={maxWidth}>
    <Icon>
      {success && (
        <IconSuccess
          width="16px"
          height="16px"
          data-testid="alert-icon-success"
        />
      )}
      {warning && <IconExclamation data-testid="alert-icon-warning" />}
      {failure && <IconExclamationRed data-testid="alert-icon-failure" />}
    </Icon>

    <Content block={block} maxWidth={maxWidth}>
      {content}
    </Content>
  </Container>
)

export default Component
