import React from "react"

// styles
import styled from "@emotion/styled"

// components
import { TitleWithLine } from "components"

// "phone": 320px,
// "note": 420px,
// "tablet": 768px,
// "desktop": 1208px

const Component = styled.div`
  label: component-objective-group;

  margin-bottom: 16px;
  grid-column: auto / span ${(props) => Math.min(props.items, 3)};

  @media (max-width: 1208px) {
    grid-column: auto / span ${(props) => Math.min(props.items, 3)};
  }
  @media (max-width: 1100px) {
    grid-column: auto / span ${(props) => Math.min(props.items, 2)};
  }
  @media (max-width: 768px) {
    grid-column: auto / span ${(props) => Math.min(props.items, 1)};
  }

  @media (max-width: 420px) {
    grid-column: auto / span ${(props) => Math.min(props.items, 1)};
  }
`

const ObjectivesContainer = styled.div`
  label: component-objective-group-container;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const ObjectivesContainerItem = styled.div`
  label: component-objective-group-container-item;

  > * {
    width: 100% !important;
  }
`

const ObjectiveGroup = ({ title, children }) => (
  <Component items={React.Children.toArray(children).length}>
    <TitleWithLine title={title} />
    <ObjectivesContainer>
      {React.Children.toArray(children).map((item, index) => (
        <ObjectivesContainerItem key={index}>{item}</ObjectivesContainerItem>
      ))}
    </ObjectivesContainer>
  </Component>
)

export default ObjectiveGroup
