import React from "react"

// Sentry
import * as Sentry from "@sentry/react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"
import useFirebaseAxios from "hooks/useFirebaseAxios"

// auth module
import { FirebaseAuthService } from "services/firebase"

// store
import { create as createPhoneNumber } from "store/api/phone-number"
import { useToken } from "store/token"

// utils
import { omit, pick } from "lodash"
import { useUser, useLogout } from "auth0/lib/AuthApp"

// store
// import { useFirebaseAuth } from "store/firebase-auth"

//
// logs
import debugModule from "utils/debug"
const debug = debugModule("webuser")

const generateSWRCacheKey = ({ authKey, tokenIsReady }) => {
  return tokenIsReady && authKey
    ? () => `/webuser?auth0_user_key=eq.${authKey}&select=
webuser_id,
currently_signed_in_tenant_id,
family_name,
gender,
given_name,
language_id,
name_title_id,
email_address!webuser_email_address_id_fkey(
email
),
phone_number(
number
)`
    : null
}

const useWebuser = (revalidateOnMount = false) => {
  const orca = useOrcaAxios()
  const firebase = useFirebaseAxios()
  const { user } = useUser()
  const { logout } = useLogout()
  const { tokenStatus, databaseToken, serverToken } = useToken()

  const { data, error, mutate, isValidating } = useSWR(
    generateSWRCacheKey({
      authKey: user.sub,
      tokenIsReady:
        tokenStatus === "success" ||
        (tokenStatus === "loading" && databaseToken && serverToken),
    }),
    async (url) => {
      const {
        data: [data],
      } = await orca.get(url)
      return data
    },
    { revalidateOnMount }
  )

  const webuserId = React.useMemo(() => (data ? data.webuser_id : null), [data])

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === "development" || !webuserId) return

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: webuserId,
        username: `${data.family_name} ${data.given_name}`,
        email: data.email_address?.email ?? "no-email-found",
      })
    })
  }, [data, webuserId])

  const update = async ({ attach = {}, ...values }) => {
    let updateWebuserValues = { ...values }
    let phoneNumber
    let attachedValues = {}

    if (attach.phone_number) {
      phoneNumber = await createPhoneNumber(orca, {
        subjectId: webuserId,
        subjectType: "webuser",
        value: attach.phone_number,
      })

      attachedValues.phone_number = pick(phoneNumber, ["number"])
      updateWebuserValues.phone_number_id = phoneNumber.phone_number_id
    }

    const {
      data: [webuserProfile],
    } = await orca.patch(
      `/webuser?webuser_id=eq.${webuserId}`,
      updateWebuserValues,
      {
        headers: {
          Prefer: "return=representation",
        },
      }
    )

    return {
      ...data,
      ...omit(webuserProfile, [
        "email_address_id",
        "email_setting_id",
        "phone_number_id",
        "honorific_id",
        "auth0_user_key",
        "created_at",
        "updated_at",
      ]),
      ...attachedValues,
    }
  }

  const signInTenant = async () => {
    debug.time()
    debug.log(`Sign in Webuser-Tenant ...`)
    const { data: token } = await firebase.post(`/auth/sign_in_webuser_tenant`)

    debug.log(`Sign in Firebase-Tenant with token ...`)
    await FirebaseAuthService.signInWithCustomToken(token.firebaseToken)

    debug.log(`Webuser switched Tenant!`)
    debug.timeEnd()

    return true
  }

  const switchTenant = async (tenantId) => {
    const { data: response } = await orca.post(`/rpc/webuser__switch_tenant`, {
      webuser_id_value: webuserId,
      tenant_id_value: tenantId,
    })

    return response
  }

  const resetPassword = async () => {
    await orca.post(
      `https://${process.env.REACT_APP__AUTH__AUTH0_DOMAIN}/dbconnections/change_password`,
      {
        client_id: process.env.REACT_APP__AUTH__AUTH0_CLIENT_ID,
        email: data.email_address?.email,
        connection: "Username-Password-Authentication",
      },
      { headers: { "content-type": "application/json" } }
    )
  }

  return {
    data: {
      ...data,
      auth0: user,
    },
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    update,
    signInTenant,
    switchTenant,
    resetPassword,
    logout,
  }
}

export { generateSWRCacheKey }

export default useWebuser
