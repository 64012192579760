import React from "react"

// styles
import styled from "@emotion/styled"

const Icon = styled.div`
  label: icon-number;
  border-radius: 100%;
  color: white;
  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.small};

  background-color: ${({ theme, disabled }) =>
    disabled ? theme.backgroundColorExtraLight : theme.themeColorDefault};
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Component = ({ count = 1, disabled }) => (
  <Icon className="icon-number" disabled={disabled}>
    {count}
  </Icon>
)

export default Component
