import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Menu as Component } from "antd"

const stylingMenu = (theme, props) => css`
  label: component-menu--styling;

  border: none !important;
  ${theme.round};
  ${theme.shadow};

  padding: 0;
  overflow: hidden;
  background: #ffffff;

  ${!props.shadow &&
  css`
    box-shadow: none;
  `}

  .ant-dropdown-menu-title-content {
    flex: inherit;
  }

  .ant-dropdown-menu-item {
    padding: 4px 12px !important;
    height: 30px !important;

    ${theme.font.typo.default};
    font-size: ${theme.font.size.default};
    color: ${theme.textColorDefaut};

    line-height: 150% !important;
    text-align: right;
    letter-spacing: 0.03em;

    &:nth-of-type(2n) {
      background: ${theme.backgroundColorExtraLighter};
    }

    .ant-dropdown-menu-item-icon {
      path {
        fill: ${theme.textColorDefaut};
      }
    }

    ${!props.hoverable &&
    css`
      background-color: transparent !important;
      &:hover {
        background: transparent;
      }
    `}

    &:hover {
      color: ${theme.textColorDefaut};
      background: ${theme.backgroundColorExtraLight};

      .ant-dropdown-menu-item-icon {
        path {
          fill: ${theme.textColorDefaut};
        }
      }
    }

    &.ant-menu-item-selected {
      ${theme.font.typo.bold600};
      color: ${theme.textColorDefault};
    }
  }
`

const Menu = ({ items, hoverable = true, shadow = true, ...rest }) => {
  return (
    <Component
      css={(theme) => stylingMenu(theme, { hoverable, shadow })}
      className="component-menu"
      items={items}
      {...rest}
    />
  )
}

export default Menu
