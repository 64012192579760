import React from "react"
import moment from "moment"

// errors
import { ErrorBoundary } from "react-error-boundary"

// components
import { ErrorFallback } from "components"

// components
import {
  Space,
  DatePicker,
  Select,
  Flex,
  Form,
  Divider,
  Button,
  Typo,
} from "components"

// styles
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"
import useCube from "hooks/useCube"

// context
import { DashboardProvider, useDashboard } from "store/dashboard"

// utils
import * as fn from "utils/functions"
import { department_types } from "utils/data-types"

// assets
import { ReactComponent as IconArrow } from "assets/arrows/arrow-thin.svg"

// logs
import debugModule from "utils/debug"
const debug = debugModule("<DashboardLayout/>")

const Widget = styled.div`
  label: component-widget;

  border: 1px dashed rgba(200, 200, 200, 0.275);
  /*  border-radius: 8px;*/
  overflow: hidden;

  height: 100%;
  min-height: ${({ minHeight = 250 }) => `${minHeight}px`};
  max-height: ${({ maxHeight = 500 }) => `${maxHeight}px`};
  width: 100%;

  overflow: auto;

  position: relative;

  padding: 16px;

  /*  box-shadow: 1px 9px 9px 1px rgba(230, 230, 230, 0.1);

  transition: box-shadow 300ms;

  &:hover {
    box-shadow: 1px 9px 9px 1px rgba(230, 230, 230, 0.2);
  }*/
`

const DateRange = styled.div`
  label: component-widget--date-range;

  svg path {
    fill: ${({ theme }) => theme.textColorExtraLight};
  }
`

const DashboardLayout = ({
  layout = [],
  filters = [],
  periodKeys = [
    "this-fyear",
    "last-fyear",
    "last-2-fyears",
    "last-3-fyears",
    "last-4-fyears",
    "this-and-last-fyear",
    "this-and-last-2-fyears",
    "this-and-last-3-fyears",
    "this-and-last-4-fyears",

    "this-year",
    "last-year",
    "this-and-last-year",
    "this-and-last-2-years",
    "this-and-last-3-years",
    "this-and-last-4-years",

    "this-quarter",
    "last-quarter",

    "this-month",
    "last-month",
    "last-12-months",
    "last-12-months-until-now",

    "this-week",
    "last-week",
    "next-week",
    "next-2-weeks",
    "next-3-weeks",
    "next-4-weeks",

    "today",
    "yesterday",
    "last-7-days",
    "last-30-days",
  ],
  periodKeyDefaultValue = "this-month",
  fiscalYearStartDateMonthKey,
  fiscalYearStartDateDayKey,
}) => {
  const { t } = useTranslate(["ui", "data"])
  const { config, setConfig, filter, removeFilter, setFilter } = useDashboard()
  const [timeRange, setTimeRange] = React.useState(periodKeyDefaultValue)
  const [edited, setEdited] = React.useState(false)
  const [datepickerValue, setDatepickerValue] = React.useState()
  const [showDatepicker, setShowDatepicker] = React.useState(false)

  //   const presets = React.useMemo(() => {
  //     const ranges = {}
  //
  //     periodKeys.forEach((key) => {
  //       const range = fn.getTimeRange(key, {fiscalYearStartDateMonthKey
  // ,fiscalYearStartDateDayKey})
  //       ranges[t(`data:dashboard-periods.types.${key}.label`)] = [
  //         moment(range.start_date_key),
  //         moment(range.end_date_key),
  //       ]
  //     })
  //
  //     return ranges
  // }, [periodKeys, t])

  return (
    <div>
      <Form layout="vertical">
        <Flex.Row gutter={16}>
          {false && (
            <Flex.Col span={4}>
              <Form.Item label="Period">
                <Select
                  block
                  allowClear={false}
                  value={timeRange}
                  items={periodKeys.map((key) => ({ id: key, value: key }))}
                  onChange={(value) => {
                    setTimeRange(value)
                    const range = fn.getTimeRange(value, {
                      fiscalYearStartDateMonthKey,
                      fiscalYearStartDateDayKey,
                    })

                    setConfig({
                      analysis_start_date_key: range.start_date_key,
                      analysis_end_date_key: range.end_date_key,
                    })
                  }}
                />
              </Form.Item>
            </Flex.Col>
          )}

          <Flex.Col span={10}>
            <Form.Item
              label={
                <Space>
                  <Typo.Text size="tall" font="bold600">
                    Période:{" "}
                  </Typo.Text>
                  <Typo.Text color="textColorLighter" italic>
                    {t(`data:dashboard-periods.types.${timeRange}.label`)}
                  </Typo.Text>
                </Space>
              }
            >
              <DatePicker.RangePicker
                block
                open={showDatepicker}
                onOpenChange={(value) => setShowDatepicker(value)}
                allowClear={false}
                value={datepickerValue}
                defaultValue={[
                  moment(config.analysis_start_date_key),
                  moment(config.analysis_end_date_key),
                ]}
                onChange={(values) => {
                  setTimeRange("custom")
                  setEdited(true)
                  setDatepickerValue(values)
                }}
                renderExtraFooter={() => (
                  <div
                    style={{
                      maxHeight: 300,
                      overflowY: "auto",
                      paddingBottom: 20,
                    }}
                  >
                    {["fyear", "year", "quarter", "month", "week", "day"].map(
                      (group) => (
                        <div key={group}>
                          <Divider orientation="left" orientationMargin="10px">
                            <Typo.Text font="bold600" underline>
                              {fn.capitalize(
                                t(`common:datetime.__.${group}.label`)
                              )}
                            </Typo.Text>
                          </Divider>
                          <Flex.Row gutter={[8, 0]}>
                            {periodKeys
                              .filter((p) =>
                                p.match(new RegExp(`[-ro]${group}[-s]?`))
                              )
                              .map((p) => {
                                const range = fn.getTimeRange(p, {
                                  fiscalYearStartDateMonthKey,
                                  fiscalYearStartDateDayKey,
                                })
                                return (
                                  <Flex.Col span={12} key={p}>
                                    <Button
                                      grey
                                      type="text"
                                      onClick={() => {
                                        setEdited(false)
                                        setShowDatepicker(false)
                                        setTimeRange(p)
                                        setDatepickerValue([
                                          moment(range.start_date_key),
                                          moment(range.end_date_key),
                                        ])

                                        setConfig({
                                          analysis_start_date_key: moment(
                                            range.start_date_key
                                          ).format("YYYY-MM-DD"),
                                          analysis_end_date_key: moment(
                                            range.end_date_key
                                          ).format("YYYY-MM-DD"),
                                        })
                                      }}
                                      label={
                                        <DateRange>
                                          <Typo.Text
                                            font="bold600"
                                            color="textColorLighter"
                                            size="small"
                                            block
                                          >
                                            {t(
                                              `data:dashboard-periods.types.${p}.label`
                                            )}
                                          </Typo.Text>

                                          <Typo.Text
                                            block
                                            italic
                                            color="textColorLighter"
                                            size="smaller"
                                          >
                                            <Space>
                                              {moment(
                                                range.start_date_key
                                              ).format("YYYY-MM-DD")}

                                              <IconArrow
                                                style={{
                                                  transform: "rotate(-90deg)",
                                                }}
                                              />

                                              {moment(
                                                range.end_date_key
                                              ).format("YYYY-MM-DD")}
                                            </Space>
                                          </Typo.Text>
                                        </DateRange>
                                      }
                                      size="small"
                                    />
                                  </Flex.Col>
                                )
                              })}
                          </Flex.Row>
                        </div>
                      )
                    )}
                  </div>
                )}
              />
            </Form.Item>
          </Flex.Col>

          {edited && (
            <Flex.Col>
              <Form.Item label={" "}>
                <Button
                  type="primary"
                  size="small"
                  label={t("ui:button.apply.label")}
                  onClick={() => {
                    const conf = {
                      analysis_start_date_key: moment(
                        datepickerValue[0]
                      ).format("YYYY-MM-DD"),
                      analysis_end_date_key: moment(datepickerValue[1]).format(
                        "YYYY-MM-DD"
                      ),
                    }
                    debug.log("set", { conf })

                    setEdited(false)
                    setConfig(conf)
                  }}
                />
              </Form.Item>
            </Flex.Col>
          )}
          {filters.map((f) => (
            <Flex.Col key={f.key}>
              <Form.Item key={f.key} label={f.label}>
                <f.form
                  removeFilter={removeFilter}
                  filter={filter}
                  setFilter={setFilter}
                />
              </Form.Item>
            </Flex.Col>
          ))}
        </Flex.Row>
      </Form>

      {false && process.env.NODE_ENV !== "production" && (
        <React.Fragment>
          <Divider />

          <pre style={{ fontSize: 8 }}>
            debug: {JSON.stringify({ config, filter }, null, 4)}
          </pre>
        </React.Fragment>
      )}

      <Flex.Row gutter={[0, 0]}>
        {React.useMemo(
          () =>
            layout.map((item) => (
              <ErrorBoundary
                key={item.key}
                FallbackComponent={(props) => (
                  <ErrorFallback
                    fullscreen
                    center
                    part={`widget:${item.key}`}
                    {...props}
                  />
                )}
                onReset={() => {
                  window.location.reload()
                }}
              >
                {item.type === "divider" ? (
                  <Flex.Col key={item.key} span={24}>
                    <Divider orientation="left" orientationMargin="0">
                      <Typo.Title level={3}>
                        <item.content maxHeight={item.maxHeight || 500} />
                      </Typo.Title>
                    </Divider>
                  </Flex.Col>
                ) : (
                  <Flex.Col key={item.key} span={item.span}>
                    <Widget
                      data-testid="component-dashboard-widget"
                      maxHeight={item.maxHeight}
                    >
                      <item.content maxHeight={item.maxHeight || 500} />
                    </Widget>
                  </Flex.Col>
                )}
              </ErrorBoundary>
            )),
          [layout]
        )}
      </Flex.Row>
    </div>
  )
}

const useFilters = (keys = []) => {
  const { t, translateList } = useTranslate(["ui", "data"])

  const ClinicList = useCube(
    {
      dimensions: ["Clinic.clinic_key", "Clinic.name"],
    },
    {
      skip: !keys.includes("clinic_key"),
    }
  )

  const DentistGroupList = useCube(
    {
      dimensions: ["DentistGroup.dentist_group_key", "DentistGroup.label"],
    },
    {
      skip: !keys.includes("dentist_group_key"),
    }
  )

  const filters = React.useMemo(() => {
    const list = []

    if (keys.includes("clinic_key"))
      list.push({
        key: "clinic_key",
        label: "Clinique",
        form: ({ filter, removeFilter, setFilter }) => {
          return (
            <Select
              style={{ minWidth: 200 }}
              block
              mode="multiple"
              placeholder={t("ui:select.placeholder")}
              value={filter.find((f) => f.member === "clinic_key")?.values}
              onChange={(values) => {
                if (values.length === 0) {
                  removeFilter("clinic_key")
                } else {
                  setFilter({
                    member: "clinic_key",
                    operator: "equals",
                    values,
                  })
                }
              }}
              items={
                !ClinicList.resultSet
                  ? []
                  : ClinicList.resultSet.tablePivot().map((clinic) => ({
                      id: clinic["Clinic.clinic_key"],
                      key: clinic["Clinic.clinic_key"],
                      label: clinic["Clinic.name"],
                    }))
              }
            />
          )
        },
      })

    if (keys.includes("department_key"))
      list.push({
        key: "department_key",
        label: "Département",
        form: ({ filter, removeFilter, setFilter }) => (
          <Select
            style={{ minWidth: 200 }}
            block
            mode="multiple"
            placeholder={t("ui:select.placeholder")}
            value={filter.find((f) => f.member === "department_key")?.values}
            onChange={(values) => {
              if (values.length === 0) {
                removeFilter("department_key")
              } else {
                setFilter({
                  member: "department_key",
                  operator: "equals",
                  values,
                })
              }
            }}
            items={translateList(department_types, "department")}
          />
        ),
      })

    if (keys.includes("dentist_group_key"))
      list.push({
        key: "dentist_group_key",
        label: "Dentiste",
        form: ({ filter, removeFilter, setFilter }) => (
          <Select
            style={{ minWidth: 200 }}
            block
            mode="multiple"
            placeholder={t("ui:select.placeholder")}
            value={filter.find((f) => f.member === "dentist_group_key")?.values}
            onChange={(values) => {
              if (values.length === 0) {
                removeFilter("dentist_group_key")
              } else {
                setFilter({
                  member: "dentist_group_key",
                  operator: "equals",
                  values,
                })
              }
            }}
            items={
              !DentistGroupList.resultSet
                ? []
                : DentistGroupList.resultSet
                    .tablePivot()
                    .map((dentistGroup) => ({
                      id: dentistGroup["DentistGroup.dentist_group_key"],
                      key: dentistGroup["DentistGroup.dentist_group_key"],
                      label: dentistGroup["DentistGroup.label"],
                    }))
            }
          />
        ),
      })

    return list
  }, [t, keys, translateList, DentistGroupList, ClinicList])

  return filters
}

const DashboardLayoutWrapper = (props) => (
  <ErrorBoundary
    FallbackComponent={(p) => (
      <ErrorFallback center part="ccg-dashboard-layout" {...p} />
    )}
    onReset={() => {
      window.location.reload()
    }}
  >
    <DashboardProvider
      periodKeyDefaultValue={props.periodKeyDefaultValue}
      fiscalYearStartDateMonthKey={props.fiscalYearStartDateMonthKey}
      fiscalYearStartDateDayKey={props.fiscalYearStartDateDayKey}
    >
      <DashboardLayout {...props} />
    </DashboardProvider>
  </ErrorBoundary>
)

DashboardLayoutWrapper.useFilters = useFilters

export default DashboardLayoutWrapper
