import React from "react"

// styles
import styled from "@emotion/styled"

const Container = styled.div`
  label: component-objective-card-container;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const ObjectiveGrid = ({ children }) => {
  return <Container>{children}</Container>
}

export default ObjectiveGrid
