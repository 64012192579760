import Holidays from "date-holidays"

const holidays = new Holidays("CA", "QC")

export const isHoliday = (year, date) => {
  const holidayList = holidays
    .getHolidays(year)
    .filter((item) => item.type === "public")
    .map((item) => item.date.split(" ")[0])

  return holidayList.includes(date)
}
