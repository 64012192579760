export const parseTimeToNumber = (value, timeFormat) => {
  if (!value.includes(timeFormat))
    throw new Error(
      `The timeformat seperator << ${timeFormat} >> is not included in the input value!`
    )

  if (timeFormat === ":") {
    const time = value.split(":")
    const minutes = time[1] !== "" ? time[1] : 0
    const hours = time[0] !== "" ? time[0] : 0
    return Number(hours) * 60 + (Number(minutes) % 60)
  } else {
    const time = value.split(".")
    const minutes = time[1] !== "" ? time[1] : 0
    const hours = time[0] !== "" ? time[0] : 0
    return Math.round(Number(hours) * 60 + 60 * ((Number(minutes) % 100) / 100))
  }
}
