export const getUniqueLabel = (item, key, { fallback }) => {
  if (!item) return fallback || "-"

  const name =
    item.family_name || item.given_name
      ? `${item.family_name} ${item.given_name}`
      : fallback

  const label = item[key]
  return label ?? name.trim()
}
