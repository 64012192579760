import React from "react"

// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-spacer;
  margin: ${({ margin }) => (margin ? margin : "16px 0px")};
`

const Spacer = ({ margin, ...rest }) => {
  return <Component margin={margin} />
}

const SeperatorPipe = styled.span`
  label: component-pipe;
  margin: 0 ${({ margin = "8px" }) => margin};

  ${({ theme, font = "default" }) => theme.font.typo[font]};

  font-size: ${({ theme, size = "default" }) => theme.font.size[size]};
  color: ${({ theme, color = "textColorDefault" }) => theme[color] || color};
`

const ComponentPipe = ({ ...rest }) => (
  <SeperatorPipe className="component-pipe" {...rest}>
    |
  </SeperatorPipe>
)

Spacer.Pipe = ComponentPipe

export default Spacer
