import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Modal as Component } from "antd"

// assets
import { ReactComponent as Close } from "assets/icons/icon-close.svg"

const styling = (theme) => css`
  label: component-modal--styling;

  .ant-modal-content {
    ${theme.round}

    .ant-modal-body {
      margin-bottom: 100px;
    }
  }
`

const Modal = ({ children, ...rest }) => {
  return (
    <Component
      css={styling}
      className="component-modal"
      closeIcon={<Close />}
      {...rest}
    >
      {children}
    </Component>
  )
}

Modal.confirm = ({ ...args }) =>
  Component.confirm({
    ...args,
    okButtonProps: { className: "okay" },
    cancelButtonProps: { className: "cancel" },
  })

export default Modal
