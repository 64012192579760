// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 7,
  height = 6,
  color = "themeColorDefault",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7 6"
      {...props}
    >
      <circle cx="3.5" cy="3" r="2.5" stroke={theme[color]} fill="none" />
    </svg>
  )
}
