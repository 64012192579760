import React from "react"

// styles
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"

// components
import {
  Select,
  Flex,
  Space,
  Spacer,
  Button,
  Typo,
  Checkbox,
  Line,
  Input,
  Tag,
  Dropdown,
  Menu,
  Badge,
  Divider,
} from "components"
import Orders from "./Orders"
import Filters from "./Filters"
import { LockOutlined, UnlockOutlined } from "@ant-design/icons"

// utils
import { omit, uniq } from "lodash"
import renderColTypeIcon from "../utils/renderColTypeIcon"

// assets
import { ReactComponent as IconGroup } from "assets/icons/icon-group.svg"
import { ReactComponent as IconBin } from "assets/icons/icon-bin.svg"
import { ReactComponent as IconPencil } from "assets/icons/icon-pencil.svg"
import { ReactComponent as IconDots } from "assets/icons/icon-dots.svg"
import { ReactComponent as IconLupe } from "assets/icons/icon-lupe-simple.svg"
import { ReactComponent as IconView } from "assets/icons/icon-view.svg"
import { ReactComponent as IconArrowOrder } from "assets/arrows/arrow-order.svg"

import { ReactComponent as IconDuplicate } from "assets/icons/icon-duplicate.svg"

const Container = styled.div`
  label: component-data-table--controls;
  width: 100%;
`

const PopupContainer = styled.div`
  label: component-data-table--popup;
  background-color: white;

  ${({ theme }) => theme.round};
  ${({ theme }) => theme.shadow};
`

const PopupContent = styled.div`
  label: component-data-table--popup-content;
  padding: 8px;
  width: ${({ width }) => width}px;

  overflow-y: auto;
  max-height: 80vh;
`

const Controls = ({
  lock = false,
  pageSize,
  selectedPages,
  pagination,
  countSelectedItems,
  countTotalItems,

  searchable = false,
  selectable = false,

  onSearch = () => null,

  extraControls = [],

  viewEdited = false,
  schema = [],
  views = [],
  currentView = "default",
  filters,
  orders = [],

  onAddView = () => null,
  onDeleteView = () => null,
  onSetView = () => null,
  onChangeView = () => null,
  onResetView = () => null,

  onSelect = () => null,

  onAddOrder = () => null,
  onDeleteOrder = () => null,
  onChangeOrder = () => null,
  onCreateOrder = () => null,
  onRemoveOrders = () => null,
  onMoveUpOrder = () => null,
  onMoveDownOrder = () => null,

  onAddFilter = () => null,
  onAddFilterGroup = () => null,
  onChangeFilter = () => null,
  onDuplicateFilter = () => null,
  onDeleteFilter = () => null,
  onChangeGroupType = () => null,
  onCreateFilter = () => null,
  onRemoveFilters = () => null,
}) => {
  const { t } = useTranslate(["ui"])
  const [showInput, setShowInput] = React.useState(false)
  const view = React.useMemo(
    () => views.find((v) => v.key === currentView) || {},
    [views, currentView]
  )

  const isPreset = React.useMemo(() => view.type === "preset", [view])

  const [title, setTitle] = React.useState(view.name)
  const [status, setStatus] = React.useState("idle")

  const [editedConfigurations, setEditedConfigurations] = React.useState([])

  React.useEffect(() => {
    setTitle(view.name)
  }, [view])

  const listFilters = (input) => {
    if (!input) return []

    if (!input.or && !input.and) return input

    return [
      ...(input.or ? input.or.map((i) => listFilters(i)) : []),
      ...(input.and ? input.and.map((i) => listFilters(i)) : []),
    ]
      .flat()
      .filter((f) => f.field && f.operator)
  }

  return (
    <Container data-testid="datatable-controls">
      <Flex.Row fullWidth justify="end" gutter={0} space="8px">
        {extraControls.map((item) => (
          <Flex.Col key={item.id}>{item.content}</Flex.Col>
        ))}
      </Flex.Row>

      <Flex.Row justify="space-between" gutter={8}>
        <Flex.Col>
          {showInput && (
            <Space>
              <Input
                size="smaller"
                data-testid="datatable-controls-name-input"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                defaultValue={title}
                placeholder="Titre"
              />
              <Button
                data-testid="datatable-controls-save-name-button"
                loading={status === "save-view"}
                size="small"
                type="primary"
                label={t("ui:button.save.label")}
                onClick={async () => {
                  setStatus("save-view")
                  await onChangeView(currentView, {
                    name: title,
                    locked: false,
                    config: {
                      pageSize,
                      filter: filters,
                      order: orders,
                    },
                  })
                  setStatus("idle")
                  setShowInput(false)
                }}
              />
            </Space>
          )}

          {!lock && !showInput && (
            <Select
              data-testid="datatable-controls-view-select"
              block
              size="smaller"
              value={currentView}
              defaultValue={currentView}
              allowClear={false}
              items={[
                {
                  label: t("ui:datatable.views.preset.label"),
                  id: "standard",
                  children: views
                    .filter((v) => v.type === "preset")
                    .map((v) => ({
                      id: v.key,
                      value: v.key,
                      label: (
                        <Space>
                          <IconView />
                          <Typo.Text font="bold700">{v.name}</Typo.Text>
                        </Space>
                      ),
                    })),
                },

                ...(views.filter((v) => v.type === "custom").length > 0
                  ? [
                      {
                        label: t("ui:datatable.views.custom.label"),
                        id: "custom",
                        children: views
                          .filter((v) => v.type === "custom")
                          .map((v) => ({
                            id: v.key,
                            value: v.key,
                            label: (
                              <Space>
                                <IconView />
                                <Typo.Text font="bold700">{v.name}</Typo.Text>
                              </Space>
                            ),
                          })),
                      },
                    ]
                  : []),
              ]}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      data-testid="datatable-controls-view-add-view"
                      loading={status === "add-view"}
                      grey
                      type="text"
                      label={t("ui:datatable.buttons.add-view.label")}
                      onClick={async () => {
                        setStatus("add-view")
                        await onAddView({
                          key: new Date().getTime(),
                          name: "Sans titre",
                          locked: false,
                          config: {
                            pageSize: 10,
                            filter: null,
                            order: [],
                          },
                        })
                        setStatus("idle")
                        setShowInput(true)
                        setEditedConfigurations([])
                      }}
                    />
                  </Space>
                </>
              )}
              // preIcon={<IconView />}
              onChange={(value) => onSetView(value)}
            />
          )}
        </Flex.Col>

        <Flex.Col flex={1} style={{ display: "flex" }}>
          {!showInput && currentView !== "default" && (
            <Dropdown.Button
              icon={
                <IconDots
                  data-testid="datatable-controls-edit-view-button"
                  width="16px"
                />
              }
              type="text"
              size="small"
              trigger="click"
              arrow={false}
              overlay={
                <Menu
                  onClick={async ({ key }) => {
                    switch (key) {
                      case "rename":
                        setShowInput(true)
                        break

                      case "duplicate": {
                        setStatus("duplicate-view")

                        await onAddView({
                          ...view,
                          key: new Date().getTime(),
                        })
                        setShowInput(true)
                        setEditedConfigurations([])
                        setStatus("idle")
                        break
                      }

                      case "delete": {
                        setStatus("delete-view")
                        await onDeleteView(currentView)
                        setEditedConfigurations([])
                        setStatus("idle")
                        break
                      }

                      default:
                        break
                    }
                  }}
                  items={[
                    ...(view.type === "custom" && !view.locked
                      ? [
                          {
                            key: "rename",
                            icon: <IconPencil width="16px" />,
                            label: t("ui:datatable.buttons.rename.label"),
                          },
                        ]
                      : []),
                    {
                      key: "duplicate",

                      icon: <IconDuplicate width="16px" />,
                      label: t("ui:datatable.buttons.duplicate.label"),
                    },
                    ...(view.type === "custom" && !view.locked
                      ? [
                          {
                            key: "delete",

                            icon: <IconBin width="16px" height="16px" />,
                            label: t("ui:datatable.buttons.delete.label"),
                          },
                        ]
                      : []),
                  ]}
                />
              }
            />
          )}
        </Flex.Col>
        {searchable && (
          <Flex.Col>
            <Space>
              {false && <UnlockOutlined />}
              {view.locked && <LockOutlined />}

              <Input
                size="smaller"
                data-testid="datatable-controls-search-input"
                prefix={<IconLupe />}
                placeholder={t("ui:input.types.search.placeholder")}
                onChange={(e) => {
                  onSearch(e.target.value)
                }}
              />
            </Space>
          </Flex.Col>
        )}
      </Flex.Row>

      {!(lock && !searchable) && <Line light />}

      <Flex.Row gutter={8}>
        <Flex.Col span={17}>
          <div style={{ paddingLeft: 8, paddingBottom: 8 }}>
            {selectable && (
              <Space>
                <Dropdown
                  trigger="hover"
                  overlay={
                    <Menu
                      onClick={({ key }) => {
                        switch (key) {
                          case "all-in-view": {
                            onSelect("all-in-view")
                            break
                          }
                          case "all": {
                            onSelect("all")
                            break
                          }
                          case "none": {
                            onSelect("none")
                            break
                          }
                          default:
                            break
                        }
                      }}
                      items={[
                        {
                          key: "all-in-view",
                          label: t("ui:datatable.select.all-in-view.label"),
                        },
                        {
                          key: "all",
                          label: t("ui:datatable.select.all.label"),
                        },
                        {
                          key: "none",
                          label: t("ui:datatable.select.none.label"),
                        },
                      ]}
                    />
                  }
                >
                  <Checkbox
                    data-testid="datatable-controls-checkbox"
                    indeterminate={
                      countSelectedItems !== 0 &&
                      countSelectedItems !== countTotalItems
                    }
                    checked={
                      countTotalItems !== 0 &&
                      countSelectedItems === countTotalItems
                    }
                    onChange={(e) => {
                      return e.target.checked
                        ? onSelect("all")
                        : onSelect("none")
                    }}
                  />
                </Dropdown>

                <Typo.Text
                  data-testid="datatable-controls-line-count-text"
                  color="textColorLighter"
                  size="smaller"
                  italic
                  margin="0 8px 0 0"
                >
                  {pagination
                    ? t("ui:datatable.pagination.lines.label", {
                        context: "pages",
                        countSelectedItems,
                        countPages: uniq(
                          selectedPages.map((page) =>
                            Math.ceil((page + 1) / pageSize)
                          )
                        ).length,
                        countTotalItems,
                      })
                    : t("ui:datatable.pagination.lines.label", {
                        countSelectedItems,
                        countTotalItems,
                      })}
                </Typo.Text>
              </Space>
            )}

            {(!lock || orders.length > 0) && selectable && (
              <Spacer.Pipe color="textColorLighter" />
            )}

            {orders
              .filter((o) => o.field && o.direction)
              .map((o) => (
                <Badge
                  key={o.id}
                  dot={editedConfigurations.includes(o.id)}
                  offset={[-5, 3]}
                >
                  <Tag
                    icon={
                      o.direction === "asc" ? (
                        <IconArrowOrder
                          style={{ transform: "rotate(-180deg)" }}
                        />
                      ) : (
                        <IconArrowOrder />
                      )
                    }
                    round
                    small
                    borderColor={
                      lock && isPreset
                        ? "borderColorDefault"
                        : "themeColorDefault"
                    }
                    textColor={
                      lock && isPreset
                        ? "borderColorDefault"
                        : "themeColorDefault"
                    }
                    color={
                      lock && isPreset
                        ? "borderColorLighter"
                        : "themeColorExtraLighter"
                    }
                    label={
                      <Typo.Text
                        color={
                          lock && isPreset
                            ? "textColorLighter"
                            : "themeColorDefault"
                        }
                        font="bold600"
                        size="smaller"
                      >
                        {schema.find((col) => col.key === o.field)?.title}
                      </Typo.Text>
                    }
                  />
                </Badge>
              ))}

            {!lock && !view.locked && (
              <Dropdown.Button
                data-testid="datatable-controls-add-order-dropdown"
                autoClose={false}
                type="text"
                size="small"
                grey
                label={t("ui:datatable.buttons.order.label")}
                trigger="click"
                placement="bottomLeft"
                overlay={
                  orders.length === 0 ? (
                    <Menu
                      onClick={({ key }) => {
                        const newId = new Date().getTime()

                        setEditedConfigurations((prev) =>
                          uniq([...prev, newId])
                        )
                        onCreateOrder(newId, key)
                      }}
                      items={schema.map((col) => ({
                        key: col.key,
                        icon: renderColTypeIcon(col.type),
                        label: col.title,
                      }))}
                    />
                  ) : (
                    <PopupContainer>
                      <PopupContent width={500}>
                        <Orders
                          schema={schema}
                          orders={orders}
                          onChangeOrder={(orderId, values) => {
                            onChangeOrder(orderId, values)
                            setEditedConfigurations((prev) =>
                              uniq([...prev, orderId])
                            )
                          }}
                          onDeleteOrder={(orderId) => onDeleteOrder(orderId)}
                          onMoveUp={(orderId, orderIndex) => {
                            onMoveUpOrder(orderIndex)
                            setEditedConfigurations((prev) =>
                              uniq([...prev, orderId])
                            )
                          }}
                          onMoveDown={(orderId, orderIndex) => {
                            onMoveDownOrder(orderIndex)
                            setEditedConfigurations((prev) =>
                              uniq([...prev, orderId])
                            )
                          }}
                        />

                        {orders.length !== schema.length && (
                          <Button
                            onClick={onAddOrder}
                            size="small"
                            type="text"
                            grey
                            label={t("ui:datatable.buttons.add-order.label")}
                          />
                        )}

                        <Line />

                        <Button
                          onClick={onRemoveOrders}
                          type="text"
                          grey
                          label={
                            <Typo.Text underline>
                              {t("ui:datatable.buttons.delete-order.label")}
                            </Typo.Text>
                          }
                        />
                      </PopupContent>
                    </PopupContainer>
                  )
                }
              />
            )}

            {(!lock || listFilters(filters).length > 0) && (
              <Spacer.Pipe color="textColorLighter" />
            )}

            {listFilters(filters).length > 1 && (
              <Badge
                dot={editedConfigurations.some((filterId) =>
                  listFilters(filters).find((f) => f.id === filterId)
                )}
                offset={[-5, 3]}
              >
                <Tag
                  icon={<IconGroup />}
                  round
                  small
                  borderColor={
                    lock && isPreset
                      ? "borderColorDefault"
                      : "themeColorDefault"
                  }
                  textColor={
                    lock && isPreset
                      ? "borderColorDefault"
                      : "themeColorDefault"
                  }
                  color={
                    lock && isPreset
                      ? "borderColorLighter"
                      : "themeColorExtraLighter"
                  }
                  label={
                    <Typo.Text
                      color="themeColorDefault"
                      font="bold600"
                      size="smaller"
                    >
                      {listFilters(filters).length} rules
                    </Typo.Text>
                  }
                />
              </Badge>
            )}

            {listFilters(filters).length === 1 &&
              listFilters(filters).map((f, index) => (
                <Badge
                  dot={editedConfigurations.includes(f.id)}
                  key={f.id || index}
                  offset={[-5, 3]}
                >
                  <Tag
                    icon={renderColTypeIcon(
                      schema.find((col) => col.key === f.field)?.type
                    )}
                    round
                    small
                    borderColor={
                      lock && isPreset
                        ? "borderColorDefault"
                        : "themeColorDefault"
                    }
                    textColor={
                      lock && isPreset
                        ? "borderColorDefault"
                        : "themeColorDefault"
                    }
                    color={
                      lock && isPreset
                        ? "borderColorLighter"
                        : "themeColorExtraLighter"
                    }
                    label={
                      <Space>
                        <Typo.Text
                          color="themeColorDefault"
                          font="bold800"
                          size="smaller"
                        >
                          {schema.find((col) => col.key === f.field)?.title}
                        </Typo.Text>
                        <Typo.Text
                          color="themeColorDefault"
                          font="bold600"
                          size="smaller"
                          italic
                        >
                          {t(`ui:datatable.filters.types.${f.operator}.label`)}
                        </Typo.Text>
                        <Typo.Text
                          color="themeColorDefault"
                          font="bold600"
                          size="smaller"
                        >
                          {f.value}
                        </Typo.Text>
                      </Space>
                    }
                  />
                </Badge>
              ))}

            {!lock && !view.locked && (
              <Dropdown.Button
                data-testid="datatable-controls-add-filter-dropdown"
                autoClose={false}
                type="text"
                size="small"
                grey
                label={t("ui:datatable.buttons.filter.label")}
                trigger="click"
                placement="bottomLeft"
                overlay={
                  !filters ? (
                    <Menu
                      onClick={({ key }) => {
                        const newId = new Date().getTime()
                        setEditedConfigurations((prev) =>
                          uniq([...prev, newId])
                        )
                        onCreateFilter(newId, key)
                      }}
                      items={schema.map((col) => ({
                        icon: renderColTypeIcon(col.type),
                        key: col.key,
                        label: col.title,
                      }))}
                    />
                  ) : (
                    <PopupContainer>
                      <PopupContent width={600}>
                        <Filters
                          schema={schema}
                          path={[Object.entries(omit(filters, "id"))[0][0]]}
                          type={Object.entries(omit(filters, "id"))[0][0]}
                          items={Object.entries(omit(filters, "id"))[0][1]}
                          onAddRule={(id, path) => {
                            setEditedConfigurations((prev) =>
                              uniq([...prev, id])
                            )

                            onAddFilter(id, path)
                          }}
                          onAddGroup={(id, path) => {
                            setEditedConfigurations((prev) =>
                              uniq([...prev, id, id - 1])
                            )
                            onAddFilterGroup(id, path)
                          }}
                          onDuplicateFilter={(id, path, index) => {
                            setEditedConfigurations((prev) =>
                              uniq([...prev, id])
                            )
                            onDuplicateFilter(id, path, index)
                          }}
                          onDeleteFilter={onDeleteFilter}
                          onChangeGroupType={onChangeGroupType}
                          onChangeFilter={(path, item) => {
                            onChangeFilter(path, item)

                            setEditedConfigurations((prev) =>
                              uniq([...prev, item.id])
                            )
                          }}
                        />

                        <Line />

                        <Button
                          onClick={onRemoveFilters}
                          type="text"
                          grey
                          label={
                            <Typo.Text underline>
                              {t("ui:datatable.buttons.delete-filter.label")}{" "}
                            </Typo.Text>
                          }
                        />
                      </PopupContent>
                    </PopupContainer>
                  )
                }
              />
            )}
          </div>
        </Flex.Col>

        <Flex.Col
          span={7}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          {viewEdited && (
            <Space>
              <Button
                size="small"
                grey
                type="link"
                label={t("ui:button.reset.label")}
                onClick={() => {
                  onResetView()
                  setEditedConfigurations([])
                }}
              />

              {view.type === "preset" ? (
                <Button
                  loading={status === "save-as-new-view"}
                  size="small"
                  type="secondary"
                  label={t("ui:datatable.buttons.add-new-view.label")}
                  onClick={async () => {
                    setStatus("save-as-new-view")

                    await onAddView({
                      key: new Date().getTime(),
                      name: "Sans titre",
                      locked: false,
                      config: {
                        pageSize,
                        filter: filters,
                        order: orders,
                      },
                    })
                    setShowInput(true)
                    setStatus("idle")
                    setEditedConfigurations([])
                  }}
                />
              ) : (
                <Dropdown.Button
                  arrow
                  loading={status === "save-view"}
                  size="small"
                  type="secondary"
                  warning
                  trigger="click"
                  label={t("ui:datatable.buttons.save-for-all.label")}
                  overlay={
                    <Menu
                      onClick={async ({ key }) => {
                        switch (key) {
                          case "save-for-all": {
                            setStatus("save-view")
                            await onChangeView(currentView, {
                              config: {
                                pageSize,
                                filter: filters,
                                order: orders,
                              },
                            })
                            setStatus("idle")
                            setEditedConfigurations([])
                            break
                          }

                          case "save-as-new": {
                            setStatus("save-as-new-view")

                            await onAddView({
                              key: new Date().getTime(),
                              name: "Sans titre",
                              locked: false,
                              config: {
                                pageSize,
                                filter: filters,
                                order: orders,
                              },
                            })
                            setShowInput(true)
                            setStatus("idle")
                            setEditedConfigurations([])

                            break
                          }

                          default:
                            break
                        }
                      }}
                      items={[
                        {
                          key: "save-for-all",
                          label: t("ui:datatable.buttons.save-for-all.label"),
                        },
                        {
                          key: "save-as-new",
                          label: t("ui:datatable.buttons.save-as-new.label"),
                        },
                      ]}
                    />
                  }
                />
              )}
            </Space>
          )}
        </Flex.Col>
      </Flex.Row>
    </Container>
  )
}

export default Controls
