// styles
import styled from "@emotion/styled"

const Component = styled.hr`
  border: none;
  margin: ${({ margin = "8px 0" }) => margin};
  height: ${({ height = "1px" }) => height};
  background-color: ${({ theme, dark, light }) =>
    dark
      ? theme.borderColorDark
      : light
      ? theme.borderColorLight
      : theme.borderColorLighter};
`

export default Component
