import React from "react"

// moment
import moment from "moment"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// components
import { Input, Tooltip, Button } from "components"

// translation
import useTranslate from "hooks/useTranslate"

// utils
import * as fn from "utils/functions"

// assets
import { ReactComponent as Star } from "assets/icons/icon-star.svg"
import { ReactComponent as Close } from "assets/icons/icon-close.svg"
import { ReactComponent as IconDots } from "assets/icons/icon-dots.svg"

const Component = styled.div`
  label: component-row-input;

  min-width: 1400px;

  display: flex;

  ${({ showLabel, labelPosition, theme }) =>
    showLabel
      ? css`
          align-items: ${labelPosition === "top"
            ? "flex-end"
            : labelPosition === "bottom"
            ? "flex-start"
            : "center"};
        `
      : null}

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const ReferrerLabel = styled.div`
  label: component-row-input--label;

  display: flex;
  align-items: center;

  width: 200px;
  margin-right: 8px;

  cursor: pointer;

  .icon-close {
    display: none;
  }

  ${({ selected, theme }) =>
    selected
      ? css`
          .icon-star {
            transform: scale(1.1);
            path {
              fill: ${theme.themeColorDefault};
            }
          }

          &:hover {
            .icon-close {
              display: block;
            }
            .icon-star {
              display: none;
            }
          }
        `
      : css`
          &:hover {
            .icon-star {
              transform: scale(1.1);
              path {
                fill: rgb(190, 195, 207);
              }
            }
          }
        `}
`

const ReferrerIcon = styled.div`
  label: component-row-input--label-icon;

  svg {
    transition: transform 300ms;
  }

  .icon-close {
    margin-left: 2px;
    margin-right: 3px;

    transform: scale(1);
    path {
      fill: ${({ theme }) => theme.backgroundColorNegativeDark};
    }
  }
`

const ReferrerLabelBox = styled.div`
  label: component-row-input--label-box;
  margin-left: 8px;
`

const ReferrerLabelName = styled.div`
  label: component-row-input--label-name;
  ${({ theme }) => theme.font.typo.bold700};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 17px;
  letter-spacing: 0.01em;

  width: 160px;

  overflow: hidden;
  text-overflow: ellipsis;
`

const ReferrerLabelInfo = styled.div`
  label: component-row-input--label-info;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.textColorLighter};

  line-height: 17px;
  letter-spacing: 0.01em;
  height: 22px;
`

const ReferrerInput = styled.div`
  label: component-row-input--input;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin-right: 6px;
  padding: 2px;

  width: ${({ width = 70 }) =>
    typeof width === "number" ? `${width}px` : width};
  overflow: hidden;

  &:last-of-type {
    width: ${({ width = 70 }) =>
      typeof width === "number" ? `${width * 1.5}px` : `calc(${width} * 1.5)`};
  }

  opacity: ${({ disabled = false }) => (disabled ? "0.4" : "1")};

  .component-input-number {
    width: 100%;

    background: ${({ theme }) => theme.backgroundColorExtraLight};
    border: none;
    box-sizing: border-box;
    border-radius: 4px;

    height: 35px;
    padding: 5px 8px;

    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.backgroundColorLighter};
    }

    &:focus {
      cursor: text;
      background: ${({ theme }) => theme.backgroundColorExtraLight};
    }

    input {
      ${({ theme }) => theme.font.typo.bold600};
      font-size: ${({ theme }) => theme.font.size.default};
      color: ${({ theme }) => theme.textColorDefault};

      height: 100%;

      padding: 0;
      letter-spacing: -0.02em;
      line-height: 110%;

      cursor: pointer;
      text-align: center;

      &:focus {
        cursor: text;
      }
    }

    .ant-input-number-input-wrap {
      height: 100%;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }
  }
`

const InputItem = styled.div`
  label: component-row-input--input-item;
  position: relative;
  padding-bottom: 6px;

  .component-tooltip {
    visibility: hidden;
    position: absolute;
    right: 0;
    bottom: -8px;

    .dots {
      padding: 4px;
      cursor: pointer;
    }
  }

  &:hover .component-tooltip,
  .ant-input-number-focused + .component-tooltip {
    visibility: visible;
  }

  svg {
    transition: transform 200ms;

    &:hover {
      transform: scale(1.2);
    }
  }
`

const InputValue = styled.div`
  label: component-row-input--input-value;
  padding: 4px 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: default;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 110%;
  letter-spacing: 0.02em;
  width: 100%;
  height: 35px;

  ${({ showTotalbadges, total, theme }) =>
    showTotalbadges
      ? css`
          background: ${total
            ? theme.backgroundColorDark
            : theme.backgroundColorLight};
          color: ${total ? theme.textColorWhite : theme.textColorDefault};
          border: none;
          box-sizing: border-box;
          border-radius: 4px;

          height: 35px;
          padding: 5px 8px;
        `
      : null};
`

const InputLabel = styled.div`
  label: component-row-input--input-label;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.smaller};
  color: ${({ theme, bold }) =>
    bold ? theme.textColorDefault : theme.textColorLighter};

  line-height: 110%;
  letter-spacing: 0.03em;
  text-align: center;

  margin-bottom: 4px;
  margin-top: 4px;
`

const Label = ({ label, name, count, selected, onSelect }) => {
  const { t } = useTranslate(["generic"])
  return (
    <ReferrerLabel selected={selected} onClick={onSelect}>
      {onSelect && (
        <ReferrerIcon selected={selected}>
          <Close className="icon-close" />
          <Star className="icon-star" />
        </ReferrerIcon>
      )}

      <ReferrerLabelBox>
        <ReferrerLabelName>{label ?? name}</ReferrerLabelName>
        <ReferrerLabelInfo>
          {!label
            ? t("generic:lifetime-patient-referral-count.label", {
                number: count,
              })
            : null}
        </ReferrerLabelInfo>
      </ReferrerLabelBox>
    </ReferrerLabel>
  )
}

const CustomTooltipContent = styled.div`
  label: component-row-input--custom-tooltip-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin-bottom: 8px;
  }
`

const InputField = ({
  width,
  disabled,
  label,
  value,
  onChange,
  showLabel,
  labelPosition,
  showTotalbadges,
  total,
  onAction,
}) => {
  const { t } = useTranslate(["ui"])
  const [state, setState] = React.useState(false)

  return (
    <ReferrerInput disabled={disabled} width={width}>
      {showLabel && labelPosition === "top" && <InputLabel>{label}</InputLabel>}
      {onChange ? (
        <InputItem>
          <Input.Number
            disabled={disabled}
            onFocus={(e) => e.target.select()}
            min={0}
            value={value}
            onChange={onChange}
            formatter={(value) => {
              const number = Number(value !== "" ? value : undefined)
              const formatted = fn.renderAnalyticValue(number, {
                unit: null,
                alwaysShowUnits: true,
                config: {
                  useGrouping: true,
                },
              })
              return formatted
            }}
            parser={(value) => {
              const sanitized = value
                .replace(/\./g, "")
                .replace(/,/g, "")
                .replace(/ /g, "")
                .replace(/–/g, "")
              return sanitized
            }}
          />
          {!disabled && (
            <Tooltip
              visible={state}
              onVisibleChange={(e) => setState(e)}
              placement="bottom"
              trigger="click"
              title={
                <CustomTooltipContent>
                  <p>{t("ui:dialog.types.fill-right.text")}</p>
                  <Button
                    label={t("ui:button.apply.label")}
                    type="primary"
                    onClick={() => {
                      setState(false)
                      onAction()
                    }}
                  />
                </CustomTooltipContent>
              }
            >
              <span className="dots">
                <IconDots />
              </span>
            </Tooltip>
          )}
        </InputItem>
      ) : (
        <InputValue showTotalbadges={showTotalbadges} total={total}>
          {fn.renderAnalyticValue(value, {
            unit: "currency",
            config: {
              useGrouping: true,
            },
          })}
        </InputValue>
      )}
      {showLabel && labelPosition === "bottom" && (
        <InputLabel bold>{label}</InputLabel>
      )}
    </ReferrerInput>
  )
}

const RowInput = ({
  label,
  disabled,
  startKey = 1,
  data,
  hideBackground = true,
  total,
  inputWidth = 70,
  values = [],
  valueKeyName = "date_month_key",
  valuePropName = "budget_amount",
  onChange,
  onSelect,
  showLabel = false,
  labelPosition = "top",
  labelSuffix = () => "",
  showTotalbadges = false,
  showTotal = true,
}) => {
  const { i18n } = useTranslate()

  return (
    <Component showLabel={showLabel} labelPosition={labelPosition}>
      {onSelect && data ? (
        <Label
          onSelect={() => onSelect(!data.selected)}
          name={label || data.label}
          selected={data.selected}
          count={data.count}
        />
      ) : (
        <Label label={label || "TOTAL"} />
      )}

      {new Array(12).fill(null).map((month, index) => {
        const monthKeyIndex = ((startKey - 1 + index) % 12) + 1
        const label = moment()
          .locale(i18n.language)
          .month(monthKeyIndex - 1)
          .format("MMM")
          .toUpperCase()

        const value = values.find(
          (value) => value[valueKeyName] === monthKeyIndex
        )?.[valuePropName]

        return (
          <InputField
            width={inputWidth}
            disabled={
              disabled === true ||
              (Array.isArray(disabled) && disabled[monthKeyIndex - 1])
            }
            showTotalbadges={total && showTotalbadges}
            showLabel={showLabel}
            labelPosition={labelPosition}
            key={monthKeyIndex}
            label={`${label} ${labelSuffix(monthKeyIndex)}`}
            value={value}
            onAction={() => {
              if (!onChange) return

              const remainingMonths = 12 - index - 1

              new Array(remainingMonths).fill(null).forEach((item, i) => {
                const nextMonthKey = ((monthKeyIndex + i) % 12) + 1

                if (Array.isArray(disabled) && disabled[nextMonthKey - 1])
                  return

                onChange(nextMonthKey, value)
              })
            }}
            onChange={onChange && ((value) => onChange(monthKeyIndex, value))}
          />
        )
      })}

      {showTotal && (
        <InputField
          width={inputWidth}
          total={total}
          showTotalbadges={showTotalbadges}
          key={"TOTAL"}
          showLabel={showLabel}
          labelPosition={labelPosition}
          label={"TOTAL"}
          value={values.reduce((p, c) => p + c[valuePropName], 0) || null}
        />
      )}
    </Component>
  )
}

export default RowInput
