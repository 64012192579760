import React from "react"

// styles
import { css } from "@emotion/core"

import { List as Component } from "antd"

const styling = (theme, props) => css`
  label: component-list--styling;
  border: none;

  .ant-list-item {
    padding: 0;
  }

  ${props.bordered
    ? null
    : css`
        .ant-list-item {
          border: none;
        }
      `};
`

const List = ({ children, ...rest }) => {
  return (
    <Component
      css={(theme) => styling(theme, { ...rest })}
      className="component-list"
      {...rest}
    >
      {children}
    </Component>
  )
}

List.Item = Component.Item

export default List
