import React from "react"

// styles
import { css } from "@emotion/core"

import { Table as Component } from "antd"

const styling = (theme, props) => css`
  label: component-table--styling;

  margin-bottom: 1rem;

  max-width: ${props.maxWidth || "100%"};

  /*  tr.ant-table-row:hover > td {
    background: none !important;
  }*/

  .ant-table {
    .ant-table-cell {
      padding-left: 0;
      padding-right: 0;
      padding: ${props.rowPadding || "8px"} 0px;
    }

    thead.ant-table-thead {
      > tr {
        th.ant-table-cell {
          vertical-align: ${props.headerVerticalAlign || "bottom"};

          &:not(:last-child) {
            padding-right: 8px;
          }

          &::before {
            width: 0 !important;
          }
        }
      }
    }

    tbody.ant-table-tbody {
      > tr.ant-table-row {
        td.ant-table-cell {
          vertical-align: ${props.verticalAlign || "middle"};

          &:not(:last-child) {
            padding-right: 8px;
          }
        }
      }
    }
  }

  ${props.loading
    ? css`
        thead {
          tr {
            th {
              border: none;
            }
          }
        }
        tbody {
          tr:nth-of-type(2n + 1) {
            ${theme.skeleton.loading}
            opacity: 0.5;

            td {
              padding-top: 8px;
              padding-bottom: 8px;
              opacity: 0;
            }

            td:first-of-type {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            td:last-of-type {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }

          tr:nth-of-type(2n) {
            opacity: 0;
            height: 10px;

            td {
              padding: 0 !important;
              opacity: 0;
              display: none;
            }
          }
        }
      `
    : null}

  .ant-table thead > tr > th {
    ${theme.font.typo.bold700};
    font-size: ${theme.font.size.tall};
    color: ${theme.textColorDefault};

    vertical-align: bottom;

    background: none;
  }

  .ant-table tbody > tr > td {
    ${theme.font.typo.default};
    font-size: ${theme.font.size.tall};
    color: ${theme.textColorDefault};
  }

  .ant-table tbody > tr:last-child {
    td {
      border-bottom: none;
    }
  }
`

const Table = ({
  dataSource,
  loading,
  verticalAlign,
  headerVerticalAlign,
  maxWidth,
  rowPadding,
  rowKey = "key",
  pagination = false,
  small = true,
  ...rest
}) => {
  return (
    <Component
      data-testid="table"
      css={(theme) =>
        styling(theme, {
          loading,
          verticalAlign,
          headerVerticalAlign,
          maxWidth,
          rowPadding,
        })
      }
      scroll={{ x: true }}
      dataSource={
        loading
          ? [
              { [rowKey]: 1 },
              { [rowKey]: 2 },
              { [rowKey]: 3 },
              { [rowKey]: 4 },
              { [rowKey]: 5 },
              { [rowKey]: 6 },
              { [rowKey]: 7 },
              { [rowKey]: 8 },
              { [rowKey]: 9 },
            ]
          : dataSource
      }
      className="component-table"
      rowKey={rowKey}
      pagination={pagination}
      small={small}
      {...rest}
    />
  )
}

export default Table
