import "moment/locale/fr"

import moment from "moment"

moment.updateLocale("fr", {
  week: {
    dow: 0, // Monday is the first day of the week.
  },
})

moment.updateLocale("en", {
  week: {
    dow: 0, // Monday is the first day of the week.
  },
})
