// packages
import moment from "moment"

// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
// REGEXP
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
export const regexp = {
  phone_number: {
    canada: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  },
  fiscal_year: /^([0-9]?[0-9]\/[0-9]?[0-9])$/,
  postal_code: {
    canada:
      /^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}$/,
  },
  url: /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,

  time: {
    minutes: `^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]`,
    decimals: `^([0-9]|0[0-9]|1[0-9]|2[0-3]).[0-9][0-9]`,
  },
  timeformat: {
    minutes: /^[-]?[0-9]{1,2}:[0-5][0-9]$/g,
    decimals: /^[-]?[0-9]{1,2}.[0-9]{2}$/g,
  },
}

// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
// FORM VALIDATION
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
export const is_date = (message) => (rule, value) => {
  const month = value.split("/")[1] - 1
  const day = value.split("/")[0]
  return moment({ day, month }).isValid()
    ? Promise.resolve()
    : Promise.reject(message)
}

export const is_number = (message) => (rule, value) =>
  !isNaN(value) || [null, undefined, ""].includes(value)
    ? Promise.resolve()
    : Promise.reject(message)

export const is_checked = (message) => (rule, value) =>
  value ? Promise.resolve() : Promise.reject(message)

export const is_gt = (number, message) => (rule, value) =>
  Number(value) > number || [null, undefined, ""].includes(value)
    ? Promise.resolve()
    : Promise.reject(message)

export const is_lt = (number, message) => (rule, value) =>
  Number(value) < number || [null, undefined, ""].includes(value)
    ? Promise.resolve()
    : Promise.reject(message)

export const is_eq = (comparision, message) => (rule, value) =>
  value === comparision || [null, undefined, ""].includes(value)
    ? Promise.resolve()
    : Promise.reject(message)

export const is_not_in_array = (comparision, message) => (rule, value) =>
  !comparision.includes(value) ? Promise.resolve() : Promise.reject(message)

export const remove_space_and_dash = (e) => {
  if (e.target.value === " " || e.target.value === "-") e.preventDefault()
}

export const doesNotExist = ({
  table,
  key,
  message,
  axiosInstance,
  mapper = (value) => value,
  whiteList = [],
  preCondition = () => true,
} = {}) => {
  return async function validator(rule, value) {
    if (!preCondition(value)) return Promise.resolve()

    const mappedValue = mapper(value ?? "")

    try {
      const { data: result } = await axiosInstance.get(
        `/${table}?${key}=eq.${mappedValue}&select=${table}_id`
      )

      console.log("Debug : validator doesNotExist reult : ", {
        value,
        mappedValue,
        result,
        passValidation: Array.isArray(result) && result.length === 0,
      })

      return whiteList.includes(mappedValue) ||
        (Array.isArray(result) && result.length === 0)
        ? Promise.resolve()
        : Promise.reject(message)
    } catch (e) {
      console.error(e)
      return Promise.reject("Une erreur est survenue!")
    }
  }
}

export const doesNotViolateUniqueContraint = ({
  table,
  key,
  constraint,
  message,
  axiosInstance,
  whiteList = [],
  mapper = (value) => value,
  preCondition = () => true,
} = {}) => {
  return async function validator(rule, value) {
    if (!preCondition(value)) return Promise.resolve()

    const mappedValue = mapper(value ?? "")

    try {
      const {
        data: [id],
      } = await axiosInstance.get(
        `/${constraint}?${key}=eq.${mappedValue}&select=${constraint}_id`
      )

      if (!id) return Promise.resolve()

      const { data: result } = await axiosInstance.get(
        `/${table}?${`${constraint}_id`}=eq.${
          id[`${constraint}_id`]
        }&select=${table}_id`
      )

      return whiteList.includes(mappedValue) ||
        (Array.isArray(result) && result.length === 0)
        ? Promise.resolve()
        : Promise.reject(message)
    } catch (e) {
      console.error(e)
      return Promise.reject("Une erreur est survenue!")
    }
  }
}

export const orcaValidator = (
  axios,
  rpc,
  { message, map = (v) => v, whiteList = [], preCondition = () => true }
) =>
  async function validator(rule, value) {
    try {
      const mappedValue = map(value)
      if (whiteList.includes(mappedValue)) return Promise.resolve()
      if (!preCondition(mappedValue)) return Promise.resolve()

      const { data: response } = await axios.post(`/rpc/${rpc}`, {
        value: mappedValue,
      })

      return response ? Promise.resolve() : Promise.reject(message)
    } catch (e) {
      console.error(e)
      return Promise.reject("Une erreur est survenue!")
    }
  }
