import React from "react"

// styles
// import styled from "@emotion/styled"
// import { css } from "@emotion/core"

import { Spin as Component } from "antd"

const Spinner = ({ ...rest }) => {
  return (
    <Component data-testid="spinner" className="component-spinner" {...rest} />
  )
}

export default Spinner
