import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

// translation
import useTranslate from "hooks/useTranslate"

// components
import {
  Select,
  Flex,
  Typo,
  Input,
  Dropdown,
  Menu,
  ActiveInactive,
} from "components"

// utils
import { omit } from "lodash"

// assets
import { ReactComponent as IconDuplicate } from "assets/icons/icon-duplicate.svg"
import { ReactComponent as IconBin } from "assets/icons/icon-bin.svg"
import { ReactComponent as IconDots } from "assets/icons/icon-dots.svg"

const FilterBox = styled.div`
  label: component-data-table--filter-box;
  padding: 4px;

  ${({ isGroupFilter, theme }) =>
    isGroupFilter
      ? css`
          border: 1px solid ${theme.borderColorLight};
          background-color: rgba(230, 230, 230, 0.1);
        `
      : null};
`

const Filters = ({
  isGroupFilter,
  schema,
  path,
  type: filterType,
  items: filterItems,
  onAddRule = () => null,
  onAddGroup = () => null,
  onChangeFilter = () => null,
  onDuplicateFilter = () => null,
  onDeleteFilter = () => null,
  onChangeGroupType = () => null,
}) => {
  const { t } = useTranslate(["ui"])

  return (
    <FilterBox isGroupFilter={isGroupFilter}>
      {filterItems.map((filter, index, itemArr) => (
        <Flex.Row
          key={filter.id}
          gutter={8}
          fullWidth
          style={{
            paddingLeft: 12,
            marginBottom: 8,
          }}
        >
          <Flex.Col span={3}>
            {index === 0 ? (
              <Typo.Text
                font="bold600"
                color="textColorDefault"
                block
                textAlign="right"
                margin="6px 0 0 0"
              >
                {t("ui:datatable.filters.where.label")}
              </Typo.Text>
            ) : index === 1 ? (
              <Select
                allowClear={false}
                block
                onChange={(value) => onChangeGroupType(path, value)}
                defaultValue={filterType}
                items={[
                  {
                    id: "or",
                    value: "or",
                    label: t("ui:datatable.filters.or.label"),
                  },
                  {
                    id: "and",
                    value: "and",
                    label: t("ui:datatable.filters.and.label"),
                  },
                ]}
              />
            ) : (
              <Typo.Text
                color="textColorDefault"
                block
                textAlign="right"
                margin="6px 0 0 0"
              >
                {filterType === "or"
                  ? t("ui:datatable.filters.or.label")
                  : t("ui:datatable.filters.and.label")}
              </Typo.Text>
            )}
          </Flex.Col>

          {filter.and || filter.or ? (
            <Flex.Col span={19}>
              <Filters
                isGroupFilter
                schema={schema}
                path={[
                  ...path,
                  index,
                  Object.entries(omit(filter, "id"))[0][0],
                ]}
                type={Object.entries(omit(filter, "id"))[0][0]}
                items={Object.entries(omit(filter, "id"))[0][1]}
                onAddRule={onAddRule}
                onAddGroup={onAddGroup}
                onDuplicateFilter={onDuplicateFilter}
                onDeleteFilter={onDeleteFilter}
                onChangeGroupType={onChangeGroupType}
                onChangeFilter={onChangeFilter}
              />
            </Flex.Col>
          ) : (
            <React.Fragment>
              <Flex.Col span={6}>
                <Select
                  allowClear={false}
                  block
                  placeholder={t("ui:datatable.form.placeholder.field.label")}
                  defaultValue={filter.field}
                  value={filter.field}
                  onChange={(value) =>
                    onChangeFilter([...path, index], {
                      ...filter,
                      field: value,
                      operator: null,
                      value: null,
                    })
                  }
                  items={schema.map((col) => ({
                    id: col.key,
                    label: col.title,
                    value: col.value,
                  }))}
                />
              </Flex.Col>
              <Flex.Col span={7}>
                <ActiveInactive active={filter.field}>
                  <Select
                    allowClear={false}
                    block
                    placeholder={t(
                      "ui:datatable.form.placeholder.operator.label"
                    )}
                    onChange={(value) =>
                      onChangeFilter([...path, index], {
                        ...filter,
                        operator: value,
                        value: null,
                      })
                    }
                    defaultValue={filter.operator}
                    value={filter.operator}
                    items={(() => {
                      switch (
                        schema.find((col) => col.key === filter.field)?.type
                      ) {
                        case "text":
                          return [
                            "equals",
                            "notEquals",
                            "empty",
                            "notEmpty",
                            "set",
                            "notSet",
                            "contains",
                          ]

                        case "date":
                          return ["equals", "notEquals", "set", "notSet"]

                        case "daterange":
                          return ["equals", "notEquals", "set", "notSet"]

                        case "select":
                          return ["equals", "notEquals", "set", "notSet"]

                        case "formular":
                          return ["equals", "notEquals", "set", "notSet"]

                        case "number":
                          return [
                            "equals",
                            "notEquals",
                            "set",
                            "notSet",
                            "gt",
                            "gte",
                            "lt",
                            "lte",
                          ]

                        default:
                          return []
                      }
                    })().map((id) => ({
                      id,
                      value: id,
                      label: t(`ui:datatable.filters.types.${id}.label`),
                    }))}
                  />
                </ActiveInactive>
              </Flex.Col>
              <Flex.Col span={6}>
                <ActiveInactive
                  active={[
                    "equals",
                    "notEquals",
                    "gt",
                    "gte",
                    "lt",
                    "lte",
                    "contains",
                  ].includes(filter.operator)}
                >
                  <Input
                    defaultValue={filter.value}
                    value={filter.value}
                    placeholder={t("ui:datatable.form.placeholder.value.label")}
                    onChange={(e) =>
                      onChangeFilter([...path, index], {
                        ...filter,
                        value: e.target.value,
                      })
                    }
                  />
                </ActiveInactive>
              </Flex.Col>
            </React.Fragment>
          )}

          <Flex.Col span={2}>
            <Dropdown.Button
              icon={<IconDots width="16px" />}
              type="text"
              size="small"
              trigger="click"
              arrow={false}
              overlay={
                <Menu
                  onClick={({ key }) => {
                    switch (key) {
                      case "duplicate":
                        onDuplicateFilter(new Date().getTime(), path, index)
                        break

                      case "delete":
                        onDeleteFilter(path, index)

                        break

                      default:
                        break
                    }
                  }}
                  items={[
                    {
                      key: "duplicate",
                      icon: <IconDuplicate width="16px" />,
                      label: t("ui:datatable.buttons.duplicate.label"),
                    },
                    {
                      key: "delete",
                      icon: <IconBin width="16px" height="16px" />,
                      label: t("ui:datatable.buttons.delete.label"),
                    },
                  ]}
                />
              }
            />
          </Flex.Col>
        </Flex.Row>
      ))}

      <Dropdown.Button
        trigger="click"
        arrow={false}
        type="text"
        grey
        size="small"
        label={t("ui:datatable.buttons.add-filter.label")}
        overlay={
          <Menu
            onClick={({ key }) => {
              switch (key) {
                case "add-rule":
                  onAddRule(new Date().getTime(), path)
                  break

                case "add-group":
                  onAddGroup(new Date().getTime(), path)
                  break

                default:
                  break
              }
            }}
            items={[
              {
                key: "add-rule",
                label: t("ui:datatable.buttons.add-rule.label"),
              },
              {
                key: "add-group",
                label: t("ui:datatable.buttons.add-group-rule.label"),
              },
            ]}
          />
        }
      />
    </FilterBox>
  )
}

export default Filters
