import React from "react"

// styles
// import styled from "@emotion/styled"
// import { css } from "@emotion/core"

// component
import { Descriptions as Component } from "antd"

const Description = ({ children, ...rest }) => {
  return (
    <Component className="component-description" {...rest}>
      {children}
    </Component>
  )
}

Description.Item = Component.Item

export default Description
