// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 24,
  height = 15,
  color = "themeColorDefault",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 15"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H22.5C23.3284 0 24 0.671573 24 1.5C24 2.32843 23.3284 3 22.5 3H1.5C0.671573 3 0 2.32843 0 1.5ZM0 7.5C0 6.67157 0.671573 6 1.5 6H22.5C23.3284 6 24 6.67157 24 7.5C24 8.32843 23.3284 9 22.5 9H1.5C0.671573 9 0 8.32843 0 7.5ZM0 13.5C0 12.6716 0.671573 12 1.5 12H14.5C15.3284 12 16 12.6716 16 13.5C16 14.3284 15.3284 15 14.5 15H1.5C0.671573 15 0 14.3284 0 13.5Z"
        fill={theme[color]}
      />
    </svg>
  )
}
