// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 18,
  height = 16,
  color = "themeColorDefault",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="16 16 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6067 29.8891C33.0154 33.2168 34.0505 35.5789 35.2632 35.5789C36.8326 35.5789 38.1053 31.6202 38.1053 26.7368C38.1053 21.8535 36.8326 17.8947 35.2632 17.8947C34.0505 17.8947 33.0147 20.2575 32.6067 23.5846C33.41 23.6329 34.1646 23.986 34.7163 24.5719C35.268 25.1577 35.5752 25.9321 35.5752 26.7368C35.5752 27.5416 35.268 28.316 34.7163 28.9018C34.1646 29.4877 33.41 29.8408 32.6067 29.8891ZM40 26.7368C40 32.6667 37.7377 37.4737 34.9474 37.4737C30.5263 37.4737 27.3684 32.4211 25.4737 32.4211H21.6842C20.1767 32.4211 18.7309 31.8222 17.6649 30.7562C16.5989 29.6902 16 28.2444 16 26.7368C16 25.2293 16.5989 23.7835 17.6649 22.7175C18.7309 21.6515 20.1767 21.0526 21.6842 21.0526H25.4737C27.3684 21.0526 30.5263 16 34.9474 16C37.7377 16 40 20.8069 40 26.7368ZM21.5579 23.5789C20.7539 23.5789 19.9828 23.8983 19.4142 24.4669C18.8457 25.0354 18.5263 25.8065 18.5263 26.6105C18.5263 26.8115 18.6062 27.0043 18.7483 27.1464C18.8904 27.2886 19.0832 27.3684 19.2842 27.3684C19.4852 27.3684 19.678 27.2886 19.8201 27.1464C19.9623 27.0043 20.0421 26.8115 20.0421 26.6105C20.0421 26.2085 20.2018 25.823 20.4861 25.5387C20.7703 25.2544 21.1559 25.0947 21.5579 25.0947C21.7589 25.0947 21.9517 25.0149 22.0938 24.8728C22.2359 24.7306 22.3158 24.5378 22.3158 24.3368C22.3158 24.1358 22.2359 23.9431 22.0938 23.8009C21.9517 23.6588 21.7589 23.5789 21.5579 23.5789ZM22.3158 34.3158H24.7726C25.0602 34.3157 25.3391 34.4138 25.5634 34.5937C25.7877 34.7737 25.9439 35.0247 26.0061 35.3055L26.7072 38.4634C26.7481 38.648 26.7471 38.8395 26.7041 39.0237C26.6612 39.2079 26.5774 39.3801 26.459 39.5276C26.3406 39.675 26.1906 39.7941 26.0201 39.8758C25.8495 39.9576 25.6628 40 25.4737 40H23.0168C22.7293 40.0001 22.4503 39.902 22.2261 39.7221C22.0018 39.5421 21.8456 39.291 21.7834 39.0103L21.0823 35.8524C21.0414 35.6678 21.0424 35.4763 21.0854 35.2921C21.1283 35.1079 21.2121 34.9357 21.3305 34.7882C21.4489 34.6407 21.5989 34.5217 21.7694 34.4399C21.9399 34.3582 22.1267 34.3157 22.3158 34.3158Z"
        fill={theme[color]}
      />
    </svg>
  )
}
