// packages
import axios from "axios"

// token
import { useToken } from "store/token"

// utils
import message from "utils/message"

// logs
import debugModule from "utils/debug"
const debug = debugModule("useFirebaseAxios")

// axios
const instance = axios.create({
  baseURL: `${process.env.REACT_APP__ORCA_SERVER_API__HOST}${process.env.REACT_APP__ORCA_SERVER_API__FIREBASE_API_BASE_PATHNAME}`,
})

const addTokenToRequest = async (config) => {
  try {
    debug.log(
      `[${config.method}] ${
        config.baseURL.split("//")[1] + config.url.split("?")[0]
      }`
    )

    return config
  } catch (err) {
    debug.error(err)
    throw err
  }
}

instance.interceptors.request.use(addTokenToRequest, (err) =>
  Promise.reject(err)
)

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    debug.error(err)
    message.error(err?.message || "Error! Please contact VotreSuccès.com!", 60)
    return Promise.reject(err)
  }
)

function usePinkDolphinAxios() {
  const { serverToken } = useToken()

  instance.defaults.headers.common["Authorization"] = `Bearer ${serverToken}`

  return instance
}

export default usePinkDolphinAxios
