import React from "react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"

// store
import useWebuser from "store/api/webuser"
import { create as createPostalAddress } from "store/api/postal-address"
import {
  update as updateCcg,
  assign as assignToCcg,
} from "store/api/corporate-clinic-group"

const useWebuserCcg = (revalidateOnMount = false) => {
  const orca = useOrcaAxios()

  const webuserAPI = useWebuser()

  const webuser = webuserAPI.data ?? {}
  const webuserId = webuser.webuser_id

  const {
    data: ccgData,
    error,
    mutate,
    isValidating,
  } = useSWR(
    () => (webuserId ? "/rpc/ccg__get" : null),
    async (url) => {
      const { data } = await orca.post(url, {
        webuser_id_value: webuserId,
      })
      return data
    },
    { revalidateOnMount }
  )

  const data = React.useMemo(() => {
    return ccgData
      ? ccgData.map((c) => ({
          corporate_clinic_group_id: c.corporate_clinic_group_id,
          corporate_clinic_group: {
            slug: c.slug,
            name: c.name,
            acronym: c.acronym,
            webuser_creator_id: c.webuser_creator_id,
          },
        }))
      : undefined
  }, [ccgData])

  const assign = async (ccgId, clinicList) =>
    assignToCcg(orca, ccgId, clinicList)

  const create = async ({
    switchTenant,
    clinicList,
    ccg: { name, slug, fiscalYearStartDateDayKey, fiscalYearStartDateMonthKey },
    postalAddress: { address, city, districtCode, postalCode, countryCode },
  }) => {
    if (fiscalYearStartDateMonthKey < 1 || fiscalYearStartDateMonthKey > 12) {
      throw new Error({
        name: "Bad type",
        message: "'fiscal_year_start_date_month_key' must be between 1 - 12",
        toString: function () {
          return this.name + ": " + this.message
        },
      })
    }
    if (fiscalYearStartDateMonthKey === 2 && fiscalYearStartDateDayKey === 29) {
      throw new Error({
        name: "Bad type",
        message: "Fiscal year cannot be Feb 29th",
        toString: function () {
          return this.name + ": " + this.message
        },
      })
    }

    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    // CREATE CCG ENTITY
    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    const { data: newCCGId } = await orca.post(
      "/rpc/ccg__insert",
      {
        webuser_id: webuserId,
        name,
        slug,
      },
      {
        headers: {
          Prefer: "return=representation",
        },
      }
    )

    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    // CREATE POSTAL ADDRESS
    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***

    const newPostalAddress = await createPostalAddress(orca, {
      address,
      city,
      district_code: districtCode,
      country_code: countryCode,
      postal_code: postalCode,
      subjectId: newCCGId,
      subjectType: "ccg",
    })

    await switchTenant(newCCGId)

    const updateCcgPromise = updateCcg(orca, newCCGId, {
      fiscal_year_start_date_day_key: fiscalYearStartDateDayKey,
      fiscal_year_start_date_month_key: fiscalYearStartDateMonthKey,
      postal_address_id: newPostalAddress.postal_address_id,
      acronym: slug
        .split("-")
        .map((str, index, arr) =>
          !isNaN(str) ? (arr[index + 1] ? `-${str}-` : `-${str}`) : str[0]
        )
        .join("")
        .toUpperCase()
        .substring(0, 10),
    })

    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
    // UPDATE CLINICS WITH CCG ID
    // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***

    const assignClinicsPromise = assignToCcg(orca, newCCGId, clinicList)

    await Promise.all([assignClinicsPromise, updateCcgPromise])

    return newCCGId
  }

  return {
    data,
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    create,
    assign,
  }
}

export default useWebuserCcg
