export const colors = {
  white: { hex: "#FFFFFF", rgb: "255,255,255" },
  black: { hex: "#000000", rgb: "0,0,0" },
  black808: { hex: "#3B4A6E", rgb: "59,74,110" },
  teal1111: { hex: "#7FE4FF", rgb: "127,228,255" },
  deepSpace: { hex: "#121E31", rgb: "18,30,49" },
  eventHorizon: { hex: "#12243B", rgb: "18,36,59" },
  lacrimosa: { hex: "#1E2C4D", rgb: "30,44,77" },
  endika: { hex: "#2D3B63", rgb: "45,59,99" },
  orion: { hex: "#48577A", rgb: "72,87,122" },
  ufo: { hex: "#5D6C8F", rgb: "93,108,143" },
  dao: { hex: "#646F8B", rgb: "100,111,139" },
  congruence: { hex: "#75809D", rgb: "117,128,157" },
  inBetween: { hex: "#8690AD", rgb: "134,144,173" },
  mills: { hex: "#98A4C0", rgb: "152,164,192" },
  moonshadow: { hex: "#A6B2CE", rgb: "166,178,206" },
  wiseRabbit: { hex: "#B9C2DA", rgb: "185,194,218" },
  karesansui: { hex: "#D9DEEB", rgb: "217,222,235" },
  saltPlains: { hex: "#E3E7F1", rgb: "227,231,241" },
  spirit: { hex: "#EBEFF7", rgb: "235,239,247" },
  silence: { hex: "#FAFBFD", rgb: "rgb(250, 251, 252)" },
  revelation: { hex: "#F5F7FB", rgb: "245,247,251" },
  one: { hex: "#FFFFFF", rgb: "255,255,255" },
  deepThought: { hex: "#00359E", rgb: "0,53,158" },
  blockchain: { hex: "#094CC2", rgb: "9,76,194" },
  arthurBlue: { hex: "#156CF7", rgb: "21,108,247" },
  azure: { hex: "#1D88FF", rgb: "29,136,255" },
  opal: { hex: "#59A8FF", rgb: "89,168,255" },
  calmSky: { hex: "#AED7FF", rgb: "174,215,255" },
  vipassana: { hex: "#CAE6FF", rgb: "202,230,255" },
  resonancePeak: { hex: "#41F3FF", rgb: "65,243,255" },
  oceanographer: { hex: "#018BD1", rgb: "1,139,209" },
  blueMorpho: { hex: "#00AAFF", rgb: "0,170,255" },
  kryon: { hex: "#38D5FF", rgb: "56,213,255" },
  breathe: { hex: "#9FEAFF", rgb: "159,234,255" },
  alice: { hex: "#B1EEFF", rgb: "177,238,255" },
  mirage: { hex: "#D8F7FF", rgb: "216,247,255" },
  motherPine: { hex: "#007372", rgb: "0,115,114" },
  branchMan: { hex: "#00897C", rgb: "0,137,124" },
  eden: { hex: "#00A88E", rgb: "0,168,142" },
  francis: { hex: "#35BAA5", rgb: "53,186,165" },
  mediumAquarine: { hex: "#5FCEBD", rgb: "95,206,189" },
  wimHof: { hex: "#94E3D7", rgb: "148,227,215" },
  surrender: { hex: "#C1F1EA", rgb: "193,241,234" },
  jungleObscurity: { hex: "#007457", rgb: "0,116,87" },
  bonsai: { hex: "#00A375", rgb: "0,163,117" },
  li: { hex: "#00C991", rgb: "0,201,145" },
  myscelia: { hex: "#48DE9E", rgb: "72,222,158" },
  smileOfLife: { hex: "#71E8B8", rgb: "113,232,184" },
  essence: { hex: "#A6EDCF", rgb: "166,237,207" },
  chloe: { hex: "#D2F6E6", rgb: "210,246,230" },
  swami: { hex: "#FF8C2D", rgb: "255,140,45" },
  elon: { hex: "#FF9E3B", rgb: "255,158,59" },
  sol: { hex: "#FFBF00", rgb: "255,191,0" },
  sivananda: { hex: "#FFD55F", rgb: "255,213,95" },
  marigold: { hex: "#FFE88C", rgb: "255,232,140" },
  ralphAtTheBeach: { hex: "#FFF3B8", rgb: "255,243,184" },
  egyptianSands: { hex: "#FDF6D9", rgb: "253,246,217" },
  tibet: { hex: "#C12E4D", rgb: "193,46,77" },
  emperorOfStereo: { hex: "#D14957", rgb: "209,73,87" },
  futureboyRed: { hex: "#FF5E69", rgb: "255,94,105" },
  neverSettle: { hex: "#FF7B7B", rgb: "255,123,123" },
  coralPink: { hex: "#FD8888", rgb: "253,136,136" },
  blush: { hex: "#FDB4B4", rgb: "253,180,180" },
  vulnerability: { hex: "#FDD8D8", rgb: "253,216,216" },
  infinity: { hex: "#493BB1", rgb: "73,59,177" },
  understanding: { hex: "#5342CC", rgb: "83,66,204" },
  thirdEye: { hex: "#6754EC", rgb: "103,84,236" },
  magic: { hex: "#9283FF", rgb: "146,131,255" },
  yantra: { hex: "#A598FF", rgb: "165,152,255" },
  two: { hex: "#BAB0FF", rgb: "186,176,255" },
  memory: { hex: "#E0DCFF", rgb: "224,220,255" },
}
