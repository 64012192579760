import React from "react"

// utils
import * as fn from "utils/functions"

const DashboardContext = React.createContext()

function useDashboard() {
  const context = React.useContext(DashboardContext)
  if (!context) {
    throw new Error(`useDashboard must be used within a DashboardProvider`)
  }

  const [config, setConfig] = context.config
  const [filter, setFilter] = context.filter

  return {
    filter,
    options: context.options,
    config: {
      ...config,
      date_range_in_days:
        (new Date(config.analysis_end_date_key.replace(/-/g, "/")).getTime() -
          new Date(
            config.analysis_start_date_key.replace(/-/g, "/")
          ).getTime()) /
        (1000 * 3600 * 24),

      analysis_start_year_key: new Date(
        config.analysis_start_date_key.replace(/-/g, "/")
      ).getFullYear(),

      analysis_end_year_key: new Date(
        config.analysis_end_date_key.replace(/-/g, "/")
      ).getFullYear(),
    },
    setConfig: (value) => setConfig((prev) => ({ ...prev, ...value })),
    setFilter: (f) =>
      setFilter((prev) => {
        const current = prev.find((item) => item.member === f.member)

        if (!current) {
          return [...prev, f]
        } else {
          return prev.map((item) =>
            item.member === f.member ? { ...item, ...f } : item
          )
        }
      }),
    removeFilter: (memberKey) =>
      setFilter((prev) => prev.filter((f) => f.member !== memberKey)),
    extendWithDashboardFilters: (query, members = []) => ({
      ...query,
      filters: [
        ...(query.filters || []),
        ...filter
          .map((f) =>
            members
              .filter((member) => member.split(".")[1] === f.member)
              .map((member) => ({ ...f, member: member }))
          )
          .flat(),
      ],
    }),
  }
}

function DashboardProvider({ periodKeyDefaultValue = "this-month", ...props }) {
  const timeRange = fn.getTimeRange(periodKeyDefaultValue, {
    fiscalYearStartDateMonthKey: props.fiscalYearStartDateMonthKey,
    fiscalYearStartDateDayKey: props.fiscalYearStartDateDayKey,
  })

  const [config, setConfig] = React.useState({
    analysis_start_date_key: timeRange.start_date_key,
    analysis_end_date_key: timeRange.end_date_key,
  })

  const [filter, setFilter] = React.useState([])

  const configValue = React.useMemo(() => [config, setConfig], [config])
  const filterValue = React.useMemo(() => [filter, setFilter], [filter])
  return (
    <DashboardContext.Provider
      value={{
        config: configValue,
        filter: filterValue,
        options: {
          fiscalYearStartDateMonthKey: props.fiscalYearStartDateMonthKey,
          fiscalYearStartDateDayKey: props.fiscalYearStartDateDayKey,
        },
      }}
      {...props}
    />
  )
}

export { DashboardProvider, useDashboard }
