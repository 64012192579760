import React from "react"

// Sentry
import * as Sentry from "@sentry/react"

// store
import useWebuserClinicAPI from "store/api/webuser-clinic"

const WebuserClinicContext = React.createContext()

function useWebuserClinic() {
  const context = React.useContext(WebuserClinicContext)
  if (!context) {
    throw new Error(
      `useWebuserClinic must be used within a WebuserClinicProvider`
    )
  }

  const [clinic, setClinic] = context.clinic

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === "development" || !clinic) return

    Sentry.setContext("tenant", {
      tenant_type: "clinic",
      tenant_id: clinic.clinic_id,
    })
  }, [clinic])

  const webuserClinics = useWebuserClinicAPI()

  const hasProvider = React.useCallback(
    (providerName) =>
      clinic ? clinic.clinic.provider.includes(providerName) : false,
    [clinic]
  )

  return {
    clinic,
    hasProvider,
    setClinic,
    store: webuserClinics,
    clinics: webuserClinics.data ?? [],
  }
}

function WebuserClinicProvider(props) {
  const [clinic, setClinic] = React.useState()

  const clinic_value = React.useMemo(() => [clinic, setClinic], [clinic])

  return (
    <WebuserClinicContext.Provider
      value={{
        clinic: clinic_value,
      }}
      {...props}
    />
  )
}

export { WebuserClinicProvider, useWebuserClinic }

export default useWebuserClinic
