import React from "react"

// styles
import styled from "@emotion/styled"

const Component = styled.div`
  label: component-text-box;

  background: ${({ theme }) => theme.backgroundColorDefault};
  border: 1px solid ${({ theme }) => theme.borderColorLighter};

  box-sizing: border-box;
  box-shadow: 0px 4px 8px #f5f7fb;
  border-radius: 12px;

  max-width: 838px;
  max-height: 560px;

  overflow: scroll;

  padding: 40px;

  text-align: left;
  margin: auto;
`

const Head = styled.header`
  label: component-text-box--header;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.textColorDark};

  line-height: 110%;
  letter-spacing: 0.01em;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`
const Body = styled.div`
  label: component-text-box--body;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.textColorLight};

  line-height: 150%;
  letter-spacing: 0.04em;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`

const TextBox = ({ children, title, ...rest }) => (
  <Component {...rest}>
    {title ? <Head>{title}</Head> : null}
    <Body>{children}</Body>
  </Component>
)

export default TextBox
