// core
import "__init__"
import React from "react"
import ReactDOM from "react-dom"

// sentry
import * as Sentry from "@sentry/react"
import { Integrations as TracingIntegrations } from "@sentry/tracing"

// sw
import { SWProvider, useSW } from "store/serviceworker"

// errors
import { ErrorBoundary } from "react-error-boundary"

// translation
import { useTranslation, I18nextProvider } from "react-i18next"
import i18n from "translations/i18n"
import frFR from "antd/es/locale/fr_FR"

// contexts providers
import { ConfigProvider } from "antd"
import { SWRConfig } from "swr"

// components
import { Loader, Modal, ErrorFallback } from "components"

// library
import initAuth0Client from "auth0/lib/auth0"
import AuthApp from "auth0/lib/AuthApp"

// auth0
// import { Auth0Provider } from "@auth0/auth0-react"

// hooks
import useUrlSearchParams from "hooks/useUrlSearchParams"

// styles
import { ThemeProvider } from "emotion-theming"
import { Global } from "@emotion/core"
import "styles/antd.less"
import themes from "styles/themes"
import globalCSS from "styles/global"
import overridesCSS from "styles/overrides"
import transitionsCSS from "styles/transitions"

// sentry initialization
if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    dsn: process.env.REACT_APP__SENTRY__DSN_URL,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 1,
    environment: process.env.REACT_APP_ENV,
  })
}

// apps
const AuthenticatedApp = React.lazy(() => import("apps/AuthenticatedApp"))
const UnauthenticatedApp = React.lazy(() => import("apps/UnauthenticatedApp"))

// config
const AUTH0_CONFIG = {
  domain: process.env.REACT_APP__AUTH__AUTH0_DOMAIN,
  client_id: process.env.REACT_APP__AUTH__AUTH0_CLIENT_ID,
  postgrest_uri: process.env.REACT_APP__ORCA_PGRST_API__HOST,
  redirect_uri: window.location.origin,
  audience: process.env.REACT_APP__AUTH__AUTH0_AUDIENCE,
}

const RootAuth0 = () => {
  const { i18n } = useTranslation(["common"])
  const params = useUrlSearchParams()

  const initLanguage = React.useCallback(() => {
    if (params.lng) {
      i18n.changeLanguage(params.lng)
    } else {
      if (i18n.language !== "fr" && i18n.language !== "en") {
        if (["en-US", "en-GB", "en-CA"].includes(i18n.language)) {
          i18n.changeLanguage("en")
        } else {
          i18n.changeLanguage("fr")
        }
      }
    }
  }, [i18n, params.lng])

  React.useEffect(() => {
    console.log("AUTODETECT LANGUAGE", i18n.language)
    initLanguage()
  }, [i18n, initLanguage])

  const onAuth0Redirect = (appState) => {
    const bugFixAuth0InvalidStateError = () => {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // more infos under auth0 documentation
      // https://github.com/auth0/auth0-spa-js/blob/master/FAQ.md#why-do-i-get-error-invalid-state-in-firefox-when-refreshing-the-page-immediately-after-a-login
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      window.location.hash = window.location.hash // eslint-disable-line no-self-assign

      window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
    }

    console.warn("Redirection from Authentification")

    bugFixAuth0InvalidStateError()
  }
  return (
    <AuthApp
      authProviderLibraryInitializerFunction={initAuth0Client}
      authProviderLibraryOptions={AUTH0_CONFIG}
      authProviderLibraryCallbacks={{
        onRedirect: onAuth0Redirect,
      }}
      paths={{}}
      authenticatedApp={AuthenticatedApp}
      unauthenticatedApp={UnauthenticatedApp}
      loader={Loader}
    />
  )
}

const RootI18n = () => (
  <I18nextProvider i18n={i18n}>
    <RootAuth0 />
  </I18nextProvider>
)

const RootApp = () => {
  const { i18n, t } = useTranslation()
  const { serviceWorkerUpdated, updateServiceWorker } = useSW()
  const [newVersion, setNewVersion] = React.useState(false)

  React.useEffect(() => {
    if (newVersion) return

    if (serviceWorkerUpdated) {
      setNewVersion(true)
      setTimeout(function () {
        Modal.confirm({
          title: "VotreSuccès.com",
          content: t("app:new-version.label"),
          onOk() {
            updateServiceWorker()
          },
          onCancel() {
            return
          },
        })
      }, 10000)
    }
  }, [newVersion, serviceWorkerUpdated, t, updateServiceWorker])

  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 500,
      }}
    >
      <ConfigProvider locale={i18n.language === "fr" ? frFR : null}>
        <ThemeProvider theme={themes.default}>
          <ErrorBoundary
            FallbackComponent={(props) => (
              <ErrorFallback part="index" {...props} />
            )}
            onReset={() => {
              window.location.reload()
            }}
          >
            <RootI18n />
          </ErrorBoundary>

          <Global
            styles={(theme) => [
              globalCSS,
              transitionsCSS,
              overridesCSS(theme, process.env.REACT_APP_ENV),
            ]}
          />
        </ThemeProvider>
      </ConfigProvider>
    </SWRConfig>
  )
}

const App = () => (
  <SWProvider>
    <RootApp />
  </SWProvider>
)

Sentry.withProfiler(App)

ReactDOM.render(<App />, document.getElementById("root"))
