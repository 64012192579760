import React from "react"

export default ({ rotation, color, ...rest }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.2458 0.378636C3.64366 -0.126212 4.35634 -0.126212 4.7542 0.378636L7.75455 4.18584C8.31414 4.89592 7.85515 6 7.00036 6H0.999642C0.144854 6 -0.314144 4.89592 0.245446 4.18584L3.2458 0.378636Z"
        fill={color}
        transform={`rotate(${rotation}, 4, 3)`}
      />
    </svg>
  )
}
