import { css } from "@emotion/core"

export default (theme, env) => css`
  .ant-form-item-has-error {
    .ant-input-number,
    .ant-input,
    .ant-select,
    .ant-input-affix-wrapper,
    .ant-input:hover,
    .ant-input-affix-wrapper:hover {
      background-color: rgba(255, 94, 105, 0.2) !important;
      border: 1px solid ${theme.borderColorError};
    }

    .component-rangepicker {
      color: ${theme.borderColorError};
      background-color: rgba(255, 94, 105, 0.2) !important;
      border: 1px solid ${theme.borderColorError} !important;
    }

    .component-checkbox {
      color: ${theme.borderColorError};

      .ant-checkbox-inner {
        border-color: ${theme.borderColorError} !important;
      }
    }
  }

  .ant-message {
    z-index: 10000000 !important;

    .ant-message-notice-content {
      padding: 0 !important;
      box-shadow: none !important;
      background: none !important;

      .ant-message-error,
      .ant-message-success,
      .ant-message-loading {
        padding: 10px 16px;
        border-radius: 6px !important;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
          0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      }

      .ant-message-error {
        background: rgb(255 223 225);
      }

      .ant-message-success {
        background: rgb(227, 255, 213);
      }

      .ant-message-loading {
        background: rgb(150, 211, 255);
      }
    }
  }

  .ant-modal-mask {
    z-index: 1050;
  }

  .ant-modal-wrap {
    z-index: 1050;
  }

  .ant-modal-confirm {
    .ant-modal-content {
      ${theme.round}

      .ant-modal-body {
        padding: 32px 20px;

        svg {
          fill: ${theme.themeColorDefault};
        }

        .ant-modal-confirm-body-wrapper {
          .ant-modal-confirm-body {
            .ant-modal-confirm-title {
              ${theme.font.typo.bold700};
              font-size: ${theme.font.size.tall};
              color: ${theme.textColorDefault};
            }
            .ant-modal-confirm-content {
              ${theme.font.typo.default};
              font-size: ${theme.font.size.default};
              color: ${theme.textColorDefault};
            }
          }
          .ant-modal-confirm-btns {
            .ant-btn {
              border-radius: 12px;
              transition: transform 0.3s, background-color 0.3s;
              white-space: break-spaces;
              box-shadow: none !important;

              height: auto;
              min-height: 30px;
              padding: 2px 10px;

              span {
                white-space: nowrap;
              }
            }

            .ant-btn-primary {
              span {
                ${theme.font.typo.bold600};
                font-size: ${theme.font.size.default};
                color: ${theme.textColorWhite};
              }

              &:hover {
                transform: translate3d(0, -1px, 0);
              }
            }

            .ant-btn-default {
              border: 1px solid ${theme["textColorExtraLight"]};

              span {
                ${theme.font.typo.bold600};
                font-size: ${theme.font.size.default};
                color: ${theme["textColorExtraLight"]};
              }

              &:hover {
                border: 1px solid ${theme["textColorLighter"]};

                span {
                  color: ${theme["textColorLighter"]};
                }
              }
            }

            .okay {
            }

            .cancel {
            }
          }
        }
      }
    }
  }
`
