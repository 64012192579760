import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Component = styled.div`
  label: component-card;

  background-color: ${({ theme, disabled, type }) =>
    type === "info"
      ? theme.backgroundColorExtraLight
      : theme.backgroundColorDefault};
  border: 1px solid
    ${({ theme, disabled, type }) =>
      disabled
        ? theme.borderColorLight
        : type === "info"
        ? theme.borderColorLight
        : theme.borderColorDefault};

  display: ${({ inline }) => (inline ? "inline-block" : "block")};
  box-sizing: border-box;
  border-radius: 12px;
  cursor: default;
  width: 100%;
  max-width: ${({ width = "100%" }) => width};

  overflow: hidden;

  transition: box-shadow 0.3s, border-color 300ms;

  ${({ isloading, theme }) => (isloading ? theme.skeleton.loading : null)}

  ${({ noHover, theme, disabled }) =>
    noHover
      ? null
      : css`
          &:hover {
            box-shadow: ${disabled ? "transparent" : "0px 8px 8px #f5f7fb"};
          }
        `}

  ${({ type, theme }) =>
    type !== "info"
      ? null
      : css`
          ${theme.font.typo.default};
          font-size: ${theme.font.size.small};
          color: ${theme.textColorLight};
          padding: 12px;
        `}



  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`

const Content = styled.div`
  label: component-card--content;

  display: flex;
  padding: 20px;
`

const Header = styled.div`
  label: component-card--header;

  border-bottom: 1px solid ${({ theme }) => theme.borderColorDefault};
`

const Body = styled.div`
  label: component-card--body;
  width: 100%;
`

const Prefix = styled.div`
  label: component-card--prefix;
  margin-right: 10px;
`

const Card = ({ prefix, children, header, loading, type = "box", ...rest }) => {
  return (
    <Component isloading={loading} type={type} {...rest}>
      {header && <Header>{header}</Header>}

      <Content>
        {prefix && <Prefix>{prefix}</Prefix>}
        <Body>{children}</Body>
      </Content>
    </Component>
  )
}

export default Card
