import React from "react"

// styles
import styled from "@emotion/styled"

// components
import { Progress } from "components"

// translation
import { useTranslation } from "react-i18next"

// hooks
import hook from "hooks/useFirestoreLoader"

// hooks
import * as fn from "utils/functions"

const FirestoreLoaderWrapper = styled.div`
  label: component-firestore-loader;

  margin: 0 auto;
  max-width: 418px;
`

const FirestoreLoaderBody = styled.div`
  label: component-firestore-loader-body;

  background: ${({ theme, borderless }) =>
    borderless ? "transparent" : theme.backgroundColorDefault};
  border: 1px solid
    ${({ theme, borderless }) =>
      borderless ? "transparent" : theme.borderColorLighter};

  box-sizing: border-box;
  box-shadow: 0px 12px 12px
    ${({ borderless }) =>
      borderless ? "transparent" : "rgba(59, 74, 110, 0.25)"};
  border-radius: 12px;
  padding: 24px;
  margin: 16px;
`

const FirestoreLoaderMessage = styled.p`
  label: component-firestore-loader--message;

  ${({ theme }) => theme.font.typo.bold700};
  font-size: ${({ theme }) => theme.font.size.extraTall};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  margin-top: 16px;
`
const FirestoreLoaderInfo = styled.p`
  label: component-firestore-loader--info;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorLighter};

  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;

  margin-top: 16px;
`

const FirestoreLoaderErrorInfo = styled.div`
  label: component-firestore-loader--error-info;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorError};

  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;

  margin-top: 16px;
`

const FirestoreLoaderErrorMessage = styled.p`
  label: component-firestore-loader--error-message;

  ${({ theme }) => theme.font.typo.bold700};
  font-size: ${({ theme }) => theme.font.size.extraTall};
  color: ${({ theme }) => theme.textColorError};

  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.01em;

  margin-top: 16px;
`

const FirestoreLoader = ({
  borderless = false,
  progress,
  message,
  info,
  eta,
  status,
  errMessage,
  errContent,
  onFinish,
}) => {
  const { t } = useTranslation("ui")

  React.useEffect(() => {
    const loadingCompleted = async () => {
      if (progress === 100 && status === "success") {
        await fn.wait(300)
        onFinish && onFinish()
      }
    }
    loadingCompleted()
  }, [onFinish, progress, status])

  return (
    <FirestoreLoaderWrapper data-testid="firestore-loader">
      <FirestoreLoaderBody borderless={borderless} role="loader">
        <Progress
          data-testid="firestore-loader-progress"
          data-status={status}
          role="progress-loader"
          percent={status === "success" ? 100 : Math.max(progress, 5)}
          status={status}
          showInfo={false}
        />

        {status === "exception" && (
          <React.Fragment>
            {errMessage && (
              <FirestoreLoaderErrorMessage>
                {errMessage}
              </FirestoreLoaderErrorMessage>
            )}
            {errContent && (
              <FirestoreLoaderErrorInfo>{errContent}</FirestoreLoaderErrorInfo>
            )}
          </React.Fragment>
        )}

        {status !== "exception" && (
          <React.Fragment>
            {message && (
              <FirestoreLoaderMessage>{message}</FirestoreLoaderMessage>
            )}
            {info && <FirestoreLoaderInfo>{info}</FirestoreLoaderInfo>}
            {eta && (
              <FirestoreLoaderInfo>
                {eta < 10
                  ? t("ui:loader.__.eta.label", { context: "arriving" })
                  : t("ui:loader.__.eta.label", {
                      context: "long",
                      seconds: eta,
                    })}
              </FirestoreLoaderInfo>
            )}
          </React.Fragment>
        )}
      </FirestoreLoaderBody>
    </FirestoreLoaderWrapper>
  )
}

FirestoreLoader.useLoader = hook

export default FirestoreLoader
