import React from "react"

// assets
import { ReactComponent as CloseIcon } from "assets/icons/icon-close-filled.svg"

// styles
import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"

// assets
import { ReactComponent as IconArrow } from "assets/arrows/arrow-triangle.svg"

import { Select as Component } from "antd"

const { Option, OptGroup } = Component

const Wrapper = styled.div`
  label: component-select--wrapper;

  width: ${(props) => (props.block ? "100%" : props.width || "min-content")};

  box-shadow: ${({ open }) =>
    open ? "0 0 0 2px rgba(21, 108, 247, 0.2)" : "none"};

  ${({ theme }) => theme.round};
  ${({ theme, size }) => theme.height[size]};

  background-color: ${({ theme, bordered }) =>
    bordered ? theme.backgroundColorExtraLight : "transparent"};

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PostIcon = styled.div`
  label: component-select--post-icon;

  padding-right: 8px;
`

const PreIcon = styled.div`
  label: component-select--pre-icon;

  padding-left: 8px;
`

const stylingSelectDropdown = (theme) => css`
  .component-select-dropdown {
    padding: 0;

    animation-duration: 0.1s !important;
    ${theme.shadow};
    ${theme.round};

    background-color: ${theme.backgroundColorExtraLighter};

    .ant-select-item {
      ${theme.font.typo.default};
      font-size: ${theme.font.size.default};
      color: ${theme.textColorDefault};

      padding: 2px 12px;

      .ant-select-item-option-content {
        display: flex;
      }

      &.ant-select-item-group {
        min-height: 18px;
        line-height: 18px;
        ${theme.font.typo.bold700};
        color: ${theme.textColorLight};
        &:not(:first-of-type) {
          margin-top: 8px;
        }
      }

      &.ant-select-item-option-grouped {
        /*        padding-left: 24px;*/
      }

      .ant-select-item-option-state {
        visibility: hidden;
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${theme.backgroundColorLight};
    }

    &.borderless {
    }

    &.min-width {
      width: auto !important;
    }
  }
`

const stylingSelect = (theme, props) =>
  css`
    label: component-select--styling;
    background-color: ${theme.backgroundColorExtraLight};
    ${theme.round};

    width: ${props.block || props.width ? "100%" : "auto"};

    ${theme.height[props.size]}
    max-width: ${props.block ? "100%" : props.width || "auto"};

    ${props.loading ? theme.skeleton.loading : null}

    &.mode-tags, &.ant-select-multiple {
      .ant-select-selection-item {
        background-color: ${theme.themeColorDefault};
        color: white;
        border-radius: 3px;
        opacity: 0.85;

        .anticon-close {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
    .ant-select-selector {
      border: none !important;
      background-color: transparent !important;
      box-shadow: none !important;

      height: 100% !important;

      .ant-select-selection-item {
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;

        .component-tag {
          height: 100%;
        }

        .component-pill {
          height: 100%;
        }
      }
    }

    input {
      ${theme.font.typo.default};
      font-size: ${theme.font.size.default};
      color: ${theme.textColorDefault};
    }

    .ant-select-clear {
      background: transparent;

      svg {
        margin-top: -2px;
        margin-left: -2px;

        rect {
          opacity: 0.7;
        }

        &:hover {
          rect {
            opacity: 0.9;
          }
        }
      }
    }

    &.ant-select-borderless {
      background: none;
    }

    ${props.mode === "multiple" &&
    props.hoverable &&
    css`
      transition: background-color 300ms;
      background-color: transparent;

      .ant-select-arrow {
        opacity: 0;
      }

      &:not(.ant-select-disabled):hover {
        background-color: ${theme.backgroundColorExtraLight};

        .ant-select-arrow {
          opacity: 1;
        }
      }
    `};
  `

const Select = ({
  items = [],
  onChange,
  onSelect,
  bordered = true,
  size = "default",
  allowClear = true,
  dropdownMatchSelectWidth = false,
  loading,
  block,
  postIcon,
  preIcon,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Wrapper
      size={size}
      block={block}
      width={rest.width}
      open={open}
      bordered={bordered}
    >
      <Global styles={stylingSelectDropdown} />

      {preIcon && <PreIcon>{preIcon}</PreIcon>}

      <Component
        data-testid="select"
        css={(theme) =>
          stylingSelect(theme, {
            loading,
            size,
            mode: rest.mode,
            hoverable: rest.hoverable,
            width: rest.width,
            block,
          })
        }
        onSelect={(...args) => {
          if (rest.maxTagCount === 1) {
            setOpen(false)
          }

          if (onSelect) onSelect(...args)
        }}
        suffixIcon={<IconArrow />}
        clearIcon={<CloseIcon />}
        allowClear={allowClear}
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        className={
          "component-select " + (rest.mode === "tags" && " mode-tags ")
        }
        bordered={bordered}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        onChange={onChange}
        dropdownClassName={
          "component-select-dropdown " +
          (!bordered && " borderless ") +
          (dropdownMatchSelectWidth && " min-width ")
        }
        {...rest}
      >
        {items.map((item, itemIndex) => {
          return item.isGroup || item.children ? (
            <OptGroup key={item.id} label={item.label}>
              {item.children.map((child) => (
                <Option
                  key={`${item.label}-${child.id}`}
                  value={child.value}
                  title={child.title || item.id}
                  disabled={child.disabled}
                >
                  {child.label
                    ? typeof child.label === "function"
                      ? child.label(child.title || child.id)
                      : child.label
                    : child.value}
                </Option>
              ))}
            </OptGroup>
          ) : (
            <Option
              key={typeof item === "string" ? itemIndex : item.id}
              value={item.value}
              title={item.title || item.id}
              disabled={item.disabled}
            >
              {item.label
                ? typeof item.label === "function"
                  ? item.label(item.title || item.id)
                  : item.label
                : item.value}
            </Option>
          )
        })}
      </Component>

      {postIcon && <PostIcon>{postIcon}</PostIcon>}
    </Wrapper>
  )
}

export default Select
