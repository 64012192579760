import React from "react"

import * as serviceWorker from "serviceWorkerRegistration"

const SWContext = React.createContext()

function useSW() {
  const context = React.useContext(SWContext)
  if (!context) {
    throw new Error(`useSW must be used within a SWProvider`)
  }
  const [serviceWorkerInitialized] = context.serviceWorkerInitialized
  const [serviceWorkerUpdated] = context.serviceWorkerUpdated
  const [serviceWorkerRegistration] = context.serviceWorkerRegistration

  const updateServiceWorker = React.useCallback(() => {
    const registrationWaiting =
      serviceWorkerRegistration && serviceWorkerRegistration.waiting
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" })
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          // hard refresh with parameter = true
          window.location.reload(true)
        }
      })
    }
    // hard refresh with parameter = true
    window.location.reload(true)
  }, [serviceWorkerRegistration])

  return {
    serviceWorkerInitialized,
    serviceWorkerUpdated,
    serviceWorkerRegistration,
    updateServiceWorker,
  }
}

function SWProvider(props) {
  const [serviceWorkerInitializedState, setServiceWorkerInitialized] =
    React.useState(false)
  const [serviceWorkerUpdatedState, setServiceWorkerUpdated] =
    React.useState(false)
  const [serviceWorkerRegistrationState, setServiceWorkerRegistration] =
    React.useState(null)

  React.useLayoutEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onSuccess: () => {
        setServiceWorkerInitialized(true)
      },
      onUpdate: (reg) => {
        setServiceWorkerRegistration(reg)
        setServiceWorkerUpdated(true)
      },
    })
  }, [])

  const serviceWorkerInitialized = React.useMemo(
    () => [serviceWorkerInitializedState, setServiceWorkerInitialized],
    [serviceWorkerInitializedState]
  )
  const serviceWorkerUpdated = React.useMemo(
    () => [serviceWorkerUpdatedState, setServiceWorkerUpdated],
    [serviceWorkerUpdatedState]
  )
  const serviceWorkerRegistration = React.useMemo(
    () => [serviceWorkerRegistrationState, setServiceWorkerRegistration],
    [serviceWorkerRegistrationState]
  )
  return (
    <SWContext.Provider
      value={{
        serviceWorkerInitialized,
        serviceWorkerUpdated,
        serviceWorkerRegistration,
      }}
      {...props}
    />
  )
}

export { SWProvider, useSW }
