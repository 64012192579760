import React from "react"

// styles
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { LoadingOutlined } from "@ant-design/icons"

const Component = styled.div`
  label: component-skeleton;

  position: relative;

  ${({ theme, block }) =>
    block
      ? null
      : theme.media(
          ">tablet",
          css`
            margin-right: 25%;
          `
        )};
`

const ComponentLoaderIcon = styled.div`
  label: component-skeleton--icon;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ComponentLine = styled.div`
  label: component-skeleton--line;

  max-width: 100%;

  height: ${({ height = "24px" }) => height};
  width: ${({ width }) => width + "%"};
  opacity: ${({ opacity = 0.25 }) => opacity};
  margin: ${({ margin = "12px 0" }) => margin};

  ${({ isLoading, theme }) => (isLoading ? theme.skeleton.loading : null)};
`

const Skeleton = ({
  block,
  loading = true,
  lines = [85, 100, 100, 100, 100, 100, 100, 100, 70],
  opacity,
  ...rest
}) => {
  return (
    <Component block={block} data-testid="skeleton">
      <ComponentLoaderIcon>
        <LoadingOutlined style={{ fontSize: 24 }} />
      </ComponentLoaderIcon>

      {lines.map((length, index) => (
        <ComponentLine
          key={index}
          width={length}
          isLoading={loading}
          opacity={opacity}
          {...rest}
        />
      ))}
    </Component>
  )
}

export default Skeleton
