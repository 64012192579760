import React from "react"

// styles
import styled from "@emotion/styled"

// components
import { Flex, Dropdown, Input, List, Typo } from "components"

// translation
import useTranslate from "hooks/useTranslate"

// icons
import { ReactComponent as IconLupe } from "assets/icons/icon-lupe-simple.svg"
import { ReactComponent as IconClose } from "assets/icons/icon-close-filled.svg"

const Component = styled.div`
  label: component-search-bar;
  height: 36px;
  background-color: ${({ theme }) => theme.backgroundColorExtraLight};

  overflow: hidden;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borderColorDefault};

  .component-search-bar--close {
    svg {
      opacity: 0;

      transition: opacity 200ms;

      margin-top: 6px;
      margin-right: 8px;
      cursor: pointer;

      rect {
        opacity: 0.7;
      }

      &:hover {
        rect {
          opacity: 0.9;
        }
      }
    }
  }

  &:hover {
    .component-search-bar--close {
      svg {
        opacity: 1;
      }
    }
  }

  .component-input {
    outline: none !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
`

const DropdownContainer = styled.div`
  label: component-search-bar--dropdown;

  background-color: ${({ theme }) => theme.backgroundColorExtraLighter};
  border-right: 1px solid ${({ theme }) => theme.borderColorDefault};

  height: 34px;
  padding-right: 8px;

  display: flex;
`

const ListHeader = styled.div`
  label: component-search-bar--list-header;

  background-color: ${({ theme }) => theme.backgroundColorExtraLighter};
  border-bottom: 1px solid ${({ theme }) => theme.borderColorDefault};

  height: 34px;
  padding: 0 8px !important;
  margin: 0;

  display: flex;
  align-items: center;
`

const DropdownContent = styled.div`
  label: component-search-bar--dropdown;

  background-color: ${({ theme }) => theme.backgroundColorDefault};
  border: 1px solid ${({ theme }) => theme.borderColorDefault};
  overflow: hidden;

  border-radius: 8px;
  min-width: 300px;

  .component-list {
    .ant-list-header {
      padding: 0;
    }
    .ant-list-item {
      padding: 8px;

      cursor: pointer;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.borderColorDefault};
      }

      &:hover {
        background-color: ${({ theme }) => theme.backgroundColorExtraLight};
      }
    }
  }
`

const SearchBar = ({ value, onChange, placeholder, items, width = "100%" }) => {
  const { t } = useTranslate(["ui", "common"])

  // const [visible, setVisible] = React.useState(false)
  const [selection, setSelection] = React.useState(null)
  const search = React.useRef("")
  const inputRef = React.useRef()

  return (
    <Component data-testid="search-bar">
      <Flex.Row align="middle" fullHeight>
        {items && items.length > 0 && (
          <Flex.Col>
            <DropdownContainer>
              <Dropdown
                placement="bottomLeft"
                overlay={
                  <DropdownContent>
                    <List
                      header={
                        <ListHeader>
                          <Typo.Text font="bold600">
                            {t("ui:input.types.search.label", {
                              context: "by",
                              item: "",
                            })}
                          </Typo.Text>
                        </ListHeader>
                      }
                      dataSource={items}
                      renderItem={(item) => (
                        <List.Item
                          onClick={() => {
                            setSelection(item.label.toLowerCase())
                            // setVisible(false)
                            inputRef.current.focus()

                            onChange({
                              value: search.current,
                              filter: item.value,
                            })
                          }}
                        >
                          <Flex.Row
                            gutter={16}
                            align="middle"
                            justify="space-between"
                            fullHeight
                            fullWidth
                          >
                            <Flex.Col>
                              <Typo.Text color="textColorLight" size="small">
                                ...{item.label}
                              </Typo.Text>
                            </Flex.Col>
                            {item.info && (
                              <Flex.Col>
                                <Typo.Text
                                  color="textColorLighter"
                                  size="small"
                                  fontStyle="italic"
                                >
                                  {item.info}
                                </Typo.Text>
                              </Flex.Col>
                            )}
                          </Flex.Row>
                        </List.Item>
                      )}
                    />
                  </DropdownContent>
                }
              >
                <span
                  data-testid="search-bar-selector"
                  style={{ padding: "0 8px" }}
                  onClick={() => {
                    // setVisible(true)
                  }}
                >
                  {t("ui:input.types.search.label", {
                    context: "by",
                    item: selection,
                  })}
                </span>
              </Dropdown>
            </DropdownContainer>
          </Flex.Col>
        )}
        <Flex.Col>
          <IconLupe style={{ marginLeft: "8px" }} />
        </Flex.Col>
        <Flex.Col
          flex="1"
          onClick={() => {
            // if (!selection) {
            //   setVisible(true)
            // } else {
            //   setVisible(false)
            // }
          }}
        >
          <Input
            data-testid="search-bar-input"
            ref={inputRef}
            style={{ minWidth: width }}
            value={items && items.length > 0 ? value?.value || "" : value}
            onChange={(e) => {
              const value = e.target.value
              search.current = value

              onChange(
                items && items.length > 0 ? { value, filter: selection } : value
              )
            }}
            placeholder={t("ui:input.types.search.placeholder")}
          />
        </Flex.Col>
        <Flex.Col className="component-search-bar--close">
          <IconClose
            onClick={() => {
              setSelection(null)
              onChange(
                items && items.length > 0 ? { value: "", filter: null } : ""
              )
            }}
          />
        </Flex.Col>
      </Flex.Row>
    </Component>
  )
}

export default SearchBar
