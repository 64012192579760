import React from "react"

// styles
import { css } from "@emotion/core"
import styled from "@emotion/styled"

// components
import { Spinner, Tooltip, Button } from "components"
import GrowthLabel from "./components/GrowthLabel"
import InputNumber from "./components/InputNumber"

// utils
import { get, isEqual, pick } from "lodash"
import { renderAnalyticValue } from "utils/functions"

// translation
import useTranslate from "hooks/useTranslate"

// assets
import { ReactComponent as IconDots } from "assets/icons/icon-dots.svg"

const MainKPIBox = styled.div`
  label: component-kpi;

  width: 100%;

  ${({ theme, isLoading }) =>
    isLoading
      ? css`
          padding: 30px 20px;
          ${theme.skeleton.loading}
        `
      : null}
`

const KpiTitle = styled.div`
  label: component-kpi--title;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};

  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.textColorDefault};

  margin-bottom: 8px;
`

const KpiTitleLabel = styled.span`
  label: component-kpi--title-label;
`

const KpiTitleIcon = styled.span`
  label: component-kpi--title-icon;
  margin-right: 8px;
  margin-top: 3px;
  display: ${({ visible }) => (visible ? "inline" : "none")};
`

const KpiBody = styled.div`
  label: component-kpi--body;
  display: ${({ visible }) => (visible ? "block" : "none")};
  opacity: ${({ active }) => (active ? "1" : "0.3")};
  transition: opacity 300ms;
  will-change: opacity;
`

const KpiValue = styled.div`
  label: component-kpi--value;
  height: 44px;
  margin: 4px 0px;
  padding: 5px 0px;

  ${({ theme }) => theme.font.typo.bold800};
  font-size: ${({ theme }) => theme.font.size.extraBig};
  line-height: 110%;
  letter-spacing: -0.02em;
  color: ${({ theme, disabled }) =>
    disabled ? theme.textColorLighter : theme.textColorDefault};
`

const BoxesList = styled.div`
  label: component-kpi--box-container;
  margin-top: 6px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`

const Box = styled.div`
  label: component-kpi--box;

  background: ${({ theme, background = "neutral" }) =>
    background === "neutral"
      ? theme.backgroundColorExtraLight
      : background === "positive"
      ? theme.backgroundColorPositive
      : background === "negative"
      ? theme.backgroundColorNegative
      : null};
  padding: 8px;
  border-radius: 6px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`

const BoxItem = styled.div`
  label: component-kpi--box-list-item;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme, level }) => {
    switch (level) {
      case 1:
        return theme.textColorDefault
      case 2:
        return theme.textColorLighter
      case 3:
        return theme.textColorExtraLight

      default:
        return theme.textColorDefault
    }
  }};
  line-height: 140%;
  letter-spacing: 0.03em;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`

const BoxItemLabel = styled.span`
  label: component-kpi--box-list-item-label;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.small};
  color: inherit;
  line-height: 110%;

  letter-spacing: 0.02em;

  ${({ selectable }) =>
    selectable
      ? css`
          opacity: 0.8;
          cursor: pointer;

          &:hover {
            text-decoration-line: underline;
            opacity: 1;
          }
        `
      : null}
`

const Footer = styled.footer`
  label: component-kpi--footer;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 130%;
  letter-spacing: 0.03em;
`

const Header = styled.header`
  label: component-kpi--header;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};

  line-height: 130%;
  letter-spacing: 0.03em;
`
const KPIAction = styled.div`
  label: component-kpi--action;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -4px;
  margin-bottom: -4px;

  svg {
    cursor: pointer;
    transition: transform 200ms;

    &:hover {
      transform: scale(1.2);
    }
  }
`

const CustomTooltipContent = styled.div`
  label: component-custom-tooltip-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin-bottom: 8px;
  }
`

const ApplyAction = ({ onAction }) => {
  const { t } = useTranslate(["ui"])
  const [state, setState] = React.useState(false)

  return (
    <KPIAction>
      <Tooltip
        visible={state}
        onVisibleChange={(e) => setState(e)}
        placement="bottom"
        trigger="click"
        title={
          <CustomTooltipContent>
            <p>{t("ui:dialog.types.apply-objective.text")}</p>
            <Button
              label={t("ui:button.apply.label")}
              type="primary"
              onClick={() => {
                setState(false)
                onAction()
              }}
            />
          </CustomTooltipContent>
        }
      >
        <IconDots />
      </Tooltip>
    </KPIAction>
  )
}

const KPI = ({
  name,
  title,
  value,
  renderValue = (v) => v,
  boxes = [],
  disabled,
  active = true,
  loading,
  footer,
  header,
  showBoxes = [],
  previousfiscalYearValues,
  previousYears,
  unit,
  min = undefined,
  max = undefined,
  onChange,
  onAction,
  previousValueProp = "value",
  growthRenderConfig = {},
  invertColorSchema = false,
  allowDecimals = false,
  precision = 0,
}) => {
  const { t } = useTranslate(["generic"])

  const renderYearList = Array.isArray(previousYears)
    ? previousYears
    : [previousYears]
  const lastYear = renderYearList[0].value
  const renderPreviousFiscalYearValue = Array.isArray(previousfiscalYearValues)
    ? previousfiscalYearValues
    : [{ value: previousfiscalYearValues, fiscal_year_key: lastYear }]

  const getValueByYear = (year) => {
    return renderPreviousFiscalYearValue
      ? renderPreviousFiscalYearValue.find(
          (item) => item?.fiscal_year_key === year
        )
      : null
  }

  const boxesBefore = []
  const boxesAfter = []

  boxes.forEach((box) => {
    if (box.before) {
      boxesBefore.push(box)
    } else {
      boxesAfter.push(box)
    }
  })

  const growthValueFormatterConfig = {
    useGrouping: ["currency", "currency-per-hour"].includes(unit),
    ...(unit === "currency" &&
      value > 100000000 && {
        notation: "compact",
        compactDisplay: "short",
      }: {}),
    ...growthRenderConfig,
  }

  const renderBoxes = [
    ...boxesBefore,
    ...(() => {
      if (!showBoxes.includes("growth")) return []

      const previousYearValue = get(getValueByYear(lastYear), previousValueProp)
      const growthDirection = invertColorSchema ? -1 : 1
      const growth =
        previousYearValue === undefined
          ? 0
          : Math.sign(value - previousYearValue) * growthDirection

      return [
        {
          name: "standard-growth-box",
          background:
            growth === 0 ? "neutral" : growth > 0 ? "positive" : "negative",
          content: [
            {
              id: "growth",
              level: 1,
              label: t("generic:growth.label", { context: "aimed" }),
              value: (
                <GrowthLabel
                  current={value}
                  previous={previousYearValue}
                  formatter={(v) =>
                    renderAnalyticValue(v, {
                      unit,
                      config: growthValueFormatterConfig,
                    })
                  }
                  showInitialValue={false}
                  showGrowthValue={true}
                />
              ),
            },
          ],
        },
      ]
    })(),
    ...(showBoxes.includes("fiscal-year-comparision")
      ? [
          {
            name: "standard-comparision-box",
            content: renderYearList.map((year, index, arr) => ({
              id: `comparision-year-${year.label}`,
              level: index === 0 ? 1 : 2,
              label: year.label,
              value: (
                <GrowthLabel
                  current={get(getValueByYear(year.value), previousValueProp)}
                  previous={
                    arr[index + 1]
                      ? get(
                          getValueByYear(arr[index + 1].value),
                          previousValueProp
                        )
                      : null
                  }
                  formatter={(v) =>
                    renderAnalyticValue(v, {
                      unit,
                      config: growthValueFormatterConfig,
                    })
                  }
                  showInitialValue={true}
                  showGrowthValue={false}
                />
              ),
            })),
          },
        ]
      : []),
    ...boxesAfter,
  ]

  return (
    <MainKPIBox
      isLoading={loading}
      data-testid="kpi"
      data-isloading={loading ? "true" : "false"}
    >
      <KpiTitle>
        <KpiTitleIcon visible={loading}>
          <Spinner />
        </KpiTitleIcon>
        <KpiTitleLabel>{title}</KpiTitleLabel>
      </KpiTitle>

      <KpiBody visible={!loading} active={active}>
        {onChange && !disabled && (
          <InputNumber
            min={min}
            max={max}
            value={value}
            onChange={onChange}
            formatter={(value) => {
              const number = Number(value !== "" ? value : undefined)
              const formatted = renderAnalyticValue(number, {
                unit,
                alwaysShowUnits: true,
                precision,
                config: {
                  useGrouping: ["currency", "currency-per-hour"].includes(unit),
                },
              })
              return formatted
            }}
            parser={(value) => {
              let sanitized = value
                .replace(/,/g, "")
                .replace(/\//g, "")
                .replace(/%/g, "")
                .replace(/\$/g, "")
                .replace(/h/g, "")
                .replace(/n/g, "")
                .replace(/p/g, "")
                .replace(/ /g, "")
                .replace(/–/g, "")
                .replace(/x/g, "")

              if (!allowDecimals) {
                sanitized = sanitized.replace(/\./g, "")
              }
              return sanitized
            }}
          />
        )}

        {(!onChange || disabled) && (
          <KpiValue disabled={disabled}>
            {renderValue(
              renderAnalyticValue(value, {
                unit,
                config: {
                  useGrouping: ["currency", "currency-per-hour"].includes(unit),
                },
              })
            )}
          </KpiValue>
        )}

        {value !== undefined && onAction && !(disabled || !onChange) && (
          <ApplyAction onAction={onAction} />
        )}

        {header && <Header>{header}</Header>}

        {renderBoxes.length ? (
          <BoxesList>
            {renderBoxes.map(
              ({
                name,
                content,
                background,
                selectable = false,
                onSelect = () => null,
              }) => {
                return (
                  <Box key={name} background={background}>
                    {content.map((item, index) => {
                      if (React.isValidElement(item)) {
                        return (
                          <BoxItem key={index} level={1}>
                            {item}
                          </BoxItem>
                        )
                      } else if (typeof item === "object") {
                        const { id, label, key, value, level = 1 } = item

                        return (
                          <BoxItem
                            key={id ? id : index}
                            level={level}
                            onClick={() => selectable && onSelect(id, item)}
                          >
                            <BoxItemLabel selectable={selectable}>
                              {`${label ? label : key}: `}
                            </BoxItemLabel>
                            {value}
                          </BoxItem>
                        )
                      }
                      return null
                    })}
                  </Box>
                )
              }
            )}
          </BoxesList>
        ) : null}
        {footer && <Footer>{footer}</Footer>}
      </KpiBody>
    </MainKPIBox>
  )
}

const CachedKPI = React.memo(KPI, (prevProps, nextProps) => {
  const checkPropNames = [
    "value",
    "loading",
    "boxes",
    "previousfiscalYearValues",
    "previousYears",
  ]

  return isEqual(
    pick(prevProps, checkPropNames),
    pick(nextProps, checkPropNames)
  )
})

export default CachedKPI
