import { css } from "@emotion/core"

import FontRegular from "assets/fonts/Proxima Nova Regular.otf"
import SemiBoldRegular from "assets/fonts/Proxima Nova Semibold.otf"
import BoldRegular from "assets/fonts/Proxima Nova Bold.otf"
import ExtraBoldRegular from "assets/fonts/Proxima Nova Extrabold.otf"

export default css`
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    src: local("Proxima Nova"), local("ProximaNova"),
      url("${FontRegular}") format("opentype");
  }
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    src: local("Proxima Nova"), local("ProximaNova"),
      url("${SemiBoldRegular}") format("opentype");
  }
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    src: local("Proxima Nova"), local("ProximaNova"),
      url("${BoldRegular}") format("opentype");
  }
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 800;
    src: local("Proxima Nova"), local("ProximaNova"),
      url("${ExtraBoldRegular}") format("opentype");
  }

  body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100vh;
    width: 100vw;
  }

  .firebase-emulator-warning {
    background-color: transparent !important;
    border: none !important;
  }

  a {
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  ul,
  figure {
    margin: 0;
    padding: 0;
  }
`
