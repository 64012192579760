const breakpoints = {
  phone: "320px",
  note: "420px",
  tablet: "768px",
  desktop: "1208px",
}

export default (mediaQueryString = "<desktop") => {
  // first character defines gt or lt
  // rest defines device or breakpoint pixels
  // e.g.: <desktop or <1440
  const boundary = mediaQueryString.slice(0, 1)
  const device = mediaQueryString.slice(1)

  const minOrMax = boundary === "<" ? "max" : "min"

  const breakpoint = breakpoints[device] ?? device

  return `@media (${minOrMax}-width: ${breakpoint})`
}
