// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 10,
  height = 6,
  color = "themeColorDefault",
  type = "right",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform={`rotate(${
          type === "left"
            ? 180
            : type === "top"
            ? -90
            : type === "bottom"
            ? 90
            : 0
        })`}
        transform-origin="center"
        strokeWidth="0.5"
        stroke={theme[color]}
        fill={theme[color]}
        d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM0.646447 1.35355L4.64645 5.35355L5.35355 4.64645L1.35355 0.646447L0.646447 1.35355ZM5.35355 5.35355L9.35355 1.35355L8.64645 0.646447L4.64645 4.64645L5.35355 5.35355Z"
      />
    </svg>
  )
}
