import React from "react"

// components
import { Tooltip, Space, Popover, Flex, Tag } from "components"

// styles
import { css } from "@emotion/core"
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"

// assets
import { ReactComponent as QuestionMark } from "assets/icons/icon-questionmark-grey.svg"

const Component = styled.div`
  label: component-tag-select;
  background-color: ${({ theme, backgroundless, disabled }) =>
    !disabled && !backgroundless
      ? theme.backgroundColorExtraLight
      : "transparent"};

  ${({ block }) =>
    block
      ? css`
          display: block;
          width: 100%;
        `
      : css`
          display: inline-block;
        `}

  position: relative;

  ${({ disabled, backgroundless, theme }) =>
    !disabled && !backgroundless
      ? css`
          &:hover {
            background-color: ${theme.backgroundColorLighter};
          }
        `
      : null}
`

const AddTag = styled.div`
  label: component-tag-select--add-item;

  display: inline-flex;
  flex-direction: center;
  align-items: center;
  padding: 1px 8px;

  background-color: ${({ theme }) => theme.backgroundColorDefault};
  border: ${({ theme }) => `1px solid ${theme.borderColorLight}`};
  transition: background-color 300ms;

  border-radius: ${({ round }) => (round ? "12px" : "6px")};

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorLight};

  margin: 2px;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColorExtraLight};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
        `
      : null}
`

const NoDataTag = styled.div`
  label: component-tag-select--no-data;

  display: inline-flex;
  flex-direction: center;
  align-items: center;
  padding: 1px 8px;

  background-color: ${({ theme }) => theme.backgroundColorDefault};
  border: ${({ theme }) => `1px solid ${theme.borderColorLight}`};
  border-radius: ${({ round }) => (round ? "12px" : "6px")};

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorLighter};

  cursor: default;

  margin: 2px;
`

const Selection = styled.div`
  label: component-tag-select--selection;

  max-height: 300px;
  max-width: 300px;

  overflow-y: scroll;

  background-color: ${({ theme }) => theme.backgroundColorDefault};
`

const Group = styled.div`
  label: component-tag-select--group;
  margin-bottom: 8px;
`

const GroupBody = styled.div`
  label: component-tag-select--group-body;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
`

const GroupLabel = styled.div`
  label: component-tag-select--group-label;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorLighter};

  font-style: italic;

  background-color: ${({ theme }) => theme.backgroundColorExtraLight};
  padding: 4px 8px;
`

const TagSelect = ({
  backgroundless,
  block = true,
  addLabel,
  emptyLabel,
  defaultValue,
  value = [],
  tagRender,
  items,
  disabled,
  round,
  noData,
  onChange = () => null,
  onSelect = () => null,
  onRemove = () => null,
  ...rest
}) => {
  const { t } = useTranslate(["ui"])

  const [state, setState] = React.useState()
  const [showList, setShowList] = React.useState(false)

  React.useEffect(() => {
    if (defaultValue) setState(defaultValue)
  }, [defaultValue])

  const groupedItems = React.useMemo(
    () => [
      ...items.filter((i) => i.group),
      { group: "undefined", label: null, items: items.filter((i) => !i.group) },
    ],
    [items]
  )

  const availableTagGroups = React.useMemo(
    () =>
      groupedItems
        .map((group) => ({
          ...group,
          items: group.items.filter(
            (item) =>
              !(state || value).includes(item.id || item.value || item.key) &&
              !item.disabled
          ),
        }))
        .filter((group) => group.items.length > 0),
    [state, value, groupedItems]
  )

  React.useEffect(() => {
    if (availableTagGroups.length === 0) setShowList(false)
  }, [availableTagGroups])

  return (
    <Component
      disabled={disabled}
      backgroundless={backgroundless}
      block={block}
      className="component-tag-select"
      data-testid="tag-select"
    >
      <Popover
        visible={showList}
        onVisibleChange={(visible) =>
          visible
            ? null
            : setShowList(availableTagGroups.length !== 0 && visible)
        }
        trigger="click"
        placement="bottomLeft"
        content={
          <Selection
            data-testid="tag-select-dropdown"
            data-visible={showList ? "visible" : "hidden"}
          >
            {availableTagGroups.map((group) => {
              if (group.items.length === 0) return null

              return (
                <Group key={group.group}>
                  {group.label && <GroupLabel>{group.label}</GroupLabel>}
                  <GroupBody>
                    {group.items.map((item) =>
                      tagRender ? (
                        tagRender(item)
                      ) : (
                        <Tag
                          key={item.id || item.value || item.key}
                          color={item.color}
                          borderColor={item.borderColor}
                          round={round || item.round}
                          label={item.label}
                          icon={item.icon}
                          onClick={() => {
                            const newValue = [
                              ...(state || value),
                              item.id || item.value || item.key,
                            ]

                            if (state) {
                              setState(newValue)
                            }
                            onSelect(item.id || item.value || item.key, item)
                            onChange(newValue)
                          }}
                        />
                      )
                    )}
                  </GroupBody>
                </Group>
              )
            })}
          </Selection>
        }
      >
        <Flex.Row wrap>
          {(state || value).map((itemId) => {
            const defaultTag = { label: itemId, id: itemId, value: itemId }

            const tag =
              groupedItems
                .map((group) => group.items)
                .flat()
                .find(
                  (i) =>
                    itemId === i.key || itemId === i.id || itemId === i.value
                ) || defaultTag

            return tagRender ? (
              tagRender(tag)
            ) : (
              <Tag
                key={itemId}
                color={tag.color}
                borderColor={tag.borderColor}
                round={round || tag.round}
                label={tag.label}
                icon={tag.icon}
                onClose={
                  disabled
                    ? null
                    : () => {
                        const newValue = (state || value).filter(
                          (i) => i !== itemId
                        )

                        if (state) {
                          setState(newValue)
                        }

                        onChange(newValue)
                        onRemove(itemId)
                      }
                }
              />
            )
          })}

          {value.length === 0 && availableTagGroups.length === 0 ? (
            <NoDataTag round={round} data-testid="tag-select-no-data">
              {emptyLabel ||
                t("common:not-available.label", { context: "data" })}
            </NoDataTag>
          ) : (
            !disabled && (
              <Space size={1}>
                <AddTag
                  onClick={() => setShowList(true)}
                  round={round}
                  disabled={availableTagGroups.length === 0}
                  data-testid="tag-select-add-button"
                >
                  {addLabel || t("ui:button.add.label", { context: "plus" })}
                </AddTag>
                {availableTagGroups.length === 0 && (
                  <Tooltip
                    placement="bottom"
                    title={
                      noData ||
                      t("common:not-available.label", { context: "data" })
                    }
                  >
                    <QuestionMark />
                  </Tooltip>
                )}
              </Space>
            )
          )}
        </Flex.Row>
      </Popover>
    </Component>
  )
}

export default TagSelect
