// styles
import { useTheme } from "emotion-theming"

export default ({
  width = 16,
  height = 16,
  color = "themeColorDefault",
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="15.95 14 12.1 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.344 16.048V17.264C27.344 18.544 26.768 19.824 25.744 20.656L24.272 22L25.744 23.344C26.704 24.24 27.28 25.52 27.28 26.864V28.016C27.28 28.048 27.28 28.064 27.288 28.072C27.296 28.08 27.312 28.08 27.344 28.08C27.728 28.08 27.984 28.4 27.984 28.72V29.36C27.984 29.68 27.728 30 27.344 30H16.592C16.272 30 15.952 29.744 15.952 29.36V28.72C15.952 28.4 16.272 28.08 16.592 28.08V28.016V26.864C16.592 25.52 17.168 24.24 18.128 23.408L19.6 22.064L18.128 20.72C17.168 19.824 16.592 18.544 16.592 17.2V16.048V15.92C16.208 15.92 15.952 15.6 15.952 15.28V14.64C15.952 14.32 16.272 14 16.592 14H27.408C27.728 14 28.048 14.256 28.048 14.64V15.344C28.048 15.664 27.728 15.984 27.408 15.984C27.376 15.984 27.36 15.984 27.352 15.992C27.344 16 27.344 16.016 27.344 16.048ZM22.672 22.064C22.672 21.872 22.736 21.68 22.864 21.552L24.912 19.76C25.616 19.12 26 18.16 25.936 17.264V16.176C25.936 16.112 25.872 16.048 25.808 16.048H18.064C18 16.048 17.936 16.112 17.936 16.176V17.264C17.936 18.16 18.384 19.12 19.088 19.76L21.072 21.488C21.2 21.616 21.264 21.808 21.264 22C21.264 22.192 21.2 22.384 21.072 22.512L19.088 24.368C18.384 25.008 18 25.904 18 26.864V27.952C18 28.016 18.064 28.08 18.128 28.08H18.576C18.576 28.08 18.64 28.08 18.64 28.016L21.328 24.688C21.648 24.304 22.288 24.304 22.608 24.688L25.296 28.016C25.3328 28.0528 25.3484 28.0684 25.3673 28.0751C25.3812 28.08 25.3968 28.08 25.424 28.08H25.872C25.936 28.08 26 28.016 26 27.952V26.864C26 25.904 25.616 25.008 24.912 24.368L22.864 22.576C22.736 22.448 22.672 22.256 22.672 22.064ZM20.4319 18.5457H23.5679C24.1439 18.5457 24.3999 19.3137 24.0159 19.6977L22.5439 21.0417C22.2239 21.2977 21.7759 21.2977 21.4559 21.0417L19.9839 19.6977C19.5359 19.3137 19.7919 18.5457 20.4319 18.5457Z"
        fill={theme[color]}
      />
    </svg>
  )
}
