import React from "react"

// styles
import styled from "@emotion/styled"

const ArthurComponents = styled.div`
  label: component-arthur;
`

const Arthur = () => {
  return <ArthurComponents />
}

const ArthurIndice = styled.div`
  label: component-arthur--indice;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDark};

  letter-spacing: 0.02em;
  text-transform: uppercase;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 100%;

  background-color: ${({ theme, type }) => {
    switch (type) {
      case "a":
        return theme.markerColorGreen
      case "b":
        return theme.markerColorTeal
      case "c":
        return theme.markerColorGold
      case "d":
        return theme.markerColorPink
      case "e":
        return theme.markerColorOrange

      default:
        return theme.markerColorGreen
    }
  }};
`

const Indice = ({ label, type, ...rest }) => {
  if (!["a", "b", "c", "d", "e"].includes(type)) {
    console.error("Arthur Indice not supported type")
    return null
  }

  return (
    <ArthurIndice type={type} {...rest}>
      {label}
    </ArthurIndice>
  )
}

const ArthurStatusTag = styled.div`
  label: component-arthur--status-tag;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDark};

  letter-spacing: 0.02em;
  text-transform: uppercase;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 6px;
  overflow: hidden;
  border-radius: 10px;

  background-color: ${({ theme, type }) => {
    switch (type) {
      case "open":
        return theme.markerColorGrey
      case "accepted":
        return theme.markerColorGreen
      case "refused":
        return theme.markerColorPink
      case "message-left":
        return theme.markerColorOrange

      default:
        return theme.markerColorGreen
    }
  }};
`

const StatusTag = ({ status, label, ...rest }) => {
  if (!["open", "accepted", "message-left", "refused"].includes(status)) {
    console.error("Arthur StatusTag not supported status")
    return null
  }

  return (
    <ArthurStatusTag status={status} {...rest}>
      {label}
    </ArthurStatusTag>
  )
}

Arthur.Indice = Indice
Arthur.StatusTag = StatusTag

export default Arthur
