import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Checkbox as Component } from "antd"

const styling = (theme) => css`
  lable: component-checkbox--styling;

  ${theme.font.typo.bold600};
  font-size: ${theme.font.size.default};
  color: ${theme.textColorDefault};
`

const Checkbox = ({ label, ...rest }) => {
  return (
    <Component
      css={styling}
      data-testid="checkbox"
      className="component-checkbox"
      {...rest}
    >
      {label}
    </Component>
  )
}

Checkbox.Group = Component.Group

export default Checkbox
