import moment from "moment"

// logs
import debugModule from "utils/debug"
const debug = debugModule("getTimeRange.js", false)

export const getTimeRange = (timeKey, options) => {
  const startDateKey = `${new Date().getFullYear()}/${(
    options.fiscalYearStartDateMonthKey || 1
  ).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })}/${(options.fiscalYearStartDateDayKey || 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })}`
  const currentFiscalYearStartDate = new Date(startDateKey)

  const yearBase = moment(currentFiscalYearStartDate).isBefore(moment())
    ? 0
    : -1

  debug.log({ startDateKey, options, currentFiscalYearStartDate, yearBase })

  switch (timeKey) {
    case "this-fyear": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase + 1, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-fyear": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 1, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-2-fyears": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 2, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-3-fyears": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 3, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-4-fyears": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 4, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-fyear": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 1, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase + 1, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-2-fyears": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 2, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase + 1, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-3-fyears": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 3, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase + 1, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-4-fyears": {
      return {
        start_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase - 4, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment(currentFiscalYearStartDate)
          .add(yearBase + 1, "year")
          .add(-1, "day")
          .format("YYYY-MM-DD"),
      }
    }

    case "today": {
      return {
        start_date_key: moment().format("YYYY-MM-DD"),
        end_date_key: moment().format("YYYY-MM-DD"),
      }
    }
    case "yesterday": {
      return {
        start_date_key: moment().add(-1, "day").format("YYYY-MM-DD"),
        end_date_key: moment().add(-1, "day").format("YYYY-MM-DD"),
      }
    }
    case "this-week": {
      return {
        start_date_key: moment().startOf("week").format("YYYY-MM-DD"),
        end_date_key: moment().endOf("week").format("YYYY-MM-DD"),
      }
    }

    case "next-week": {
      return {
        start_date_key: moment()
          .startOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .endOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD"),
      }
    }
    case "next-2-weeks": {
      return {
        start_date_key: moment()
          .startOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .endOf("week")
          .add(2, "week")
          .format("YYYY-MM-DD"),
      }
    }
    case "next-3-weeks": {
      return {
        start_date_key: moment()
          .startOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .endOf("week")
          .add(3, "week")
          .format("YYYY-MM-DD"),
      }
    }
    case "next-4-weeks": {
      return {
        start_date_key: moment()
          .startOf("week")
          .add(1, "week")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .endOf("week")
          .add(4, "week")
          .format("YYYY-MM-DD"),
      }
    }

    case "this-month": {
      return {
        start_date_key: moment().startOf("month").format("YYYY-MM-DD"),
        end_date_key: moment().endOf("month").format("YYYY-MM-DD"),
      }
    }
    case "this-quarter": {
      return {
        start_date_key: moment().startOf("quarter").format("YYYY-MM-DD"),
        end_date_key: moment().endOf("quarter").format("YYYY-MM-DD"),
      }
    }
    case "this-year": {
      return {
        start_date_key: moment().startOf("year").format("YYYY-MM-DD"),
        end_date_key: moment().endOf("year").format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-year": {
      return {
        start_date_key: moment()
          .startOf("year")
          .add(-1, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment().endOf("year").format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-2-years": {
      return {
        start_date_key: moment()
          .startOf("year")
          .add(-2, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment().endOf("year").format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-3-years": {
      return {
        start_date_key: moment()
          .startOf("year")
          .add(-3, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment().endOf("year").format("YYYY-MM-DD"),
      }
    }
    case "this-and-last-4-years": {
      return {
        start_date_key: moment()
          .startOf("year")
          .add(-4, "year")
          .format("YYYY-MM-DD"),
        end_date_key: moment().endOf("year").format("YYYY-MM-DD"),
      }
    }
    case "last-7-days": {
      return {
        start_date_key: moment().add(-7, "day").format("YYYY-MM-DD"),
        end_date_key: moment().format("YYYY-MM-DD"),
      }
    }
    case "last-30-days": {
      return {
        start_date_key: moment().add(-30, "day").format("YYYY-MM-DD"),
        end_date_key: moment().format("YYYY-MM-DD"),
      }
    }
    case "last-week": {
      return {
        start_date_key: moment()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-month": {
      return {
        start_date_key: moment()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-12-months": {
      return {
        start_date_key: moment()
          .add(-12, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-12-months-until-now": {
      return {
        start_date_key: moment()
          .add(-12, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date_key: moment().format("YYYY-MM-DD"),
      }
    }
    case "last-quarter": {
      return {
        start_date_key: moment()
          .add(-1, "quarter")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .add(-1, "quarter")
          .endOf("quarter")
          .format("YYYY-MM-DD"),
      }
    }
    case "last-year": {
      return {
        start_date_key: moment()
          .add(-1, "year")
          .startOf("year")
          .format("YYYY-MM-DD"),
        end_date_key: moment()
          .add(-1, "year")
          .endOf("year")
          .format("YYYY-MM-DD"),
      }
    }

    default:
      return new Error(`timekey "${timeKey}" invalid option!`)
  }
}
