// import React from "react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"

const generateSWRCacheKey = ({ clinicId }) => {
  return clinicId
    ? () =>
        `/clinic?clinic_id=eq.${clinicId}&select=
acronym,
arthur_representative,
clinic_id,
close_time_slots_on_comment,
close_time_slots_on_comment_minimum_duration,
corporate_clinic_group_id,
fiscal_year_start_date_day_key,
fiscal_year_start_date_month_key,
operating_rooms_count,
include_treatment_plan_statistics,
slug,
name,
postal_address_id,
comment_patients,
use_patients_as_comments,
use_schedule_track_per_operator,
team_schedule_delimiter_type`
    : null
}

const useClinic = (clinicId) => {
  const orca = useOrcaAxios()

  const fetcher = async (url) => {
    const {
      data: [data],
    } = await orca.get(url)
    return data
  }

  const { data, error, mutate, isValidating } = useSWR(
    generateSWRCacheKey({ clinicId }),
    fetcher
  )

  const update = async (values) => {
    const {
      data: [data],
    } = await orca.patch(`/clinic?clinic_id=eq.${clinicId}`, values, {
      headers: {
        Prefer: "return=representation",
      },
    })

    return data
  }

  return {
    data,
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    update,
  }
}

const update = async (orca, clinicId, values) => {
  const {
    data: [data],
  } = await orca.patch(`/clinic?clinic_id=eq.${clinicId}`, values, {
    headers: {
      Prefer: "return=representation",
    },
  })

  return data
}

export { generateSWRCacheKey, update }

export default useClinic
