import React from "react"

// styles
import { css } from "@emotion/core"

// component/
import { Avatar as Component } from "antd"

// styling
const styling = (theme) => css`
  lable: component-avatar--styling;

  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`

const Avatar = React.forwardRef(({ label, ...rest }, ref) => {
  return (
    <Component css={styling} className="component-avatar" {...rest}>
      {label}
    </Component>
  )
})

export default Avatar
