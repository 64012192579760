import React from "react"

// utils
import * as fn from "utils/functions"

// services
import { FirestoreService } from "services/firebase"
import { useFirebaseAuth } from "store/firebase-auth"

// utils
import messageUtils from "utils/message"

// logs
import debugModule from "utils/debug"
const debug = debugModule("useFirestoreLoader")

const CLOCK_INTERVAL = 1000

function useFirestoreLoader({
  collectionName,
  documentId,
  onFinish,
  onDoesNotExist,
}) {
  const { firebaseUser } = useFirebaseAuth()

  const [isLoading, setIsLoading] = React.useState(false)
  const [isInitializing, setIsInitializing] = React.useState(true)
  const [progress, setProgress] = React.useState(0)
  const [message, setMessage] = React.useState(null)
  const [status, setStatus] = React.useState(null)
  const [eta, setEta] = React.useState(0)

  const currentSubscription = React.useRef()
  const currentDoc = React.useRef()

  React.useEffect(() => {
    const sessionsIsDead = currentSubscription.current && !firebaseUser
    if (sessionsIsDead) {
      debug.log(`LOGOUT! Kill Document Subscription`)
      currentSubscription.current()
      currentSubscription.current = null

      return
    }

    if (!firebaseUser) return
    if (currentDoc.current === collectionName + "/" + documentId) return

    currentDoc.current = collectionName + "/" + documentId

    debug.log(`[${collectionName}] init-loader!`)

    let clock

    setIsInitializing(true)
    setIsLoading(false)
    setProgress(0)
    setStatus(null)

    debug.log(`Init ${collectionName} Listener`)

    currentSubscription.current = FirestoreService.collection(collectionName)
      .doc(documentId)
      .onSnapshot(
        async (doc) => {
          setIsInitializing(false)

          debug.log(`[${collectionName}] data-received!`)

          if (!doc.exists) {
            debug.log(`[${collectionName}] does-not-exist!`)

            onDoesNotExist && (await onDoesNotExist())
            setStatus("idle")
            setIsLoading(false)
            clearInterval(clock)
          } else {
            const eventData = doc.data()

            setMessage(eventData.step)
            setEta(eventData.eta_seconds)

            switch (eventData.status) {
              case "starting":
                debug.log(`[${collectionName}] starting...`)
                setIsLoading(true)
                setStatus("active")
                setProgress(5)

                break

              case "running":
                debug.log(
                  `[${collectionName}] ${eventData.progress}% loading...`
                )

                setIsLoading(true)
                setStatus("active")
                setProgress((prev) =>
                  prev > eventData.progress ? prev : eventData.progress
                )
                clearInterval(clock)
                clock = eventData.eta_seconds
                  ? setInterval(() => {
                      setEta((prev) => {
                        if (prev < 0) clearInterval(clock)
                        return prev - CLOCK_INTERVAL / 1000
                      })
                    }, CLOCK_INTERVAL)
                  : null
                break

              case "success":
                debug.log(`[${collectionName}] success!`)

                setProgress(100)
                setStatus("success")
                setIsLoading(false)

                clearInterval(clock)
                break

              case "failure":
                debug.log(`[${collectionName}] error!`)

                setIsLoading(true)
                setStatus("exception")
                setProgress(eventData.progress)
                clearInterval(clock)
                break

              default:
                debug.log(`[${collectionName}] status-unkown!`)

                clearInterval(clock)
            }
          }
        },
        (e) => {
          debug.error(e)
          messageUtils.error(
            "Could not get document. Please refresh the page and retry again!",
            3
          )
        }
      )

    return () => {
      // debug.log(
      //   `%c UNMOUNTING ... KILL ${collectionName} DOC SUBSCRIPTION`,
      //   "background: #3d58e3; color: #e8e634"
      // )
      // currentSubscription.current()
      clearInterval(clock)
    }
  }, [collectionName, documentId, firebaseUser, onDoesNotExist])

  React.useEffect(() => {
    const loadingCompleted = async () => {
      if (status === "success" && isLoading) {
        debug.log(`[${collectionName}] finish!`)

        await fn.wait(200)
        onFinish && (await onFinish())
      }
    }

    loadingCompleted()
  }, [isLoading, onFinish, status, collectionName])

  React.useEffect(() => {
    return () => {
      debug.log(`UNMOUNTING: KILL ${collectionName} Document Subscription`)
      currentSubscription.current && currentSubscription.current()
    }
  }, [collectionName])

  return {
    name: collectionName,
    isInitializing,
    progress: isInitializing ? 5 : Math.max(progress, 5),
    message,
    isLoading,
    eta,
    status,
    reset: () => {
      setProgress(5)
      setIsLoading(true)
      setStatus("active")
    },
  }
}

export default useFirestoreLoader
