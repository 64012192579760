import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Calendar as Component } from "antd"

const styling = (theme) => css`
  label: component-calendar--styling;

  .ant-picker-panel .ant-picker-body {
    padding: 0;
  }

  thead {
    tr {
      th {
        text-transform: uppercase;
        text-align: center;
        ${theme.font.typo.bold600};
        font-size: ${theme.font.size.default};
        color: ${theme.textColorLight};
      }
    }
  }

  .ant-picker-panel .ant-picker-calendar-date {
    margin: 0;
    z-index: 1;
    border: 1px solid ${theme.borderColorLighter};
  }

  .ant-picker-cell-selected {
    .ant-picker-calendar-date {
      background: ${theme.backgroundColorDefault} !important;

      &.ant-picker-calendar-date-today {
        background: #e6f4ff !important;
      }
    }
  }

  .ant-picker-cell {
    .ant-picker-calendar-date-value {
      text-align: left;
      ${theme.font.typo.bold600};
      font-size: ${theme.font.size.small};
      color: ${theme.textColorUltraLight} !important;
    }

    &.ant-picker-cell-in-view {
      .ant-picker-calendar-date-value {
        color: ${theme.textColorLighter} !important;
      }
    }
  }
`

const Calendar = ({ ...rest }) => {
  return <Component css={styling} className="component-calendar" {...rest} />
}

export default Calendar
