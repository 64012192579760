import React from "react"
import { Prompt } from "react-router-dom"

// styles
import { css } from "@emotion/core"
import styled from "@emotion/styled"

// component
import { Button } from "components"

// translation
import useTranslate from "hooks/useTranslate"

const ComponentFloatButton = styled.div`
  label: component-save-button;

  background: ${({ theme }) => theme.backgroundColorDark};
  border: 1px solid ${({ theme }) => theme.borderColorDark};

  position: fixed;
  right: 0;
  bottom: 100px;
  box-sizing: content-box;
  box-shadow: 0px 4px 8px #f5f7fb;
  border-radius: 16px 0px 0px 16px;
  padding: 16px;

  width: 250px;

  transform: translateX(${({ visible }) => (visible ? 0 : "100%")});
  transition: transform 300ms;

  z-index: 1;

  ${({ theme, visible }) =>
    theme.media(
      "<tablet",
      css`
        transform: translateX(${visible ? 0 : "-100%"});
        border-radius: 16px;
        left: 15px;
        bottom: 10px;
        text-align: left;
        box-shadow: none;
        background: none;
        border: none;
        width: auto;
      `
    )};
`

const Title = styled.div`
  label: component-save-button--title;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorWhite};

  line-height: 130%;
  letter-spacing: 0.01em;

  margin-bottom: 16px;

  ${({ theme, visible }) =>
    theme.media(
      "<tablet",
      css`
        display: none;
      `
    )};
`

const SaveButton = ({
  htmlType,
  title,
  label,
  onClick,
  visible = false,
  loading = false,
}) => {
  const { t } = useTranslate(["validation"])

  return (
    <ComponentFloatButton
      visible={visible}
      data-testid="save-button"
      className={
        loading
          ? "component-floating-button-loading"
          : "component-floating-button"
      }
    >
      <Prompt
        when={visible}
        message={t(
          "validation:message.types.confirmation.__.continue-without-save.label"
        )}
      />

      <Title>{title || t("ui:button.save.label", { context: "long" })}</Title>
      <Button
        htmlType={htmlType}
        onClick={onClick}
        label={label || t("ui:button.save.label")}
        loading={loading}
        type="primary"
      />
    </ComponentFloatButton>
  )
}

export default SaveButton
