// import React from "react"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"

const generateSWRCacheKey = ({ corporateClinicGroupId }) => {
  return corporateClinicGroupId
    ? () => `/corporate_clinic_group?corporate_clinic_group_id=eq.${corporateClinicGroupId}&select=
acronym,
corporate_clinic_group_id,
fiscal_year_start_date_day_key,
fiscal_year_start_date_month_key,
name,
slug,
postal_address_id`
    : null
}

const useCorporateClinicGroup = (corporateClinicGroupId) => {
  const orca = useOrcaAxios()

  const fetcher = async (url) => {
    const {
      data: [data],
    } = await orca.get(url)
    return data
  }

  const { data, error, mutate, isValidating } = useSWR(
    generateSWRCacheKey({ corporateClinicGroupId }),
    fetcher
  )

  const assign = async (clinicList) => {
    const response = await Promise.all(
      clinicList.map((clinic) =>
        clinic.remove
          ? orca.post(`/rpc/ccg__remove_clinic`, {
              corporate_clinic_group_id_value: corporateClinicGroupId,
              clinic_id_value: clinic.clinicId,
            })
          : orca.post(`/rpc/ccg__assign_clinic`, {
              corporate_clinic_group_id_value: corporateClinicGroupId,
              clinic_id_value: clinic.clinicId,
            })
      )
    )

    return response
  }

  const update = async (values) => {
    await orca.patch(
      `/corporate_clinic_group?corporate_clinic_group_id=eq.${corporateClinicGroupId}`,
      values,
      {
        headers: {
          Prefer: "return=representation",
        },
      }
    )
  }

  return {
    data,
    isLoading: data === undefined,
    hasError: error !== undefined,
    error,
    isRevalidating: data !== undefined && isValidating,
    isReady: data !== undefined,
    mutate,
    assign,
    update,
  }
}

const assign = async (orca, ccgId, clinicList) => {
  const response = await Promise.all(
    clinicList.map((clinic) =>
      clinic.remove
        ? orca.post(`/rpc/ccg__remove_clinic`, {
            corporate_clinic_group_id_value: ccgId,
            clinic_id_value: clinic.clinicId,
          })
        : orca.post(`/rpc/ccg__assign_clinic`, {
            corporate_clinic_group_id_value: ccgId,
            clinic_id_value: clinic.clinicId,
          })
    )
  )

  return response
}

const update = async (orca, corporateClinicGroupId, values) => {
  await orca.patch(
    `/corporate_clinic_group?corporate_clinic_group_id=eq.${corporateClinicGroupId}`,
    values
  )
}

export { generateSWRCacheKey, assign, update }

export default useCorporateClinicGroup
