import React from "react"

// styles
import styled from "@emotion/styled"
import { css, Global } from "@emotion/core"

// assets
import { ReactComponent as Success } from "assets/icons/icon-success.svg"
import { ReactComponent as Arrow } from "assets/arrows/arrow-circle.svg"

// packages
import { CSSTransition } from "react-transition-group"

const animationCss = css`
  .component-steps-animation-enter {
    opacity: 0;
    max-height: 0;
    display: block !important;
  }

  .component-steps-animation-enter-active {
    opacity: 1;
    max-height: 10000px;

    transition: opacity 300ms 300ms ease-in, max-height 300ms 300ms;
  }

  .component-steps-animation-exit {
    opacity: 1;
    max-height: 10000px;
    display: block !important;
  }

  .component-steps-animation-exit-active {
    opacity: 0.01;
    max-height: 0;
    transition: opacity 300ms ease-out, max-height 300ms;
  }

  .component-steps-animation-exit-done {
    display: none !important;
  }
  .component-steps-animation-enter-done {
    display: block !important;
  }

  @keyframes component-steps-loading-animation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: -200% 50%;
    }
  }
`

const Component = styled.div`
  label: component-steps;

  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const stylingArrows = (theme, direction) => css`
  position: absolute;
  cursor: pointer;
  opacity: 0.4;
  z-index: 10000;
  transition: opacity 0.3s;

  ${theme.media(
    "<tablet",
    css`
      circle {
        display: none;
      }
    `
  )}

  &:hover {
    opacity: 1;
  }

  ${direction === "left"
    ? css`
        top: 50%;
        left: 3%;
        transform: translate(0%, -50%);
        ${theme.media(
          "<tablet",
          css`
            left: -15px;
          `
        )}
      `
    : css`
        top: 50%;
        right: 3%;
        transform: translate(0%, -50%) rotate(180deg);
        ${theme.media(
          "<tablet",
          css`
            right: -15px;
          `
        )}
      `}
`

const ScrollContainer = styled.div`
  label: component-steps--scroll-container;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`

const Wrapper = styled.div`
  label: component-steps--wrapper;
  margin: auto;
  width: 100%;
  max-width: 1000px;
  padding: 0 50px;

  margin-top: 50px;

  ${({ theme }) =>
    theme.media(
      "<1200px",
      css`
        max-width: 900px;
      `
    )}

  ${({ theme }) =>
    theme.media(
      "<desktop",
      css`
        max-width: 700px;
      `
    )}

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        padding: 0 40px;
        margin-top: 100px;
      `
    )}
`
const Progress = styled.div`
  label: component-steps--progress;
  margin-bottom: 50px;
  height: 75px;

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1;
        left: 0;
      `
    )}
`
const StepNumber = styled.div`
  label: component-steps--step-number;

  ${({ theme }) => theme.font.typo.bold800};
  font-size: ${({ theme }) => theme.font.size.big};
  color: ${({ theme }) => theme.textColorDefault};

  border: 2px solid
    ${({ theme, state }) =>
      state === "success" ? theme.borderColorSuccess : theme.borderColorDark};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin: auto;
  line-height: 110%;
  letter-spacing: -0.02em;
  overflow: hidden;

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        display: none;
      `
    )}

  svg {
    width: 40px;
    height: 40px;
  }

  ${({ state, theme }) =>
    state === "pending"
      ? css`
          border-color: ${theme.loadingBackgroundColorWarning};

          svg {
            path {
              fill: ${theme.loadingBackgroundColorWarning};
            }
          }
        `
      : null}
`
const ProgressBar = styled.div`
  label: component-steps--progress-bar;
  max-width: 600px;
  margin: auto;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.backgroundColorExtraLight};
  height: 6px;
  border-radius: 12px;
  overflow: hidden;

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        height: 10px;
        border-radius: 0px;
        margin: 0;
      `
    )}
`

const ProgressBarInner = styled.div`
  label: component-steps--progress-bar-inner;

  transition: width 0.75s;
  height: 6px;
  width: ${({ width }) => width}%;
  will-change: background-position;
  background-color: ${({ theme, state }) =>
    state === "success"
      ? theme.loadingBackgroundColorSuccess
      : theme.loadingBackgroundColorDefault};

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        height: 10px;
      `
    )}

  ${({ state, theme }) =>
    state === "pending"
      ? css`
          animation: component-steps-loading-animation 25s linear infinite;
          background-image: repeating-linear-gradient(
            45deg,
            ${theme.loadingBackgroundColorWarning} 0px,
            ${theme.loadingBackgroundColorWarning} 10px,
            white 10px,
            white 12px,
            ${theme.loadingBackgroundColorWarning} 12px,
            ${theme.loadingBackgroundColorWarning} 13px
          );

          background-size: 200% 200%;
        `
      : null}
`

const Pages = styled.div`
  label: component-steps--pages;
`

const StepPage = styled.div`
  label: component-steps--step-page;
`

const StepPageHeader = styled.header`
  label: component-steps--step-page-header;
  text-align: center;
`

const StepPageTitle = styled.h3`
  label: component-steps--step-page-title;

  ${({ theme }) => theme.font.typo.bold800};
  font-size: ${({ theme }) => theme.font.size.large};
  color: ${({ theme, state }) =>
    state === "success" ? theme.textColorSuccess : theme.textColorDefault};

  line-height: 110%;
  letter-spacing: -0.02em;
  margin-bottom: 25px;
`

const StepPageDescription = styled.div`
  label: component-steps--step-page-description;

  ${({ theme }) => theme.font.typo.default};
  font-size: ${({ theme }) => theme.font.size.extraTall};
  color: ${({ theme }) => theme.textColorLight};

  line-height: 130%;
  letter-spacing: 0.01em;

  ${({ theme }) =>
    theme.media(
      "<desktop",
      css`
        max-width: 900px;

        font-size: ${theme.font.size.taller};

        line-height: 130%;
      `
    )}

  ${({ theme }) =>
    theme.media(
      "<tablet",
      css`
        max-width: 900px;

        font-size: ${theme.font.size.tall};

        line-height: 130%;
      `
    )}
`

const StepPageContent = styled.div`
  label: component-steps--step-page-content;

  margin-top: 50px;
  margin-bottom: 200px;
`

const Steps = ({
  onlyAllowBackwards = false,
  unmountOnExit = true,
  children,
  steps,
  step,
  lastVisitedStep = 0,
  state,
  arrows,
  showArrowOnVisit,
  navigate,
  startOffset = 0,
  endOffset = 0,
  ...rest
}) => {
  const pages = React.Children.toArray(children)
  const container = React.useRef()
  const [maxStep, setMaxSetp] = React.useState(lastVisitedStep)

  React.useEffect(() => {
    setMaxSetp(lastVisitedStep)
  }, [lastVisitedStep])

  React.useEffect(() => {
    if (step > maxStep) setMaxSetp(step)
  }, [maxStep, step])

  React.useEffect(() => {
    if (step)
      container.current.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [step, container])

  if (step === undefined) return null

  return (
    <Component data-testid="step" {...rest}>
      <Global styles={animationCss} />

      {arrows &&
        step !== 0 &&
        steps[step].exclude_from_navigation !== true &&
        steps[step - 1].exclude_from_navigation !== true && (
          <Arrow
            css={(theme) => stylingArrows(theme, "left")}
            onClick={() => navigate && navigate(step - 1)}
          />
        )}
      {arrows &&
        !onlyAllowBackwards &&
        step !== steps.length - 1 &&
        steps[step].exclude_from_navigation !== true &&
        steps[step + 1].exclude_from_navigation !== true &&
        (showArrowOnVisit ? step < maxStep : true) && (
          <Arrow
            css={(theme) => stylingArrows(theme, "right")}
            onClick={() => navigate && navigate(step + 1)}
          />
        )}
      <ScrollContainer ref={container}>
        <Wrapper>
          <Progress
            className={
              " component-steps--progress " +
              (state === "success" && " state-success ") +
              (state === "pending" && " state-pending ")
            }
          >
            <StepNumber state={state} data-testid="step-number">
              {state ? (
                <Success width="40" height="40" />
              ) : (
                step + 1 + startOffset
              )}
            </StepNumber>
            <ProgressBar>
              <ProgressBarInner
                state={state}
                width={(() => {
                  const total = steps.length + startOffset + endOffset
                  const page = step + 1 + startOffset
                  return 10 + (90 / total) * page
                })()}
              />
            </ProgressBar>
          </Progress>

          <Pages>
            {pages.map((page, index) => (
              <CSSTransition
                timeout={600}
                classNames="component-steps-animation"
                in={step === index}
                unmountOnExit={unmountOnExit}
                key={index}
              >
                <StepPage
                  style={{ display: step === index ? "" : "none" }}
                  data-testid="step-page"
                  data-stepper-page={index}
                >
                  <StepPageHeader>
                    <StepPageTitle state={state} data-testid="step-page-title">
                      {steps[index].title}
                    </StepPageTitle>
                    <StepPageDescription data-testid="step-page-description">
                      {steps[index].description}
                    </StepPageDescription>
                  </StepPageHeader>
                  <StepPageContent>{page}</StepPageContent>
                </StepPage>
              </CSSTransition>
            ))}
          </Pages>
        </Wrapper>
      </ScrollContainer>
    </Component>
  )
}

export default Steps
