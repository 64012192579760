import React from "react"

// hooks
import useClinic from "store/api/clinic"
import useCCG from "store/api/corporate-clinic-group"
import useWebuserClinic from "store/webuser-clinics"
import useWebuserCorporateClinicGroup from "store/webuser-corporate-clinic-groups"

// main
import useSWR from "swr"

// hooks
import useOrcaAxios from "hooks/useOrcaAxios"

// utils
import * as fn from "utils/functions"

const fallbackYear = () => {
  const ACTUAL_YEAR = new Date().getFullYear()
  const OBJECTIVE_PERIOD = {
    previous: `${ACTUAL_YEAR - 1}-${ACTUAL_YEAR}`,
    current: `${ACTUAL_YEAR}-${ACTUAL_YEAR + 1}`,
    current_year: ACTUAL_YEAR,
  }

  return OBJECTIVE_PERIOD
}

const FiscalYearPeriodContext = React.createContext()

function useFiscalYearPeriod() {
  const context = React.useContext(FiscalYearPeriodContext)
  if (!context) {
    throw new Error(
      `usesettings must be used within a FiscalYearPeriodProvider`
    )
  }
  return context
}

function FiscalYearPeriodProvider(props) {
  const orca = useOrcaAxios()
  const { clinic } = useWebuserClinic()
  const { corporateClinicGroup } = useWebuserCorporateClinicGroup()
  const webuserClinic = useClinic(clinic ? clinic.clinic_id : null)
  const webuserCcg = useCCG(
    corporateClinicGroup ? corporateClinicGroup.corporate_clinic_group_id : null
  )

  const dataIsLoading = webuserClinic.isLoading && webuserCcg.isLoading

  const settings = dataIsLoading
    ? null
    : webuserClinic.data
    ? webuserClinic.data
    : webuserCcg.data

  const [year, setYear] = React.useState(0)

  const isCivicYear = React.useMemo(() => {
    return settings
      ? settings.fiscal_year_start_date_month_key === 1 &&
          settings.fiscal_year_start_date_day_key === 1
      : false
  }, [settings])

  const currentYear = React.useMemo(() => {
    return settings
      ? fn.getCurrentFiscalYear({
          fiscalMonth: settings.fiscal_year_start_date_month_key,
          fiscalDay: settings.fiscal_year_start_date_day_key,
        }) + year
      : fallbackYear().current_year + year
  }, [settings, year])

  const nextFiscalYearIsOpened = React.useMemo(
    () =>
      settings
        ? fn.monthDiff(
            new Date(),
            new Date(
              fn.getCurrentFiscalYear({
                fiscalMonth: settings.fiscal_year_start_date_month_key,
                fiscalDay: settings.fiscal_year_start_date_day_key,
              }) + 1,

              settings.fiscal_year_start_date_month_key - 1,
              settings.fiscal_year_start_date_day_key
            )
          ) <= 3
        : false,
    [settings]
  )
  const maxFiscalYear = React.useMemo(
    () =>
      settings
        ? fn.getCurrentFiscalYear({
            fiscalMonth: settings.fiscal_year_start_date_month_key,
            fiscalDay: settings.fiscal_year_start_date_day_key,
          }) + 1
        : 0,
    [settings]
  )

  const selectNextYear = () => setYear((prev) => prev + 1)
  const selectPreviousYear = () => setYear((prev) => prev - 1)

  const period = {
    isReady: !dataIsLoading,
    isLoading: dataIsLoading,
    nextFiscalYearIsOpened,
    maxFiscalYear,
    fiscalMonth: settings && settings.fiscal_year_start_date_month_key,
    fiscalDay: settings && settings.fiscal_year_start_date_day_key,
    disabled: settings
      ? new Date() -
          new Date(
            currentYear + 1,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key - 1
          ) >
        0
      : false,
    next_year: currentYear + 1,
    next_year_short: (currentYear + 1).toString().slice(-2),
    last_year: currentYear - 1,
    last_year_short: (currentYear - 1).toString().slice(-2),
    last_5_years_list: [
      {
        value: currentYear - 1,
        label: isCivicYear
          ? currentYear - 1
          : `${currentYear - 1}-${currentYear}`,
      },
      {
        value: currentYear - 2,
        label: isCivicYear
          ? currentYear - 2
          : `${currentYear - 2}-${currentYear - 1}`,
      },
      {
        value: currentYear - 3,
        label: isCivicYear
          ? currentYear - 3
          : `${currentYear - 3}-${currentYear - 2}`,
      },
      {
        value: currentYear - 4,
        label: isCivicYear
          ? currentYear - 4
          : `${currentYear - 4}-${currentYear - 3}`,
      },
      {
        value: currentYear - 5,
        label: isCivicYear
          ? currentYear - 5
          : `${currentYear - 5}-${currentYear - 4}`,
      },
    ],
    last_5_years: [
      currentYear - 1,
      currentYear - 2,
      currentYear - 3,
      currentYear - 4,
      currentYear - 5,
    ],
    current_year: currentYear,
    current_year_short: currentYear.toString().slice(-2),
    previous: isCivicYear
      ? currentYear - 1
      : `${currentYear - 1}-${currentYear}`,
    last: {
      value: currentYear - 1,
      label: isCivicYear
        ? currentYear - 1
        : `${currentYear - 1}-${currentYear}`,
    },
    previous_5_years: isCivicYear
      ? [
          currentYear - 1,
          currentYear - 2,
          currentYear - 3,
          currentYear - 4,
          currentYear - 5,
        ]
      : [
          `${currentYear - 1}-${currentYear}`,
          `${currentYear - 2}-${currentYear - 1}`,
          `${currentYear - 3}-${currentYear - 2}`,
          `${currentYear - 4}-${currentYear - 3}`,
          `${currentYear - 5}-${currentYear - 4}`,
        ],
    current: isCivicYear ? currentYear : `${currentYear}-${currentYear + 1}`,
    next: isCivicYear
      ? currentYear + 1
      : `${currentYear + 1}-${currentYear + 2}`,
    selectNextYear,
    selectPreviousYear,
    currentPeriod: {
      start: settings
        ? new Date(
            currentYear,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key
          )
        : null,
      end: settings
        ? new Date(
            currentYear + 1,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key - 1
          )
        : null,
    },
    previousPeriod: {
      start: settings
        ? new Date(
            currentYear - 1,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key
          )
        : null,
      end: settings
        ? new Date(
            currentYear,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key - 1
          )
        : null,
    },
    nextPeriod: {
      start: settings
        ? new Date(
            currentYear + 1,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key
          )
        : null,
      end: settings
        ? new Date(
            currentYear + 2,
            settings.fiscal_year_start_date_month_key - 1,
            settings.fiscal_year_start_date_day_key - 1
          )
        : null,
    },
  }

  const { data: firstObjectiveFiscalYear } = useSWR(
    dataIsLoading
      ? null
      : () =>
          `/objective?clinic_id=eq.${clinic.clinic_id}&select=fiscal_year_key&order=fiscal_year_key.asc&limit=1`,
    async (url) => {
      const {
        data: [firstObjective],
      } = await orca.get(url)
      return firstObjective.fiscal_year_key
    }
  )

  const canSelectPrevYear = dataIsLoading
    ? false
    : firstObjectiveFiscalYear <= period.current_year - 1

  const canSelectNextYear = dataIsLoading
    ? false
    : !(
        (!period.nextFiscalYearIsOpened &&
          period.current_year + 1 === period.maxFiscalYear) ||
        period.current_year === period.maxFiscalYear
      )

  return (
    <FiscalYearPeriodContext.Provider
      value={{ ...period, canSelectPrevYear, canSelectNextYear }}
      {...props}
    />
  )
}

export { FiscalYearPeriodProvider, useFiscalYearPeriod }
