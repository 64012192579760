// see: https://firebase.google.com/docs/web/setup?authuser=1#node.js-apps
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app"

// Add the Firebase services that you want to use
import "firebase/auth"
import "firebase/firestore"

// config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE__API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE__AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE__DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE__PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE__STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE__MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE__APP_ID,
}

firebase.initializeApp(firebaseConfig)

// firebase.firestore.setLogLevel("debug")

const FirestoreService = firebase.firestore()
const FirebaseAuthService = firebase.auth()

// local emulator
if (["development", "test"].includes(process.env.REACT_APP_ENV)) {
  console.log("use Firebase Emumlator")
  FirestoreService.settings({
    host: "localhost:7000",
    ssl: false,
    experimentalAutoDetectLongPolling: true,
  })
  // eslint-disable-next-line react-hooks/rules-of-hooks
  FirebaseAuthService.useEmulator("http://localhost:9099/")
}

export { FirestoreService, FirebaseAuthService }
