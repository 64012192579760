import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Component = styled.div`
  label: ${({ name = "component-container" }) => name};

  transition: opacity 300ms;
  will-change: opacity;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : null}
`

const Container = ({ children, disabled, name, ...rest }) => {
  return (
    <Component
      className="component-container"
      disabled={disabled}
      name={name}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default Container
