// packages
import stringSimilarity from "string-similarity"
import { deburr } from "lodash"

export const stringMatchesTo = (
  input,
  value,
  { minScore = 0.5, minChars = 0, sanitize = true } = {}
) => {
  if (input.length < minChars) return true

  const stringvalue = (sanitize ? deburr(value) : value).toLowerCase()
  const userinput = (sanitize ? deburr(input) : input).toLowerCase()

  const matchScore = stringSimilarity.compareTwoStrings(stringvalue, userinput)

  const isPureMatching =
    stringvalue.includes(userinput) || userinput.includes(stringvalue)

  return matchScore > minScore || isPureMatching
}
