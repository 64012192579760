import React from "react"

// styles
import styled from "@emotion/styled"

// translation
import useTranslate from "hooks/useTranslate"

const Component = styled.div`
  label: component-status-pill;
  display: inline-flex;
  flex-direction: center;
  align-items: center;
  padding: 1px 8px;

  background: ${({ color }) => color};
  border-radius: 48px;

  ${({ theme }) => theme.font.typo.bold600};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme }) => theme.textColorDefault};
`

const Card = ({ status, ...rest }) => {
  const { t } = useTranslate(["data"])

  const label = (() => {
    switch (status) {
      case "active":
        return {
          label: t("data:domain-schedule-track-status.types.active.label"),
          color: "#D2F6E6",
        }

      case "inactive":
        return {
          label: t("data:domain-schedule-track-status.types.inactive.label"),
          color: "#F5F7FB",
        }

      case "planned":
        return {
          label: t("data:domain-schedule-track-status.types.planned.label"),
          color: "#CAE6FF",
        }

      case "to-verify":
        return {
          label: t("data:domain-schedule-track-status.types.to-verify.label"),
          color: "rgba(253, 180, 180, 0.8)",
        }

      case "ignored":
        return {
          label: t("data:domain-schedule-track-status.types.ignored.label"),
          color: "#D9DEEB",
        }

      default:
        return { label: "-", color: "white" }
    }
  })()

  return (
    <Component {...rest} color={label.color}>
      {label.label}
    </Component>
  )
}

export default Card
