import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Button as Component } from "antd"

const styling = (theme, props) => css`
  label: component-button--styling;

  border-radius: ${props.round ? "12px" : "4px"};
  transition: transform 0.3s, background-color 0.3s;
  white-space: break-spaces;
  box-shadow: none !important;

  height: auto;
  min-height: 30px;
  padding: 2px 10px;

  span {
    white-space: nowrap;
  }

  ${props.size === "small" &&
  css`
    &.ant-btn-sm {
      label: small;

      min-height: 24px;
      line-height: 16px;

      span {
        font-size: ${theme.font.size.small};
      }
    }
  `}

  ${props.type === "primary" &&
  css`
    &.ant-btn-primary {
      label: primary;

      ${props.grey &&
      css`
        background-color: ${theme.backgroundColorDark};
        border-color: ${theme.backgroundColorDark};
      `}

      span {
        ${theme.font.typo.bold600};
        font-size: ${theme.font.size.default};
        color: ${theme.textColorWhite};

        margin-left: ${props.hasIconAndLabel ? "4px" : null};
      }

      > svg path {
        fill: ${theme.textColorWhite};
      }

      &:hover {
        transform: translate3d(0, -1px, 0);
      }
    }
  `}

  ${(props.type === undefined || props.type === "secondary") &&
  css`
    &.ant-btn-default,
    &.ant-btn-secondary {
      label: secondary;

      border: 1px solid
        ${theme[props.grey ? "textColorExtraLight" : "themeColorLight"]};

      span {
        ${theme.font.typo.bold600};
        font-size: ${theme.font.size.default};
        color: ${theme[props.color] ||
        theme[props.grey ? "textColorExtraLight" : "themeColorLight"]};

        margin-left: ${props.hasIconAndLabel ? "4px" : null};
      }

      > svg path {
        fill: ${theme[props.grey ? "textColorExtraLight" : "themeColorLight"]};
      }

      ${props.grey
        ? css`
            &:hover {
              border: 1px solid
                ${theme[props.grey ? "textColorLighter" : "themeColorDefault"]};

              span {
                color: ${theme[props.color] ||
                theme[props.grey ? "textColorLighter" : "themeColorDefault"]};
              }

              > svg path {
                fill: ${theme[
                  props.grey ? "textColorLighter" : "themeColorDefault"
                ]};
              }
            }
          `
        : css`
            &:hover {
              background-color: rgba(21, 108, 247, 0.05);
            }
          `}
    }
  `}

  ${props.type === "link" &&
  css`
    &.ant-btn-link {
      label: link;

      padding: 0;
      min-height: auto;

      span {
        ${theme.font.typo.bold600};
        font-size: ${theme.font.size.default};
        color: ${theme[props.grey ? "textColorLighter" : "themeColorLight"]};

        margin-left: ${props.hasIconAndLabel ? "2px" : null};

        text-decoration: none;
        line-height: 150%;
        letter-spacing: 0.04em;
      }

      > svg {
        margin-bottom: 1px;
        path {
          fill: ${theme[props.grey ? "textColorLighter" : "themeColorLight"]};
        }
      }
      &:hover {
        span {
          color: ${theme[props.grey ? "textColorLight" : "themeColorDefault"]};
          text-decoration: underline;
        }

        > svg path {
          fill: ${theme[props.grey ? "textColorLight" : "themeColorDefault"]};
        }
      }
    }
  `}

  ${props.type === "text" &&
  css`
    &.ant-btn-text {
      label: text;

      border-color: transparent;
      padding: 0 8px;
      min-height: auto;

      color: ${theme[props.color] ||
      theme[props.grey ? "textColorLighter" : "themeColorLight"]};

      span {
        ${theme.font.typo.default};
        font-size: ${theme.font.size.default};
        color: ${theme[props.color] ||
        theme[props.grey ? "textColorLighter" : "themeColorLight"]};

        margin-left: ${props.hasIconAndLabel ? "2px" : null};

        line-height: 150%;
        letter-spacing: 0.03em;
      }

      > svg {
        margin-bottom: 1px;

        path {
          fill: ${theme[props.grey ? "textColorLighter" : "themeColorLight"]};
        }
      }

      &:hover {
        color: ${theme[props.color] ||
        theme[props.grey ? "textColorLight" : "themeColorDefault"]};

        span {
          color: ${theme[props.color] ||
          theme[props.grey ? "textColorLight" : "themeColorDefault"]};
        }

        > svg path {
          fill: ${theme[props.grey ? "textColorLight" : "themeColorDefault"]};
        }
      }
    }
  `}

  ${props.disabled &&
  css`
    &:disabled {
      label: disabled;

      cursor: default;
      pointer-events: none;

      > svg path {
        fill: ${theme.textColorUltraLight};
      }

      &.ant-btn-primary {
        background-color: ${theme.backgroundColorDisabled};

        span {
          color: ${theme.textColorWhite};
        }
      }

      &.ant-btn-default,
      &.ant-btn-secondary {
        background-color: transparent;
        border-color: ${theme.borderColorLight};

        span {
          color: ${theme.textColorUltraLight};
        }
      }

      &.ant-btn-link {
        background-color: transparent;
        span {
          color: ${theme.textColorExtraLight};
        }
      }

      &.ant-btn-text {
        background-color: transparent;
        span {
          color: ${theme.textColorExtraLight};
        }
      }
    }
  `}

  ${props.danger &&
  css`
    &.ant-btn-dangerous {
      label: danger;

      &.ant-btn-primary {
        background: ${theme.backgroundColorNegativeDark};
        border-color: ${theme.borderColorError};

        span {
          color: ${theme.textColorWhite};
        }
      }

      &.ant-btn-default,
      &.ant-btn-secondary {
        border-color: ${theme.borderColorError};

        span {
          color: ${theme.textColorError};
        }
        > svg path {
          fill: ${theme.textColorError};
        }
      }

      &.ant-btn-link {
        span {
          color: ${theme.textColorError};
        }
        > svg path {
          fill: ${theme.textColorError};
        }
      }

      &.ant-btn-text {
        span {
          color: ${theme.textColorError};
        }
        > svg path {
          fill: ${theme.textColorError};
        }
      }
    }
  `}

  ${props.warning &&
  css`
    &.ant-btn {
      label: warning;

      &.ant-btn-primary {
        background: ${theme.backgroundColorWarning};
        border-color: ${theme.borderColorWarning};

        span {
          color: ${theme.textColorWhite};
        }
      }

      &.ant-btn-default,
      &.ant-btn-secondary {
        border-color: ${theme.borderColorWarning};

        span {
          color: ${theme.textColorWarning};
        }
        > svg path {
          fill: ${theme.textColorWarning};
        }
      }

      &.ant-btn-link {
        span {
          color: ${theme.textColorWarning};
        }
        > svg path {
          fill: ${theme.textColorWarning};
        }
      }

      &.ant-btn-text {
        span {
          color: ${theme.textColorWarning};
        }
        > svg path {
          fill: ${theme.textColorWarning};
        }
      }
    }
  `}

  ${props.iconOnly &&
  css`
    &.ant-btn-icon-only {
      label: icon;

      &.ant-btn-primary {
        padding: 0;
        border-radius: ${props.round ? "100%" : "6px"};
      }

      &.ant-btn-default,
      &.ant-btn-secondary {
        padding: 0;
        border-radius: ${props.round ? "100%" : "6px"};
      }

      &.ant-btn-text {
        padding: 0;
        border-radius: ${props.round ? "100%" : "6px"};
      }
    }
  `}
`

const Button = React.forwardRef(
  ({ label, grey, warning, round = true, ...props }, ref) => {
    return (
      <Component
        css={(theme) =>
          styling(theme, {
            ...props,
            grey,
            round,
            warning,
            hasIconAndLabel: label && props.icon,
            iconOnly: !label && props.icon,
          })
        }
        data-testid="button"
        className="component-button"
        {...props}
      >
        {label}
      </Component>
    )
  }
)

export default Button
