import React from "react"

// styles
import styled from "@emotion/styled"

// hooks
import useTranslate from "hooks/useTranslate"

// components
import { Typo } from "components"

const Compoment = styled.div`
  label: component-pages;

  padding: 10vmin;

  max-width: 100%;
`

const NotAvailable = ({ center }) => {
  const { t } = useTranslate(["common"])

  return (
    <Compoment>
      <Typo.Title level={3} space="16px">
        {t("common:not-available.label")}
      </Typo.Title>

      <Typo.Paragraph space="32px" textAlign={center ? "center" : null}>
        {t("common:not-available.label", { context: "feature" })}
      </Typo.Paragraph>
    </Compoment>
  )
}

const NoAccess = ({ center }) => {
  const { t } = useTranslate(["common"])

  return (
    <Compoment>
      <Typo.Title level={3} space="16px">
        {t("common:no-access.label")}
      </Typo.Title>

      <Typo.Paragraph space="32px" textAlign={center ? "center" : null}>
        {t("common:no-access.label", { context: "feature" })}
      </Typo.Paragraph>
    </Compoment>
  )
}

const Pages = {
  NotAvailable,
  NoAccess,
}

export default Pages
