// import React from "react"

// styles
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const ActiveInactive = styled.span`
  label: component-page-description;

  opacity: ${({ active, transpancy = 0.3 }) => (active ? 1 : transpancy)};
  color: ${({ theme, active }) => (!active ? theme.textColorLight : "inherit")};
  font-weight: ${({ bold, active }) => (bold && active ? 600 : 400)};

  ${({ active }) =>
    !active &&
    css`
      pointer-events: none;
      user-select: none;
    `}
`

ActiveInactive.defaultProps = { "data-testid": "active-inactive" }

export default ActiveInactive
